import axios from "axios";
import urls from "./urls";

function generate(html, dimensions, format) {
  if (format == "pdf") {
    return axios({
      method: "post",
      url: "https://seniorsign-generate-pdf.herokuapp.com/html-pdf/",
      timeout: 60000,
      data: {
        html: html,
        dimensions: dimensions,
      },
    });
  } else {
    return axios({
      method: "post",
      url: "https://generate-jpeg.herokuapp.com/html-pdf/",
      timeout: 60000,
      data: {
        html: html,
        dimensions: dimensions,
      },
    });
  }
}

function generatePublic(html, dimensions, format) {
  return axios.post(
    "https://seniorsign-generate-pdf.herokuapp.com/html-pdf-public/",
    {
      html: html,
      dimensions: dimensions,
    }
  );
}

function generateBackup(html, dimensions, format) {
  if (format == "pdf") {
    return axios.post(
      "https://seniorsign-generate-pdf.herokuapp.com/html-pdf/",
      {
        html: html,
        dimensions: dimensions,
      }
    );
  } else {
    return axios.post("https://generate-jpeg.herokuapp.com/html-pdf/", {
      html: html,
      dimensions: dimensions,
    });
  }
}

const functions = {
  generate,
  generateBackup,
  generatePublic,
};

export default functions;
