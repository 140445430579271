import React, { useState, useEffect } from "react";
import styled from "styled-components";
import aws from "../util/aws";
import { arrayBufferToBlob } from "blob-util";
import pdf from "../util/pdf";
import colors from "../util/colors";
import agreements from "../util/agreements";
import applications from "../util/application";
import adBlocker from "just-detect-adblock";
import templates from "../util/templates";

const Message = styled.div`
  width: 100%;
  padding: 16px 32px;
  position: fixed;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  bottom: ${(props) => (props.show ? "0px" : "-100px")};
  left: 0;
  background: ${colors.blue};
  color: white;
  font-weight: 700;
  z-index: 11;
  text-align: center;
`;

export default (props) => {
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    async function print() {
      if (adBlocker.isDetected()) {
        alert(
          "Disable adblocker on this domain to allow the print tab to open"
        );
      } else {
        try {
          setShowMessage(true);
          setMessage("Preparing Documents");

          const pages = [];
          for (var i in props.application.agreements.resident) {
            const agreement = props.application.agreements.resident[i];
            if (agreement.agreement_id) {
              const agreementLatest = await agreements.get(
                agreement.agreement_id
              );
              pages.push(agreementLatest.data);
            } else {
              const template = await templates.get(agreement.template_id);
              const obj = {
                pages: {
                  pages: template.data.pages.pages,
                },
                details: {},
              };
              pages.push(obj);
            }
          }

          const html = applications.generatePrintAll(pages, props.auditTrail);

          const generated = await pdf.generate(html, null, "pdf");
          const buffer = await aws.getObject(generated.data.Key);
          const blob = arrayBufferToBlob(buffer.data, "application/pdf");
          const url = window.URL.createObjectURL(blob);

          setMessage(undefined);

          window.open(url);

          const opened = window.open(url);

          try {
            opened.close();
          } catch (err) {
            alert(
              "Please disable your browsers popup blocker for this domain to use the print all feature"
            );
          }
        } catch (error) {
          console.log(error);
          setMessage("Error preparing documents");
          setTimeout(() => setMessage(undefined), 3000);
        }
      }
    }

    if (props.application) print();
  }, [props.application]);

  return <Message show={showMessage && message}>{message}</Message>;
};
