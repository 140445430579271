import React from "react";
import styled from "styled-components";
import Row from "./row";

const Tabs = styled(Row)`
  border-bottom: 1px solid #ddd;
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid #66686b;
  }
`;

export default (props) => <Tabs {...props} />;
