import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Field } from "./index";

const Page = styled.div`
  margin: 10px auto;
  background: white;
  position: relative;
  width: 100%;
  width: ${({ size }) =>
    size && size.width ? size.width * 72 + "px" : "816px"};
  height: ${({ size }) =>
    size && size.height ? size.height * 72 + "px" : "1056px"};
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  margin-top: -1px;
  transform-origin: top center;
  background-size: cover !important;
  background: url(${(props) => props.background});
`;

const PageElement = (props) => {
  const [page, setPage] = useState(props.page);

  function updatePage(value, fieldId, key) {
    for (var i in page.fields)
      if (page.fields[i].fieldid === fieldId) {
        page.fields[i][key ? key : "value"] = value;
        if (
          page.fields[i].type === "initials" &&
          page.fields[i].prefill !== "rp_initials"
        ) {
          props.setLocalInitials(value);
        }
      }
    setPage(page);
    props.updateAgreement(page, props.pageNumber);
  }

  useEffect(() => {}, [props.listen]);

  if (props.page && props.page.fields) {
    return (
      <Page size={props.size} background={props.page.background}>
        {props.page.fields.map((field, index) => {
          return (
            <Field
              key={index}
              field={field}
              save={props.save}
              localInitials={props.localInitials}
              setLocalInitials={props.setLocalInitials}
              {...props}
              updatePage={updatePage}
            />
          );
        })}
      </Page>
    );
  }
};

export default PageElement;
