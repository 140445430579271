import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row } from "./index";

const List = styled.div`
  position: relative;
  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const Grab = styled.div`
  width: 40px;
  height: 42px;
  cursor: grab;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid white;
  background-image: url("/icon-drag.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px auto;
`;

const Active = styled(Row)`
  transform: rotate(2deg);
`;

const Draggable = (props) => {
  const [activeItem, setActiveItem] = useState();
  const [itemPosition, setItemPosition] = useState();
  const [initialMousePosition, setInitialMousePosition] = useState();
  const [arrayPosition, setArrayPosition] = useState();
  const listEl = useRef(null);
  const activeEl = useRef(null);

  function mouseDown(e, item) {
    setItemPosition(e.target.offsetTop);
    setInitialMousePosition({ top: e.clientY, left: e.clientX });
    setActiveItem(item);

    activeEl.current.style.top = e.target.offsetTop + "px";
  }

  function handleMouseMove(e) {
    if (activeItem) {
      let top = e.clientY - initialMousePosition.top + itemPosition;
      activeEl.current.style.top = top + "px";

      checkPosition(top);
    }
  }

  function checkPosition(top) {
    const newOrder = false;
    const newPosition = findPosition(top);
    if (newPosition !== arrayPosition) {
      setArrayPosition(newPosition);
      const filteredItems = props.items.filter(
        (_item) => _item.id !== activeItem.id
      );
      filteredItems.splice(newPosition, 0, activeItem);
      props.onChange(filteredItems);
    }
  }

  function findPosition(top) {
    const array = [];
    for (var i in props.items) {
      array.push(parseInt(i) * 50 + 40);
    }
    let position = 0;
    for (var i in array) {
      if (top > array[i]) position++;
    }
    return position;
  }

  useEffect(() => {
    var listener = () => {
      setActiveItem(undefined);
    };

    window.addEventListener("mouseup", listener, false);

    return () => {
      window.removeEventListener("mouseup", listener, false);
    };
  }, []);

  return (
    <List onMouseMove={handleMouseMove} ref={listEl}>
      {props.items &&
        props.items.map((item, index) => {
          return (
            <Row key={index} justify="space-between">
              {props.itemRenderer(item)}
              <Grab onMouseDown={(e) => mouseDown(e, item)} />
            </Row>
          );
        })}

      <div
        ref={activeEl}
        style={{
          top: "0px",
          position: "absolute",
          width: "100%",
          opacity: 0.95,
        }}
      >
        {activeItem && (
          <Active justify="space-between">
            {props.itemRenderer(activeItem)}
            <Grab />
          </Active>
        )}
      </div>
    </List>
  );
};

export default Draggable;
