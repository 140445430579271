import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const Markdown = styled(ReactMarkdown)`
  h1,
  h2,
  h3 {
    font-weight: 800;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 32px;
  }
  h2 {
    font-size: 20px;
    margin-bottom: 32px;
  }
  h3 {
    font-size: 18px;
  }
  ul,
  ol,
  dl {
    padding: 16px 0 0 16px;
    max-width: 700px;
  }
  li {
    padding-bottom: 16px;
  }
  p {
    color: black !important;
  }
  img,
  iframe,
  video {
    max-width: 100%;
    height: auto;
    margin: 0;
  }
`;

export default (props) => <Markdown escapeHtml={false} source={props.source} />;
