import "./polyfills";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

Bugsnag.start({
  apiKey: "4150b185633e3197a648e18d4f53cd8a",
  plugins: [new BugsnagPluginReact()],
});

// Initialize LogRocket and its integrations
if (!!process.env.REACT_APP_LOG_ROCKET_KEY) {
  console.log("Initializing LogRocket");
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_KEY);
  setupLogRocketReact(LogRocket);
  Bugsnag.beforeNotify = function (data) {
    data.metaData.sessionURL = LogRocket.sessionURL;
    return data;
  };
}

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
