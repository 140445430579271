import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../util/colors";

const Wrap = styled.div`
  display: inline-block;
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #c4cad0;
  background: white;
  position: relative;
  overflow: hidden;
  margin-right: ${(props) => (props.nomargin ? "0" : "8px")};
  cursor: pointer;
`;

const Checked = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid ${colors.blue};
  background: white;
  position: relative;
  overflow: hidden;
  margin-right: ${(props) => (props.nomargin ? "0" : "8px")};
  cursor: pointer;
`;

const Check = styled.div`
  width: 20px;
  height: 20px;
  background: ${colors.blue};
  display: flex;
  align-items: middle;
  & img {
    margin-left: 3px;
    width: 12px;
  }
`;

export default (props) => {
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.detect]);

  return (
    <Wrap {...props}>
      {selected ? (
        <Checked
          nomargin={props.nomargin}
          onClick={() => setSelected(!selected)}
        >
          <Check>
            <img src="/icon-checkmark.svg" />
          </Check>
        </Checked>
      ) : (
        <Checkbox
          nomargin={props.nomargin}
          onClick={() => setSelected(!selected)}
        />
      )}
    </Wrap>
  );
};
