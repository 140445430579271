const Contentful = require("contentful");

const client = Contentful.createClient({
  space: "z2eebvsydvcs",
  accessToken: "i757vJQ04WQkOSod0fBwW85WLBeQIx5GMsVOHTmCPuU",
  host: "cdn.contentful.com",
});

module.exports = {
  query: function (query) {
    return client.getEntries(query);
  },
};
