import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Row, Text } from "./index";

const Page = styled.div`
  margin: 0 4px;
  cursor: pointer;
  p {
    font-weight: 300;
    font-size: 11px;
    transition-duration: 0.2s;
    color: #8a96a1;
    border: ${(props) => (props.active ? "1px solid #B9C0C7" : "none")};
    border-radius: 20px;
    padding: 4px 12px;
    background: ${(props) => (props.active ? "#F2F3F4" : "none")};
    line-height: 1em;
  }
`;

const Arrow = styled.img`
  width: 20px;
  margin: 0 8px;
  cursor: pointer;
`;

export default (props) => {
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(props.page);

  function prevPage() {
    if (page !== 1 && page !== "1") props.selectPage(parseInt(page) - 1);
  }

  function nextPage() {
    if (page < pages.length + 1) props.selectPage(parseInt(page) + 1);
  }

  useEffect(() => {
    setPage(props.page);
    if (props.total > props.skip) {
      const array = [];
      for (var i = 0; i * 20 < props.total && i * 20 < 200; i++) {
        const iteration = i + 1;
        const obj = {
          number: iteration,
          action: () => props.selectPage(iteration),
          selected: props.page == iteration,
        };
        array.push(obj);
      }
      setPages(array);
    }
  }, [props.page]);

  if (pages.length == 0) return null;
  return (
    <Row height="45px" alignItems="center" justify="center">
      <Arrow onClick={prevPage} src="/paginate-arrow-left.svg" />
      {pages.map((current, index) => (
        <Page active={current.selected} onClick={current.action}>
          <Text kind="p">{current.number}</Text>
        </Page>
      ))}
      <Arrow onClick={nextPage} src="/paginate-arrow-right.svg" />
    </Row>
  );
};
