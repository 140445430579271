import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import applications from "../util/application";
import {
  MessageBar,
  Row,
  Text,
  BoxRow,
  Box,
  Cells,
  Cell,
  Check,
  Upload,
  Dots,
  Menu,
  Label,
  Button,
  Input,
  SelectPacket,
} from "./index";

export default (props) => {
  const [app, setApp] = useState(props.application);
  const [myInfoFields, setMyInfoFields] = useState({});
  const [responsiblePartyFields, setResponsiblePartyFields] = useState({});

  function setMyInfoField(value, field) {
    myInfoFields[field] = value;
    app.myInfo.contact_info[field] = value;
    setMyInfoFields(myInfoFields);
    setApp(app);
  }

  function setResponsibleField(value, field) {
    responsiblePartyFields[field] = value;
    app.myInfo.responsible_party[field] = value;
    setResponsiblePartyFields(responsiblePartyFields);
    setApp(app);
  }

  useEffect(() => {
    setApp(props.application);
  }, [props.application]);

  return (
    <Row direction="column" padding={"32px 40px"}>
      <Row
        height={"56px"}
        justify="space-between"
        alignItems="center"
        padding={"0 5px 16px 5px"}
      >
        <SelectPacket {...props} refresh={props.refresh} application={app} />
      </Row>

      <Row justify="space-between">
        <Row padding={"0 16px 0 0"}>
          <Box>
            <BoxRow
              padding={"16px 24px"}
              justify="space-between"
              alignItems="center"
            >
              <Text kind="p" weight={800} size={"14px"} uppercase>
                Resident Information
              </Text>
            </BoxRow>

            <Row direction="column" padding={"24px"}>
              <Row margin={"0 0 16px"}>
                <Row direction="column" padding={"0 16px 0 0"}>
                  <Input
                    value={myInfoFields.first_name}
                    onChange={(e) =>
                      setMyInfoField(e.target.value, "first_name")
                    }
                  />
                  <Label>First Name</Label>
                </Row>

                <Row direction="column">
                  <Input
                    value={myInfoFields.last_name}
                    onChange={(e) =>
                      setMyInfoField(e.target.value, "last_name")
                    }
                  />
                  <Label>Last Name</Label>
                </Row>
              </Row>

              <Row>
                <Row direction="column" padding={"0 16px 0 0"}>
                  <Input
                    value={myInfoFields.email}
                    onChange={(e) =>
                      setMyInfoField(e.target.value.trim(), "email")
                    }
                  />
                  <Label>Email Address</Label>
                </Row>

                <Row direction="column">
                  <Input
                    value={myInfoFields.phone_number}
                    onChange={(e) =>
                      setMyInfoField(e.target.value, "phone_number")
                    }
                  />
                  <Label>Phone Number</Label>
                </Row>
              </Row>
            </Row>

            <Row padding={"24px"} justify={"flex-end"}>
              <Button>Save</Button>
            </Row>
          </Box>
        </Row>

        <Row padding={"0 0 0 16px"}>
          <Box>
            <BoxRow
              padding={"16px 24px"}
              justify="space-between"
              alignItems="center"
            >
              <Text kind="p" weight={800} size={"14px"} uppercase>
                Responsible Party Information
              </Text>
            </BoxRow>

            <Row direction="column" padding={"24px"}>
              <Row margin={"0 0 16px"}>
                <Row direction="column" padding={"0 16px 0 0"}>
                  <Input
                    value={responsiblePartyFields.rp_first_name}
                    onChange={(e) =>
                      setResponsibleField(e.target.value, "rp_first_name")
                    }
                  />
                  <Label>First Name</Label>
                </Row>

                <Row direction="column">
                  <Input
                    value={responsiblePartyFields.rp_last_name}
                    onChange={(e) =>
                      setResponsibleField(e.target.value, "rp_last_name")
                    }
                  />
                  <Label>Last Name</Label>
                </Row>
              </Row>

              <Row>
                <Row direction="column" padding={"0 16px 0 0"}>
                  <Input
                    value={responsiblePartyFields.rp_email_address}
                    onChange={(e) =>
                      setResponsibleField(
                        e.target.value.trim(),
                        "rp_email_address"
                      )
                    }
                  />
                  <Label>Email Address</Label>
                </Row>

                <Row direction="column">
                  <Input
                    value={responsiblePartyFields.rp_phone}
                    onChange={(e) =>
                      setResponsibleField(e.target.value, "rp_phone")
                    }
                  />
                  <Label>Phone Number</Label>
                </Row>
              </Row>
            </Row>

            <Row padding={"24px"} justify={"flex-end"}>
              <Button>Save</Button>
            </Row>
          </Box>
        </Row>
      </Row>
    </Row>
  );
};
