import React, { useState, useEffect } from "react";
import {
  Row,
  Text,
  Button,
  Input,
  Label,
  Select,
  Required,
  MessageBar,
} from ".";
import styled from "styled-components";
import SHERPA from "../util/sherpa";
import queryString from "query-string";
import randomstring from "randomstring";

const SherpaImport = ({ location, close, selectResident }) => {
  return <>Sherpa Import</>;
};

export default SherpaImport;
