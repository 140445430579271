import React, { useState, useEffect } from "react";
import styled from "styled-components";
import applications from "../util/application";
import templates from "../util/templates";
import agreements from "../util/agreements";
import communities from "../util/community";
import user from "../util/user";
import emailer from "../util/emails";
import queryString from "query-string";
import {
  MessageBar,
  Header,
  Sidebar,
  Row,
  Dashboard,
  Text,
  Close,
  Link,
  Box,
  BoxRow,
  Cells,
  Cell,
  Button,
  Check,
  Pill,
  Label,
  InPersonAttachments,
  InPersonPhysician,
  Input,
  Required,
} from "../components";

const Exit = styled(Link)`
  position: absolute;
  top: 48px;
  right: 48px;
`;
const Indicator = styled.img`
  margin-right: 8px;
  max-width: 24px;
`;
const Wrap = styled(Row)`
  width: 170px;
  flex-direction: column;
  padding: 0 18px;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
`;
const Step = styled.div`
  background: #8a96a1;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  color: white;
  margin: 32px 0 16px;
`;
const Actions = styled(Row)`
  button {
    margin-left: 16px;
  }
`;
const Complete = styled.img`
  width: 30px;
  margin: 32px 0 16px;
`;
const Party = styled.img`
  width: 100%;
  margin-top: 16px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;
`;

const Verification = styled.div`
  background: white;
  border-radius: 8px;
  position: relative;
  z-index: 9;
  width: 100%;
  max-width: ${(props) => (props.width ? props.width + "px" : "500px")};
  align-self: flex-start;
  overflow: hidden;
`;

const VerificationWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: flex;
  justify-content: center;
  padding: 180px 0;
  overflow: scroll;
`;

const InPerson = (props) => {
  const [application, setApplication] = useState();
  const [loaded, setLoaded] = useState();
  const [refreshed, refresh] = useState(false);
  const [step, setStep] = useState("agreements");
  const [message, setMessage] = useState();
  const [changed, change] = useState();
  const [complete, setComplete] = useState();
  const [showVerification, setShowVerification] = useState(true);
  const [lastFour, setLastFour] = useState("");
  const [sendEmailAddress, setSendEmailAddress] = useState();

  async function openSigner(agreement) {
    if (agreement.agreement_id) {
      props.history.push(
        "/sign/" +
          agreement.agreement_id +
          "/" +
          props.match.params.id +
          "/All/in-person"
      );
    } else {
      const template = await templates.get(agreement.template_id);
      const newAgreement = await agreements.create(template.data);
      await agreements.assignNewAgreementId(
        newAgreement.data[0].id,
        agreement.template_id,
        props.match.params.id
      );
      props.history.push(
        "/sign/" +
          newAgreement.data[0].id +
          "/" +
          props.match.params.id +
          "/All/in-person"
      );
    }
  }

  async function skipSending() {
    const _application = await applications.get(application.id);
    _application.data.progress.current = "complete";
    await applications.update(_application.data);
    setMessage("Packet updated successfully!");
    change(!changed);
    setComplete(true);
  }

  async function sendAgreements() {
    const _application = await applications.get(application.id);
    _application.data.progress.current = "complete";
    await applications.update(_application.data);

    try {
      const resident = await user.getResident(application.userId);
      const location = await communities.get(resident.data.facilityId);
      let assignee;
      try {
        if (application.myInfo.application_info.manager_name) {
          const _assignee = await user.getResident(
            application.myInfo.application_info.manager_name
          );
          assignee = _assignee.data.name;
        } else {
          assignee = undefined;
        }
      } catch {
        assignee = undefined;
      }

      let email;
      if (resident.data.invitationCode) {
        email = emailer.generateCompletedApplicationEmail(
          resident.data.email,
          location.data.details.name,
          `https://res.seniorsign.io/invite?${queryString.stringify({
            email: resident.data.email,
            invite_code: resident.data.invitationCode,
          })}`,
          resident.data.name,
          assignee
        );
      } else {
        email = emailer.generateCompletedApplicationEmail(
          resident.data.email,
          location.data.details.name,
          "https://res.seniorsign.io",
          resident.data.name
        );
      }
      await emailer.send(email);
      setMessage("Packet updated and Agreements sent successfully!");
      change(!changed);
      setComplete(true);
    } catch (error) {
      setMessage("Error sending email");
      change(!changed);
    }
  }

  async function agreeToVerify() {
    if (!lastFour) {
      alert("Please enter the last 4 of your SSN");
    } else {
      const _application = await applications.get(application.id);
      if (!_application.data.myInfo.audit_trail)
        _application.data.myInfo.audit_trail = {};
      _application.data.myInfo.audit_trail.verification = {
        lastFour: lastFour,
        date: new Date(),
      };
      await applications.update(_application.data);
      setShowVerification(false);
      setMessage("Consent to sign recorded successfully!");
      change(!changed);
    }
  }

  useEffect(() => {
    async function getApplication() {
      try {
        const app = await applications.get(props.match.params.id);

        if (
          app.data.myInfo.audit_trail &&
          app.data.myInfo.audit_trail.verification
        ) {
          setShowVerification(false);
        }

        setApplication(app.data);
        setSendEmailAddress(app.data.myInfo.contact_info.email);
        setLoaded(true);

        const parsed = queryString.parse(props.location.search);
        if (parsed.tab) setStep(parsed.tab);
      } catch (error) {
        props.history.push("/login");
      }
    }

    getApplication();
  }, [refreshed]);

  if (!loaded) return null;
  return (
    <main>
      {showVerification && (
        <Overlay>
          <VerificationWrap>
            <Verification>
              <BoxRow padding="24px">
                <Text weight="700" uppercase>
                  Consent to sign digitally
                </Text>
              </BoxRow>
              <BoxRow padding="24px" direction="column">
                <Text size="12px">
                  I agree to electronically sign these documents and to be
                  legally bound by my signature. Enter the last 4 digits of your
                  social security number then click on “I Agree” to sign
                  electronically.
                </Text>

                <Row padding="24px 0 0">
                  <Row direction="column">
                    <Input
                      mask={"XXX-XX-9999"}
                      change={(e) => setLastFour(e.target.value)}
                      placeholder="Last 4 of SSN"
                    />
                    <Label>
                      <Required />
                      Last 4 of SSN
                    </Label>
                  </Row>
                  <Button margin="0 8px" onClick={agreeToVerify}>
                    I Agree
                  </Button>
                  <Link
                    to={`/application/${props.match.params.id}?tab=agreements`}
                  >
                    <Button outline stay>
                      Cancel
                    </Button>
                  </Link>
                </Row>
              </BoxRow>
            </Verification>
          </VerificationWrap>
        </Overlay>
      )}

      <Header>
        <Row></Row>
      </Header>
      <Dashboard padding="48px">
        <Exit to={`/application/${props.match.params.id}?tab=agreements`}>
          <Close />
        </Exit>
        <Text kind="p" size="18px" weight="800" uppercase center>
          Resident Packet
        </Text>

        <Row justify="center" alignItems="center">
          <Wrap
            active={step == "agreements"}
            onClick={() => setStep("agreements")}
          >
            {!applications.allRequiredDocsComplete(application) && (
              <Step>1</Step>
            )}
            {applications.allRequiredDocsComplete(application) && (
              <Complete src="/icon-completed-check.svg" />
            )}
            <Text kind="p" uppercase weight="700" size="12px" center>
              Sign Agreements
            </Text>
          </Wrap>

          <Wrap
            active={step == "attachments"}
            onClick={() => setStep("attachments")}
          >
            {(!applications.allUploadsReceived(application) ||
              step == "agreements") && <Step>2</Step>}
            {applications.allUploadsReceived(application) &&
              (step == "attachments" ||
                step == "physician" ||
                step == "submit") && (
                <Complete src="/icon-completed-check.svg" />
              )}
            <Text kind="p" uppercase weight="700" size="12px" center>
              Attach Documents
            </Text>
          </Wrap>

          {application.agreements.physician &&
          application.agreements.physician.length ? (
            <Wrap
              active={step == "physician"}
              onClick={() => setStep("physician")}
            >
              {!applications.allPhysicianDocsComplete(application) ||
              step == "agreements" ||
              step == "attachments" ? (
                <Step>3</Step>
              ) : null}
              {applications.allPhysicianDocsComplete(application) &&
                (step == "physician" || step == "submit") && (
                  <Complete src="/icon-completed-check.svg" />
                )}
              <Text kind="p" uppercase weight="700" size="12px" center>
                Physician Forms
              </Text>
            </Wrap>
          ) : null}

          <Wrap active={step == "submit"} onClick={() => setStep("submit")}>
            {step !== "submit" && (
              <Step>
                {application.agreements.physician &&
                application.agreements.physician.length
                  ? 4
                  : 3}
              </Step>
            )}
            {step == "submit" && <Complete src="/icon-completed-check.svg" />}
            <Text kind="p" uppercase weight="700" size="12px" center>
              Submit Application
            </Text>
          </Wrap>
        </Row>

        <Row margin="48px 0 0" direction="column">
          {step == "agreements" && (
            <>
              <Box width={980}>
                <BoxRow
                  padding={"16px 24px"}
                  justify="space-between"
                  alignItems="center"
                >
                  <Text kind="p" weight={800} size={"14px"} uppercase>
                    Document Name
                  </Text>

                  <Cells>
                    <Cell width={155}>
                      <Text
                        kind="p"
                        center
                        weight={800}
                        size={"14px"}
                        uppercase
                      >
                        Action
                      </Text>
                    </Cell>
                  </Cells>
                </BoxRow>

                {application.agreements.resident.length &&
                  application.agreements.resident.map((agreement, index) => {
                    return (
                      <BoxRow
                        padding={"16px 24px"}
                        justify="space-between"
                        alignItems="center"
                        key={index}
                      >
                        <Row alignItems="center">
                          {!applications.allSignaturesComplete(
                            agreement.signatures
                          ) && <Check />}
                          {applications.allSignaturesComplete(
                            agreement.signatures
                          ) && <Check checked />}
                          <Text
                            pointer
                            kind="p"
                            size={"15px"}
                            underline
                            onClick={() => openSigner(agreement)}
                          >
                            {agreement.details.required && <Required />}
                            {agreement.name}
                          </Text>
                        </Row>

                        <Cells>
                          <Cell width={155}>
                            {!applications.allSignaturesComplete(
                              agreement.signatures
                            ) && (
                              <Button onClick={() => openSigner(agreement)}>
                                Sign Now
                              </Button>
                            )}
                            {applications.allSignaturesComplete(
                              agreement.signatures
                            ) && <Pill green>Complete</Pill>}
                          </Cell>
                        </Cells>
                      </BoxRow>
                    );
                  })}
              </Box>

              {applications.allRequiredDocsComplete(application) &&
              step == "agreements" ? (
                <Row
                  width={"980px"}
                  margin="0 auto"
                  padding="24px 0"
                  justify="flex-end"
                >
                  <Button onClick={() => setStep("attachments")}>Next</Button>
                </Row>
              ) : (
                <Row
                  width={"980px"}
                  margin="0 auto"
                  padding="24px 0"
                  justify="flex-end"
                >
                  <Button disabled>Next</Button>
                </Row>
              )}
            </>
          )}

          {step == "attachments" && (
            <InPersonAttachments
              application={application}
              refresh={(e) => refresh(e)}
            />
          )}

          {applications.allRequiredDocsComplete(application) &&
            step == "attachments" && (
              <Row
                width={"980px"}
                margin="0 auto"
                padding="24px 0"
                justify="flex-end"
              >
                {application.agreements.physician &&
                application.agreements.physician.length ? (
                  <Button onClick={() => setStep("physician")}>Next</Button>
                ) : (
                  <Button onClick={() => setStep("submit")}>Next</Button>
                )}
              </Row>
            )}

          {step == "physician" && (
            <InPersonPhysician
              {...props}
              application={application}
              refresh={(e) => refresh(e)}
            />
          )}

          {applications.allRequiredDocsComplete(application) &&
            step == "physician" && (
              <Row
                width={"980px"}
                margin="0 auto"
                padding="24px 0"
                justify="flex-end"
              >
                <Button onClick={() => setStep("submit")}>Next</Button>
              </Row>
            )}

          {step == "submit" && (
            <>
              {complete ? (
                <Box width={570}>
                  <BoxRow
                    padding={"16px 24px"}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Text kind="p" weight={800} size={"14px"} uppercase>
                      Congratulations!
                    </Text>
                  </BoxRow>
                  <BoxRow padding={"16px 24px"} direction="column">
                    <Text kind="p" size="14px">
                      You've completed your packet!
                    </Text>

                    <Party src="/party.gif" />

                    <Actions padding="24px 0 0" justify="flex-end">
                      <a
                        href={
                          "/application/" + application.id + "/?tab=agreements"
                        }
                      >
                        <Button>Done</Button>
                      </a>
                    </Actions>
                  </BoxRow>
                </Box>
              ) : (
                <Box width={570}>
                  <BoxRow
                    padding={"16px 24px"}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Text kind="p" weight={800} size={"14px"} uppercase>
                      Submit Application
                    </Text>
                  </BoxRow>
                  <BoxRow padding={"16px 24px"} direction="column">
                    <Text kind="p" size="14px">
                      Once completed, click below to submit your application.
                    </Text>
                    {false ? (
                      <>
                        <Row padding="24px 0 0">
                          <Input
                            change={(e) => setSendEmailAddress(e.target.value)}
                            initialvalue={
                              application.myInfo.contact_info.email.indexOf(
                                "resident+"
                              ) !== -1
                                ? ""
                                : application.myInfo.contact_info.email
                            }
                            type="text"
                          />
                        </Row>
                        <Label>Email Address</Label>
                      </>
                    ) : (
                      ""
                    )}
                    <Actions padding="24px 0 0" justify="flex-end">
                      <Button onClick={skipSending}>Submit</Button>
                    </Actions>
                  </BoxRow>
                </Box>
              )}
            </>
          )}
        </Row>

        <MessageBar message={message} listen={changed} />
      </Dashboard>
    </main>
  );
};

export default InPerson;
