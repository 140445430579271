import React, { useState, useEffect } from "react";
import LogRocket from "logrocket";
import application from "../util/application";
import user from "../util/user";
import communities from "../util/community";
import queryString from "query-string";
import Moment from "react-moment";
import {
  Pill,
  HelpButton,
  Header,
  Sidebar,
  Row,
  Text,
  Box,
  Wrapper,
  Link,
  Button,
  Input,
  BoxRow,
  Cells,
  Cell,
  Dashboard,
  Dots,
  Add,
  Loading,
  Select,
  Paginate,
} from "../components";

const Summary = (props) => {
  const [applications, setApplications] = useState([]);
  const [loaded, setLoaded] = useState();
  const [department, setDepartment] = useState();
  const [departments, setDepartments] = useState();
  const [refreshed, refresh] = useState(false);
  const [total, setTotal] = useState();
  const [selectedPage, setSelectedPage] = useState(1);

  async function deleteApplication(app) {
    const check = window.confirm(
      `Are you sure you want to delete this ${app.myInfo.contact_info.name}? THIS INFORMATION CANNOT BE RECOVERED`
    );
    if (check) {
      await application.remove(app.id);
      refresh(!refreshed);
    }
  }

  async function archiveApplication(app) {
    const check = window.confirm(
      "Are you sure you want to archive this application?"
    );
    if (check) {
      async function archive() {
        const _latest = await application.get(app.id);
        _latest.data.archived = true;
        _latest.data.progress.current = "Archived";
        await application.update(_latest.data);
        refresh(!refreshed);
      }

      archive();
    }
  }

  function generateOptions(app) {
    return [
      {
        label: "View",
        action: () => props.history.push("/application/" + app.id),
      },
      { label: "Archive", action: () => archiveApplication(app) },
      { label: "Delete", action: () => deleteApplication(app) },
    ];
  }

  function generateSelectList(array, community) {
    const newArray = [];
    for (var i in array) {
      let match = false;
      for (var j in community.details.departments) {
        if (community.details.departments[j].name === array[i]) match = true;
      }
      if (match) {
        const obj = {
          label: array[i],
          value: array[i],
        };
        newArray.push(obj);
        array[i] = obj;
      }
    }
    newArray.push({ label: "All Residents", value: "All Residents" });
    return newArray;
  }

  async function selectDepartment(e) {
    localStorage.selected_department = e;
    setDepartment(e);
    const queries = queryString.parse(props.location.search);
    const userResult = await user.get();
    if (e === "All Residents") {
      const totalCount = await application.countApplications(
        userResult.data.facilityId
      );
      const applicationResults = await application.listFacilityApplications(
        userResult.data.facilityId,
        queries.page ? queries.page - 1 : 0
      );
      setApplications(applicationResults.data);
      setTotal(totalCount.data[0].count);
    } else {
      const applicationResults = await application.listByDepartment(
        userResult.data.facilityId,
        e,
        queries.page ? queries.page - 1 : 0
      );
      setApplications(applicationResults.data);
    }
  }

  async function selectPage(page) {
    setSelectedPage(page);
    props.history.push(props.location.pathname + "?page=" + page);
  }

  useEffect(() => {
    async function getApplications() {
      try {
        //Get URL Params for pagination
        const queries = queryString.parse(props.location.search);
        //Get User for FacilityId
        const userResult = await user.get();

        //Set user on LogRocket
        LogRocket.identify(userResult.data.id, {
          name: userResult.data.name,
          email: userResult.data.email,

          // Add your own custom user variables here, ie:
          access: userResult.data.access,
        });

        // Delighted NPS tracking.
        if (window.delighted) {
          window.delighted.survey({
            email: userResult.data.email, // customer email (optional)
            name: userResult.data.name, // customer name (optional)
            createdAt: new Date(), // time subscribed (optional)
            properties: {
              // extra context (optional)
              company: userResult.data.department,
            },
          });
        }
        //Get Applications and departments
        if (
          userResult.data.details &&
          userResult.data.details.departments &&
          userResult.data.details.departments.length
        ) {
          const departmentSelected = userResult.data.details.default_department
            ? localStorage.selected_department ||
              userResult.data.details.default_department.value
            : localStorage.selected_department ||
              userResult.data.details.departments[0];
          const community = await communities.get(userResult.data.facilityId);
          if (departmentSelected == "All Residents") {
            const totalCount = await application.countApplications(
              userResult.data.facilityId
            );
            const applicationResults = await application.listFacilityApplications(
              userResult.data.facilityId,
              queries.page ? queries.page - 1 : 0
            );
            setTotal(totalCount.data[0].count);
            setDepartment(departmentSelected);
            setDepartments(
              generateSelectList(
                userResult.data.details.departments,
                community.data
              )
            );
            setApplications(applicationResults.data);
          } else {
            const applicationResults = await application.listByDepartment(
              userResult.data.facilityId,
              departmentSelected,
              queries.page ? queries.page - 1 : 0
            );
            const totalCount = await application.countByDepartment(
              userResult.data.facilityId,
              departmentSelected
            );
            queries.page && setSelectedPage(queries.page);
            setTotal(totalCount.data[0].count);
            setDepartment(departmentSelected);
            setDepartments(
              generateSelectList(
                userResult.data.details.departments,
                community.data
              )
            );
            setApplications(applicationResults.data);
          }
        }

        setLoaded(true);
      } catch (error) {
        console.log(error);
        props.history.push("/login");
      }
    }

    getApplications();
  }, [refreshed, selectedPage]);

  return (
    <main>
      <Header />
      <Row>
        <Sidebar />

        {loaded ? (
          <Dashboard padding={"48px"}>
            <Row
              alignItems="center"
              justify="space-between"
              margin={"0 0 16px"}
              padding={"0 0 0 4px"}
            >
              {departments && departments.length ? (
                <Select
                  update={selectDepartment}
                  width={"400px"}
                  initialvalue={{ label: department }}
                  textonly
                  options={departments}
                />
              ) : (
                <Text kind="p" weight={800} uppercase>
                  No Department Assigned
                </Text>
              )}
            </Row>

            <Box>
              <BoxRow
                padding={"16px 24px"}
                justify="space-between"
                alignItems="center"
              >
                <Text kind="p" weight={800} size={"14px"} uppercase>
                  Resident Name
                </Text>

                <Cells>
                  <Cell width={145}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>
                      Department
                    </Text>
                  </Cell>

                  <Cell width={120}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>
                      Created
                    </Text>
                  </Cell>

                  <Cell width={155}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>
                      Status
                    </Text>
                  </Cell>

                  <Cell width={35}>
                    <Link to="/add-resident">
                      <Add src="icon-add.svg" />
                    </Link>
                  </Cell>
                </Cells>
              </BoxRow>

              {applications.length > 0 ? (
                applications.map((application, index) => (
                  <BoxRow
                    padding={"16px 24px"}
                    justify="space-between"
                    alignItems="center"
                    key={index}
                  >
                    <Link to={"/application/" + application.id}>
                      <Text kind="p" underline>
                        {application.myInfo.contact_info.name}
                      </Text>
                    </Link>

                    <Cells>
                      <Cell width={145}>
                        <Text kind="p" center color={"#aaa"} size={"12px"}>
                          {application.department
                            ? application.department
                            : "None Assigned"}
                        </Text>
                      </Cell>

                      <Cell width={120}>
                        <Text
                          kind="p"
                          center
                          color={"#aaa"}
                          size={"12px"}
                          uppercase
                        >
                          <Moment format="MM/DD/YYYY">
                            {application.createdAt}
                          </Moment>
                        </Text>
                      </Cell>

                      <Cell width={155}>
                        {application.progress.current == "New!" && (
                          <Link
                            full="true"
                            to={"/application/" + application.id}
                          >
                            <Pill full primary>
                              {application.progress.current}
                            </Pill>
                          </Link>
                        )}
                        {application.progress.current == "complete" && (
                          <Link
                            full="true"
                            to={"/application/" + application.id}
                          >
                            <Pill full green>
                              {application.progress.current}
                            </Pill>
                          </Link>
                        )}
                        {application.progress.current !== "New!" &&
                          application.progress.current !== "complete" && (
                            <Link
                              full="true"
                              to={"/application/" + application.id}
                            >
                              <Pill full gray>
                                {application.progress.current}
                              </Pill>
                            </Link>
                          )}
                      </Cell>

                      <Cell width={35}>
                        <Dots options={generateOptions(application)} />
                      </Cell>
                    </Cells>
                  </BoxRow>
                ))
              ) : (
                <Row padding="16px 24px">
                  <Text kind="p">No Results</Text>
                </Row>
              )}

              <Paginate
                page={selectedPage}
                total={total}
                skip={20}
                selectPage={(e) => selectPage(e)}
              />
            </Box>

            <HelpButton />
          </Dashboard>
        ) : (
          <Loading />
        )}
      </Row>
    </main>
  );
};

export default Summary;
