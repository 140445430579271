import axios from "axios";
import urls from "./urls";
import request from "request";
import queryString from "query-string";

function authenticate(code, redirect_uri) {
  return axios.post(urls.api + "pcc/auth", {
    code: code,
    redirect_uri: redirect_uri,
  });
}

function getFacilities(token, orgUuid) {
  return axios.get(
    urls.api + `pcc/facilities?orgUuid=${orgUuid}&token=${token}`
  );
}

function getFacility(token, orgUuid, facId) {
  return axios.get(
    urls.api + `pcc/facility?orgUuid=${orgUuid}&token=${token}&facId=${facId}`
  );
}

function getPatient(token, orgUuid, patientId) {
  return axios.get(
    urls.api +
      `pcc/patient?orgUuid=${orgUuid}&patientId=${patientId}&&token=${token}`
  );
}

function getPatients(token, orgUuid, facId, page, patientStatus) {
  if (page && patientStatus)
    return axios.get(
      urls.api +
        `pcc/patients?orgUuid=${orgUuid}&token=${token}&facId=${facId}&page=${page}&patientStatus=${patientStatus}`
    );
  if (page && !patientStatus)
    return axios.get(
      urls.api +
        `pcc/patients?orgUuid=${orgUuid}&token=${token}&facId=${facId}&patientStatus=Current&page=${page}`
    );
  return axios.get(
    urls.api +
      `pcc/patients?orgUuid=${orgUuid}&token=${token}&facId=${facId}&patientStatus=Current`
  );
}

function getPatientMatch(token, orgUuid, facId, search) {
  return axios.post(urls.api + "pcc/patientMatch", {
    token,
    orgUuid,
    facId,
    search,
  });
}

const functions = {
  getPatients,
};

export default functions;
