import axios from "axios";
import urls from "./urls";

function login(user) {
  return axios.post(urls.api + "session", user);
}

function get() {
  return axios.get(urls.api + "user", {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function generateCode() {
  return axios.get(urls.api + "user/generatecode", {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function list(id, offset) {
  return axios.get(
    urls.api + "facilityResidents/" + id + "/" + offset * 20 + "/20",
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

function listByDepartment(id, department, offset) {
  return axios.get(
    urls.api +
      "facilityDepartmentResidents/" +
      id +
      "/" +
      department +
      "/" +
      offset * 20 +
      "/20",
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

function countByDepartment(id, department) {
  return axios.get(
    urls.api + "countFacilityDepartmentResidents/" + id + "/" + department,
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

function listCommunityAdmins(facilityId, companyId) {
  const list = new Promise(async (resolve, reject) => {
    let _array = [];

    const _facilityUsers = await axios.get(
      urls.api + "facilityAdmins/" + facilityId + "/0",
      {
        headers: {
          "X-Auth-Token": localStorage.token,
        },
      }
    );

    _array = _facilityUsers.data;

    if (companyId) {
      const _companyUsers = await axios.get(
        urls.api + "companyAdmins/" + companyId + "/0",
        {
          headers: {
            "X-Auth-Token": localStorage.token,
          },
        }
      );

      if (_companyUsers.data) {
        _companyUsers.data = _companyUsers.data.filter((_user) =>
          _user.details && _user.details.communities
            ? _user.details.communities.filter(
                (_community) => _community.id === facilityId
              ).length > 0
            : true
        );
        const _additionalUsers = _companyUsers.data.filter(
          (_user) =>
            _array.filter((_item) => _item.id === _user.id).length === 0
        );

        _array = [..._additionalUsers, ..._array];
      }
    }

    resolve({ data: _array });
  });

  return list;
}

function getResident(id) {
  return axios.get(urls.api + "user/" + id, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function search(facilityId, searchTerm) {
  return axios.post(
    urls.api + "facilitySearchResidents/" + facilityId,
    { search: searchTerm },
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

function checkTokenStatus() {
  return axios.post(
    urls.api + "users/verifyToken",
    {},
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

function addResident(resident) {
  return axios.post(urls.api + "addResident", resident, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function inviteResident(resident) {
  return axios.post(urls.api + "invite-resident", resident, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function inviteAdmin(admin) {
  return axios.post(urls.api + "invite-new-community-admin", admin, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function getByEmail(email) {
  return axios.get(urls.api + "userbyemail/" + email, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function remove(id) {
  return axios.delete(urls.api + "user/" + id, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function update(user) {
  return axios.patch(urls.api + "users/" + user.id, user, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function getIpAddress() {
  return axios.get(urls.api + "iplocation", {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function requestReset(email) {
  return axios.post(urls.api + "reset-community-password", { email: email });
}

function acceptInvitation(user) {
  user.new = true;
  return axios.post(urls.api + "acceptFacilityInvitation", user);
}

function resetPassword(password, reset_code) {
  return axios.post(urls.api + "resetPassword", {
    new_password: password,
    reset_code: reset_code,
  });
}

const functions = {
  login,
  get,
  generateCode,
  checkTokenStatus,
  list,
  listByDepartment,
  countByDepartment,
  getResident,
  search,
  listCommunityAdmins,
  inviteResident,
  inviteAdmin,
  getByEmail,
  remove,
  update,
  getIpAddress,
  requestReset,
  acceptInvitation,
  resetPassword,
};

export default functions;
