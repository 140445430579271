import React, { useState, useEffect } from "react";
import styled from "styled-components";
import applications from "../util/application";
import {
  Header,
  Sidebar,
  Row,
  Text,
  Box,
  Link,
  Button,
  Input,
  BoxRow,
  Dashboard,
  Cells,
  Cell,
  Dots,
  HelpButton,
  Label,
  Check,
  Select,
  Pill,
  Tab,
  Tabs,
  Menu,
  MessageBar,
  Preview,
  Upload,
  Attachments,
  Agreements,
  Physician,
  ResidentInfo,
  Overview,
} from "../components";
import queryString from "query-string";

const Application = (props) => {
  const [app, setApplication] = useState();
  const [loaded, setLoaded] = useState();
  const [tab, setTab] = useState("overview");
  const [refreshed, refresh] = useState(false);

  function getLatest(e) {
    refresh(e ? e : !refreshed);
  }

  useEffect(() => {
    async function getApplication() {
      try {
        const parsed = queryString.parse(props.location.search);
        if (parsed.tab) setTab(parsed.tab);
        const application = await applications.get(props.match.params.id);
        setApplication(application.data);
        setLoaded(true);
      } catch (error) {
        props.history.push("/login");
      }
    }

    getApplication();
  }, [refreshed]);

  return (
    <main>
      <Header />
      <Row>
        <Sidebar />
        <Dashboard padding={"0 0 128px"}>
          {loaded && (
            <div>
              <Tabs>
                <a href="?tab=overview">
                  <Tab active={tab === "overview"}>
                    <Text kind="p" weight={700} size={"14px"} uppercase>
                      Overview
                    </Text>
                  </Tab>
                </a>
                <a href="?tab=agreements">
                  <Tab active={tab === "agreements"}>
                    <Text kind="p" weight={700} size={"14px"} uppercase>
                      Agreements
                    </Text>
                  </Tab>
                </a>
                <a href="?tab=attachments">
                  <Tab active={tab === "attachments"}>
                    <Text kind="p" weight={700} size={"14px"} uppercase>
                      Attachments
                    </Text>
                  </Tab>
                </a>
                {app.agreements.physician && app.agreements.physician.length ? (
                  <a href="?tab=physician">
                    <Tab active={tab === "physician"}>
                      <Text kind="p" weight={700} size={"14px"} uppercase>
                        Physician Forms
                      </Text>
                    </Tab>
                  </a>
                ) : null}

                {/*<Tab active={tab === 'logs'} onClick={() => setTab('logs')}>
                <Text kind="p" weight={700} size={'14px'} uppercase>Activity Log</Text>
              </Tab>*/}
              </Tabs>

              {tab === "overview" && (
                <Overview
                  {...props}
                  setTab={setTab}
                  application={app}
                  refresh={getLatest}
                />
              )}

              {tab === "agreements" && (
                <Agreements {...props} application={app} refresh={getLatest} />
              )}

              {tab === "attachments" && (
                <Attachments {...props} application={app} refresh={getLatest} />
              )}

              {tab === "physician" && (
                <Physician {...props} application={app} refresh={getLatest} />
              )}

              {tab === "info" && (
                <ResidentInfo
                  {...props}
                  application={app}
                  refresh={getLatest}
                />
              )}
            </div>
          )}
        </Dashboard>

        <HelpButton />
      </Row>
    </main>
  );
};

export default Application;
