import React from "react";
import styled from "styled-components";

const SignatureBox = styled.div`
  display: flex;
  align-items: center;
`;

export default (props) => (
  <SignatureBox {...props}>
    <div className="overlay"></div>
    <div className="action-box">
      <p>CREATE SIGNATURE</p>
      <div className="signature">
        <input type="text" />
      </div>
      <span>I understand this is a legal representation of my signature.</span>
      <button>INSERT</button>
    </div>
  </SignatureBox>
);
