import React from "react";
import styled from "styled-components";

const Tab = styled.div`
  padding: 24px 32px;
  background: ${(props) => (props.active ? "#EFF0F2" : "none")};
  cursor: pointer;
  transition-duration: 0.3s;
  &:hover {
    background: #eff0f2;
  }
  @media (prefers-color-scheme: dark) {
    background: ${(props) => (props.active ? "#333232" : "none")};
    &:hover {
      background: #333232;
    }
  }
`;

export default (props) => <Tab {...props} />;
