import React, { useState, useEffect } from "react";
import {
  Row,
  Text,
  Button,
  MessageBar,
  Checkbox,
  Input,
  Label,
  Required,
  Select,
} from ".";
import styled from "styled-components";
import PCC from "../util/pcc";
import queryString from "query-string";
import aws from "../util/aws";
import applications from "../util/application";
import pdf from "../util/pdf";
import agreements from "../util/agreements";
import randomstring from "randomstring";

const Item = styled.div`
  width: 100%;
  padding: 12px;
  background: white;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition-duration: 0.3s;
  background: white;
  display: flex;
  align-items: center;
  :hover {
    background: #ededed;
    div {
      font-weight: 600 !important;
    }
  }
`;

const Items = styled.div`
  width: 100%;
  height: 225px;
  overflow: scroll;
`;

const Icon = styled.img`
  width: 20px;
`;

const Main = ({ location, close, selectResident, application, exportDoc }) => {
  const [auth, setAuth] = useState();
  const [facility, setFacility] = useState();
  const [facilities, setFacilities] = useState();
  const [folders, setFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [message, setMessage] = useState();
  const [uploadEnabled, setUploadEnabled] = useState(false);
  const [patientId, setPatientId] = useState();
  const [orgUuid, setOrgUuid] = useState();
  const [search, setSearch] = useState({ gender: "MALE" });
  const [showMessage, setShowMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState();

  useEffect(() => {
    const _parsed = queryString.parse(location.search);

    async function checkTokens() {
      try {
        if (_parsed.code) {
          const _auth = await authenticate(_parsed.code);
          const _refreshedToken = await PCC.refreshToken(_auth.refresh_token);
          if (_refreshedToken.data.access_token) {
            setAuth(_refreshedToken.data);
            getFacilities(_refreshedToken.data);
            window.localStorage.setItem(
              "pcc",
              JSON.stringify(_refreshedToken.data)
            );
          }
        } else if (window.localStorage.pcc) {
          const _auth = JSON.parse(window.localStorage.pcc);
          if (_auth.refresh_token) {
            const _refreshedToken = await PCC.refreshToken(_auth.refresh_token);
            console.log("refreshed", _refreshedToken);

            if (_refreshedToken.data.access_token) {
              setAuth(_refreshedToken.data);
              window.localStorage.setItem(
                "pcc",
                JSON.stringify(_refreshedToken.data)
              );
              //Check if application is from an import
              if (application.integrations && application.integrations.pcc) {
                console.log(application.integrations.pcc);
                if (application.integrations.pcc.orgUuid)
                  setOrgUuid(application.integrations.pcc.orgUuid);
                console.log(
                  "Setting orgid: ",
                  application.integrations.pcc.orgUuid
                );
                if (application.integrations.pcc.patientId)
                  setPatientId(application.integrations.pcc.patientId);
                console.log(
                  "Setting patientId: ",
                  application.integrations.pcc.patientId
                );
                if (application.integrations.pcc.facId)
                  setFacility({ facId: application.integrations.pcc.facId });
              } else {
                getFacilities(_refreshedToken.data);
              }
            }
          } else {
            console.log("auth", _auth);
            login();
          }
        } else {
          login();
        }
      } catch (err) {
        console.log("error check", err);
      }
    }

    checkTokens();
  }, []);

  useEffect(() => {
    async function getFacilityDetails() {
      const _facility = await PCC.getFacility(
        auth.access_token,
        auth.metadata.orgUuid,
        facility.facId
      );
      console.log("fac details", _facility.data);
      if (_facility.data.isUploadEnabled) {
        setUploadEnabled(true);
        if (
          _facility.data.pickList &&
          _facility.data.pickList.documentCategories
        ) {
          setFolders(_facility.data.pickList.documentCategories);
        } else {
          setFolders([
            {
              id: 1,
              description: "Clinical Document",
              isCustom: false,
            },
            {
              id: 2,
              description: "Other",
              isCustom: true,
            },
          ]);
        }
      }
    }

    if (facility && auth) getFacilityDetails();
  }, [facility]);

  function updateSearch(e) {
    search[e.target.name] = e.target.value;
    setSearch(search);
  }

  async function getFacilities(_auth) {
    try {
      const _facilities = await PCC.getFacilities(
        _auth.access_token,
        _auth.metadata.orgUuid
      );
      console.log("facilities", _facilities);
      setFacilities(_facilities.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function authenticate(_code) {
    try {
      const _auth = await PCC.authenticate(
        _code,
        `https://comm.seniorsign.io/application/${application.id}`
      );
      window.localStorage.setItem("pcc", JSON.stringify(_auth.data));
      return _auth.data;
    } catch (err) {
      console.log("error", err);
      return false;
    }
  }
  // Production URL
  function login() {
    window.location.href = `https://connect.pointclickcare.com/auth/login?client_id=FIbvMl792HdxAIUE88ubqmb0ygH7lKFX&response_type=code&redirect_uri=https://comm.seniorsign.io/application/${application.id}&appName=ss-community-portal&state=9j76_b63`;
  }

  // Sandbox URL
  // function login() {
  //   window.location.href = `https://connect.pointclickcare.com/auth/login?client_id=FIbvMl792HdxAIUE88ubqmb0ygH7lKFX&response_type=code&redirect_uri=https://comm.seniorsign.io/application/${application.id}&appName=ss-community-portal&state=9j76_b63`;
  // }

  function toggleSelection(_folder) {
    const _found = selectedFolders.filter(
      (_selected) => _selected.id === _folder.id
    );
    let _toggled;
    if (_found.length) {
      _toggled = [
        ...selectedFolders.filter((_selected) => _selected.id !== _folder.id),
      ];
    } else {
      _toggled = [...selectedFolders, _folder];
    }
    setSelectedFolders(_toggled);
  }

  async function exportAgreement() {
    //console.log(selectedFolders);
    //console.log(exportDoc);
    const _agreement = await agreements.get(exportDoc.agreement_id);
    const _html = applications.generateHtml(_agreement.data);
    const _pdf = await pdf.generate(_html, exportDoc.details.size, "pdf");
    //const _tempUrl = await aws.getPreviewSignedUrl(_pdf.data.Key);
    // console.log(_tempUrl.data.url);
    // if (selectedFolders.length > 0) {
    // }
    console.log("export doc", exportDoc);

    const _metadata = {
      metadata: {
        name: exportDoc.name,
        agreement_id: exportDoc.agreement_id,
        documentCategory: 1,
      },
      file: _pdf.data.Key,
    };
    console.log(auth.access_token, orgUuid, patientId, _metadata);
    const _added = await PCC.addDocument(
      auth.access_token,
      orgUuid,
      patientId,
      _metadata
    );
    console.log("added", _added);
    alert("Document added to PCC");
    //close();
  }

  async function runSearch() {
    if (
      !search.firstName ||
      !search.lastName ||
      !search.birthDate ||
      !search.gender
    ) {
      setMessage("Please fill out all required fields");
      setShowMessage(randomstring.generate());
    } else {
      setLoading(true);
      const _patients = await PCC.getPatientMatch(
        auth.access_token,
        auth.metadata.orgUuid,
        facility.facId,
        search
      );
      console.log("Here are our patients: ", _patients);
      console.log("Here are our search: ", search);
      setPatients(_patients.data);
      setLoading(false);
    }
  }

  const [disableExportButton, setDisableExportButton] = useState(false);

  return (
    <>
      <Row direction="column">
        {patientId && orgUuid && (
          <Text>
            <strong>Name: </strong>
            {exportDoc.name}
          </Text>
        )}
        {facilities && !facility && (
          <>
            <Text uppercase weight="600" size="14px" margin="0 0 16px">
              Community / Facility
            </Text>
            {facilities.map((_facility) => (
              <Item
                onClick={() => setFacility(_facility)}
                key={_facility.facId}
              >
                <Text size="12px">{_facility.facilityName}</Text>
              </Item>
            ))}
          </>
        )}

        {facility && (
          <>
            {patientId ? (
              <>
                <Text weight="bold" margin="0 0 16px" uppercase>
                  {facility.facilityName}
                </Text>
                {folders.length > 0 ? (
                  <>
                    <Items>
                      {folders.map((_folder) => (
                        <Item>
                          <Checkbox onClick={() => toggleSelection(_folder)} />
                          <Text>{_folder.description}</Text>
                        </Item>
                      ))}
                    </Items>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Row direction="column" margin="24px 0 0">
                <Row margin="0 0 8px">
                  <Row direction="column" margin="0 8px 0 0">
                    <Input
                      name="firstName"
                      placeholder="First Name"
                      change={updateSearch}
                    />
                    <Label color="black">
                      <Required />
                      First Name
                    </Label>
                  </Row>

                  <Row direction="column">
                    <Input
                      name="lastName"
                      placeholder="Last Name"
                      change={updateSearch}
                    />
                    <Label color="black">
                      <Required />
                      Last Name
                    </Label>
                  </Row>
                </Row>

                <Row alignItems="flex-start" justify="space-between">
                  <Row direction="column" margin="0 8px 0 0">
                    <Input
                      mask="9999-99-99"
                      placeholder="yyyy-mm-dd"
                      name="birthDate"
                      change={updateSearch}
                    />
                    <Label color="black">
                      <Required />
                      Date of Birth
                    </Label>
                  </Row>

                  <Row direction="column" margin="0 8px 0 0">
                    <Select
                      options={[
                        { label: "Male", value: "MALE" },
                        { label: "Female", value: "FEMALE" },
                        { label: "Unknown", value: "UNKNOWN" },
                      ]}
                      name="gender"
                      change={(e) =>
                        updateSearch({
                          target: { name: "gender", value: e.value },
                        })
                      }
                    />
                    <Label color="black">
                      <Required />
                      Gender
                    </Label>
                  </Row>

                  <Button onClick={runSearch}>Search</Button>
                </Row>
              </Row>
            )}
          </>
        )}
      </Row>

      <Row padding="32px 0 0" justify="flex-start">
        <Button outline stay onClick={close}>
          Back
        </Button>

        {patientId && orgUuid && (
          <Button
            margin="0 0 0 16px"
            onClick={
              disableExportButton
                ? undefined
                : (e) => {
                    exportAgreement(e);
                    setDisableExportButton(true);
                  }
            }
            disabled={disableExportButton}
          >
            Export
          </Button>
        )}
      </Row>
    </>
  );
};

export default Main;
