import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../util/colors";
import applications from "../util/application";
import user from "../util/user";
import communities from "../util/community";
import { Modal, Row, Button, Text, Checkbox, Label } from "./index";

export default (props) => {
  const [show, setShow] = useState(props.show);
  const [application, setApplication] = useState(props.application);
  const [documents, setDocuments] = useState();

  function toggleDocument(doc) {
    doc.selected = !doc.selected;
    for (var i in documents) {
      if (doc.title == documents[i].title) documents[i] = doc;
    }
    setDocuments(documents);
  }

  async function addDocuments() {
    try {
      const applicationLatest = await applications.get(application.id);
      for (var i in documents) {
        if (documents[i].selected)
          applicationLatest.data.myDocuments.required_documents.push(
            documents[i]
          );
      }
      await applications.update(applicationLatest.data);
      props.complete();
      props.close();
    } catch (err) {}
  }

  useEffect(() => {
    if (props.application) {
      async function init() {
        const me = await user.get();
        const location = await communities.get(me.data.facilityId);

        //Get the department data
        const _department = location.data.details.departments.filter(
          (dep) => dep.name === props.application.department
        );

        const department = _department[0];

        let cleanedUpList = [];
        //Loop through the available uploads
        for (var i in department.available_uploads) {
          let clear = true;

          for (var j in props.application.myDocuments.required_documents) {
            //If the document is already in the list, don't list it
            if (
              props.application.myDocuments.required_documents[j].title ==
              department.available_uploads[i].title
            )
              clear = false;
          }
          if (clear) {
            const obj = {
              title: department.available_uploads[i].title,
              files: undefined,
              selected: false,
            };
            cleanedUpList.push(obj);
          }
        }

        setDocuments(cleanedUpList);
      }
      setShow(props.show);
      setApplication(props.application);
      init();
    } else {
      console.log("NO APPLICATION FOR ATTACHMENTS");
    }
  }, [props.application, props.show]);

  return (
    <Modal title={"Add Attachments"} show={show} close={props.close}>
      {documents && documents.length > 0 ? (
        <Row direction="column">
          <Row padding={"24px 24px 0"} direction="column">
            <Text kind="p" weight={700} color="#4D5864">
              Documents
            </Text>
          </Row>

          <Row direction="column" padding={"12px"}>
            {documents.map((doc, index) => (
              <Row padding={"12px"} alignItems="center">
                <Checkbox onClick={() => toggleDocument(doc)} />
                <Label color="#4D5864">{doc.title}</Label>
              </Row>
            ))}
          </Row>

          <Row padding={"24px"} justify="flex-end">
            <Button onClick={addDocuments}>Add</Button>
          </Row>
        </Row>
      ) : (
        <Row direction="column">
          <Row justify="center" padding="48px">
            <Text kind="p" center color="#4D5864">
              All attachments have already been assigned
            </Text>
          </Row>

          <Row padding={"0 24px 24px"} justify="flex-end">
            <Button onClick={() => props.close()}>Close</Button>
          </Row>
        </Row>
      )}
    </Modal>
  );
};
