import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import applications from "../util/application";
import aws from "../util/aws";
import $ from "jquery";
import randomString from "randomstring";
import colors from "../util/colors";

const Message = styled.div`
  width: 100%;
  padding: 16px 32px;
  position: fixed;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  bottom: ${(props) => (props.show ? "0px" : "-100px")};
  left: 0;
  background: ${colors.blue};
  color: white;
  font-weight: 700;
  z-index: 11;
  text-align: center;
`;

export default (props) => {
  const [fieldName, setFieldName] = useState(
    props.agreement.name.replace(/[^a-zA-Z0-9]/g, "-") + "-upload"
  );
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);

  function selectFile() {
    $("#" + fieldName).click();
  }

  useEffect(() => {
    $("#" + fieldName).change(function () {
      async function run() {
        setMessage("Uploading file(s)...");
        setShowMessage(true);
        var files = document.getElementById(fieldName).files;
        //Put files in AWS and add to Array
        const array = [];
        for (var i in files) {
          if (
            files[i].name &&
            files[i].name !== undefined &&
            files[i].name !== "item"
          ) {
            const name =
              props.agreement.name.replace(/ /g, "_") +
              "_" +
              props.application.myInfo.contact_info.name.replace(/ /g, "_") +
              "_" +
              randomString.generate(15) +
              "." +
              files[i].name.split(".").pop();
            const put = await aws.putObject(files[i], name);

            array.push(put.data.Key);
          }
        }
        //Update Application
        const application = await applications.get(props.application.id);
        for (var i in application.data.agreements.physician) {
          if (
            application.data.agreements.physician[i].template_id ==
            props.agreement.template_id
          ) {
            application.data.agreements.physician[i].files = array;
          }
        }
        const updated = await applications.update(application.data);
        setMessage("File(s) successfully added");
        setTimeout(() => setMessage(undefined), 3000);
        props.complete(array[0]);
      }

      run();
    });
  }, []);

  return (
    <div onClick={selectFile}>
      {props.children}
      <input
        hidden
        id={props.agreement.name.replace(/ /g, "-") + "-upload"}
        multiple
        type="file"
      />
      <Message show={showMessage && message}>{message}</Message>
    </div>
  );
};
