import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../util/colors";
import Close from "./close";
import Row from "./row";
import Text from "./text";

const Header = styled(Row)`
  border-bottom: 1px solid #ddd;
`;

const Modal = styled.div`
  background: white;
  border-radius: 8px;
  position: relative;
  z-index: 9;
  width: 100%;
  max-width: ${(props) => (props.width ? props.width + "px" : "500px")};
  align-self: flex-start;
`;

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  display: flex;
  justify-content: center;
  padding: 80px 0;
  overflow: scroll;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

export default (props) => {
  useEffect(() => {}, [props.show]);

  if (!props.show) return null;
  return (
    <Wrap>
      <Overlay />
      <Modal {...props}>
        <Header
          padding={"24px 24px 24px 32px"}
          justify="space-between"
          alignItems="center"
        >
          <Row>
            {props.title && (
              <Text kind="p" color="#4D5864" weight={700} uppercase>
                {props.title}
              </Text>
            )}
          </Row>

          <div onClick={props.close}>
            <Close />
          </div>
        </Header>

        {props.children}
      </Modal>
    </Wrap>
  );
};
