import React, { useState, useEffect } from "react";
import styled from "styled-components";
import application from "../util/application";
import communities from "../util/community";
import user from "../util/user";
import colors from "../util/colors";
import agreements from "../util/agreements";
import packetsHelper from "../util/packets";
import companies from "../util/company";
import {
  PreviewTemplate,
  ImportResident,
  Row,
  Text,
  Box,
  Link,
  Button,
  Input,
  BoxRow,
  Dashboard,
  HelpButton,
  Label,
  Select,
  Checkbox,
  Close,
  Loading,
  Required,
  Modal,
} from "../components";
import queryString from "query-string";
import randomString from "randomstring";
import includes from "array-includes";

const Dash = styled(Dashboard)`
  padding: 48px 84px;
`;

const Steps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 48px;
`;

const Step = styled.div`
  width: 30px;
  height: 30px;
  background: ${(props) => (props.active ? colors.blue : "#C4CAD0")};
  border-radius: 50%;
  color: white;
  font-weight: 900;
  font-size: 12px;
  display: flex;
  line-height: 1em;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (prefers-color-scheme: dark) {
    background: ${(props) => (props.active ? colors.blue : "#7f8992")};
  }
`;

const Title = styled.div`
  width: 100%;
  left: -40px;
  position: absolute;
  text-align: center;
  bottom: -20px;
  color: #4d5864;
  white-space: nowrap;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`;

const Line = styled.div`
  width: 160px;
  height: 2px;
  background: #c4cad0;
  @media (prefers-color-scheme: dark) {
    background: #7f8992;
  }
`;

const WrapLeft = styled.div`
  width: 100%;
  margin-right: 16px;
`;

const WrapRight = styled.div`
  width: 100%;
  margin-left: 16px;
`;

const WrapCenter = styled.div`
  width: 100%;
`;

const Actions = styled.div`
  & button {
    margin-left: 16px;
  }
`;

const ListItem = styled(Row)`
  border-bottom: 1px solid #c4cad0;
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid #66686b;
  }
`;

const Column = styled(Row)`
  border-right: 1px solid #c4cad0;
  @media (prefers-color-scheme: dark) {
    border-right: 1px solid #66686b;
  }
`;

const Wrap = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const AddResident = (props) => {
  const [loaded, setLoaded] = useState();
  const [step, setStep] = useState(1);
  const [templates, setTemplates] = useState([]);
  const [packetsSelectList, setPacketsSelectList] = useState([]);
  const [communityAdminSelectList, setCommunityAdminSelectList] = useState([]);
  const [packets, setPackets] = useState([]);
  const [changed, change] = useState(); //used to decect change for checkboxes
  const [resident, setResident] = useState({});
  const [error, setError] = useState();
  const [availableStep, setAvailableStep] = useState(1);
  const [community, setCommunity] = useState();
  const [uploadsList, setUploadsList] = useState([]);
  const [physicianList, setPhysicianList] = useState([]);
  const [readAndReview, setReadAndReview] = useState([]);
  const [readAndReviewList, setReadAndReviewList] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [company, setCompany] = useState();
  const [preview, setPreview] = useState();
  const [allAgreements, setAllAgreements] = useState();
  const [emailError, setEmailError] = useState(false);
  const [emailFormatError, setEmailFormatError] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [submittedPacket, setSubmittedPacket] = useState(true);

  function updateField(e) {
    resident[e.target.name] = e.target.value;
    setResident(resident);
    validate();
  }

  function updateSelect(e, field) {
    resident[field] = e.value;
    if (field === "assignee") {
      setCurrentUser(e);
    }
    setResident(resident);
    validate();
  }

  function validate() {
    let validStep = 1;
    if (
      resident.first_name &&
      resident.last_name &&
      !emailError &&
      !emailFormatError
    )
      validStep = 3;
    setAvailableStep(validStep);
  }

  function toggleTemplate(template) {
    template.selected = !template.selected;
    for (var i in templates) {
      if (templates[i].id === template.id) templates[i] = template;
    }
    setTemplates(templates);
    change(!changed);
  }

  function selectPacket(input) {
    if (packets.length) {
      change(!changed);

      const packet = packets.filter((_packet) => _packet.name === input.value);
      const department = community.details.departments.filter(
        (department) => department.name === resident.department
      );
      const _departmentAgreements = agreements.filterDepartmentAgreements(
        department[0].available_agreements,
        allAgreements
      );

      packet[0] = agreements.applyPacket(
        _departmentAgreements,
        packet[0],
        department[0].available_uploads
      );

      setTemplates(packet[0].agreements);
      setPhysicianList(packet[0].physicianDocs);
      setReadAndReview(packet[0].readAndReview);
      setUploadsList(packet[0].uploads);

      resident.packet_name = input.value;
      setResident(resident);
    }
  }

  function finalStep() {
    setUploadDocsList();
  }

  function getDepartments() {
    var array = [];
    if (community.details.departments) {
      for (var i in community.details.departments) {
        const obj = {
          label: community.details.departments[i].name,
          value: community.details.departments[i].name,
        };

        if (currentUser.details && currentUser.details.departments) {
          for (var j in currentUser.details.departments) {
            if (currentUser.details.departments[j] === obj.label)
              array.push(obj);
          }
        }
      }
    }

    if (array.length > 0 && !resident.department)
      resident.department = array[0].value;
    return array;
  }

  function getAdmins(users) {
    const array = [];
    for (var i in users) {
      const obj = {
        label: users[i].name,
        value: users[i].id,
        details: users[i].details,
      };
      if (users[i].email.indexOf("@seniorsign.io") === -1) array.push(obj);
    }
    if (array.length > 0) resident.assignee = array[0].value;
    return array;
  }

  function setUploadDocsList() {
    if (resident.department) {
      const department = community.details.departments.filter(
        (department) => department.name === resident.department
      );

      setPackets(department[0].packets);
      setPacketsSelectList(packetsHelper.getList(department[0].packets));
      const _filteredUploads = packetsHelper.filterUploads(
        department[0].available_uploads,
        company.forms.required_documents
      );

      setUploadsList(packetsHelper.deselectUploads(_filteredUploads));
      setTemplates(
        templates.filter((agreement) => {
          let check = true;
          if (agreement.details.physician_doc) check = false;
          if (agreement.details.read_only) check = false;
          if (
            department[0].available_agreements.filter(
              (id) => id === agreement.id
            ).length === 0
          )
            check = false;
          return check;
        })
      );

      setReadAndReview(
        readAndReviewList.filter((agreement) => {
          let check = true;
          if (agreement.details.read_only) check = false;
          if (
            department[0].available_agreements.filter(
              (id) => id === agreement.id
            ).length === 0
          )
            check = false;
          return check;
        })
      );

      setPhysicianList(
        physicianList.filter((agreement) => {
          let check = true;
          if (!agreement.details.physician_doc) check = false;
          if (
            department[0].available_agreements.filter(
              (id) => id === agreement.id
            ).length === 0
          )
            check = false;
          return check;
        })
      );
    }

    setStep(3);
  }

  function toggleUpload(uploadDoc) {
    uploadDoc.selected = !uploadDoc.selected;
    for (var i in uploadsList) {
      if (uploadsList[i].title === uploadDoc.title) uploadsList[i] = uploadDoc;
    }
    setUploadsList(uploadsList);
    change(!changed);
  }

  async function finish() {
    setSubmittedPacket(false);
    try {
      if (!resident.email_address || resident.email_address === "") {
        resident.email_address = `resident+${randomString.generate()}@seniorsign.io`;
      }
      const new_user = await user.inviteResident({
        name: resident.first_name + " " + resident.last_name,
        email: resident.email_address.trim(),
        access: "resident",
        details: {},
        progress: "Invite Sent",
        facilityId: community.id,
        facilityName: community.details.name,
        password: "Oiad8f3D2",
        department: resident.department,
      });
      resident.id = new_user.data.id;
      assignApplication();
    } catch (error) {
      console.log("error", error);
      const check_user = await user.getByEmail(resident.email_address);
      if (check_user.data && check_user.data.id) {
        resident.id = check_user.data.id;
        assignApplication();
      }
    }

    async function assignApplication() {
      const new_application = application.generateApplication(
        resident,
        community,
        templates,
        uploadsList,
        physicianList,
        readAndReview
      );
      const created_application = await application.create(new_application);

      try {
        const me = await user.get();
        const location = await communities.get(me.data.facilityId);
        if (!location.data.details.rooms) location.data.details.rooms = [];
        if (resident.unit_number) {
          const roomExists = location.data.details.rooms.filter(
            (room) => room.unit_number === resident.unit_number
          );
          if (roomExists.length > 0) {
            location.data.details.rooms = location.data.details.rooms.filter(
              (room) => {
                if (room.unit_number === resident.unit_number) {
                  room.history.push({
                    application_id: created_application.data[0].id,
                    name: `${resident.first_name}${
                      resident.middle_name
                        ? " " + resident.middle_name + " "
                        : " "
                    }${resident.last_name}`,
                    date_entered: new Date(),
                    department: resident.department,
                  });
                }
                return room;
              }
            );
          } else {
            location.data.details.rooms.push({
              unit_number: resident.unit_number,
              history: [
                {
                  application_id: created_application.data[0].id,
                  name: `${resident.first_name}${
                    resident.middle_name
                      ? " " + resident.middle_name + " "
                      : " "
                  }${resident.last_name}`,
                  date_entered: new Date(),
                  department: resident.department,
                },
              ],
            });
          }

          await communities.update(location.data);
        }
      } catch {
        console.log("unit number not tracked");
      }

      props.history.push("/application/" + created_application.data[0].id);
    }
  }

  function toggleReadAndReview(doc) {
    doc.selected = !doc.selected;
    for (var i in readAndReview) {
      if (readAndReview[i].details.name === doc.details.name)
        readAndReview[i] = doc;
    }
    // console.log("Doc: ", doc);
    // console.log("Read and Review: ", readAndReview);
    setReadAndReview(readAndReview);
    change(!changed);
  }

  async function checkEmail() {
    const _user = await user.getByEmail(resident.email_address);
    if (_user.data.access && _user.data.access === "facility") {
      alert("The email address is not available for a resident account");
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    if (!resident.email_address.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      setEmailFormatError(true);
    } else {
      setEmailFormatError(false);
    }
  }

  function selectResident(e) {
    const _imported = { ...resident, ...e };
    setResident(_imported);
  }

  useEffect(() => {
    async function setup() {
      try {
        const parsed = queryString.parse(props.location.search, {
          decode: false,
        });
        //Get location and templates
        const me = await user.get();
        const location = await communities.get(me.data.facilityId);
        const agreementsList = await agreements.getAgreements(
          location.data.companyId,
          me.data.facilityId
        );
        const communityAdmins = await user.listCommunityAdmins(
          me.data.facilityId,
          me.data.companyId,
          0
        );
        const _company = await companies.get(location.data.companyId);

        if (parsed.email) {
          resident.email_address = decodeURIComponent(parsed.email);
          if (parsed.first_name)
            resident.first_name = decodeURIComponent(parsed.first_name);
          if (parsed.last_name)
            resident.last_name = decodeURIComponent(parsed.last_name);
          if (
            resident.email_address &&
            resident.first_name &&
            resident.last_name
          )
            setStep(2);
          setResident(resident);
          validate();
        }

        setCompany(_company.data);
        setCurrentUser(me.data);
        setReadAndReviewList(
          agreementsList.filter((agreement) => agreement.details.read_only)
        );
        setCommunity(location.data);
        setCommunityAdminSelectList(getAdmins(communityAdmins.data));
        setTemplates(
          agreementsList.filter((agreement) => !agreement.details.physician_doc)
        );
        setPhysicianList(
          agreementsList.filter((agreement) => agreement.details.physician_doc)
        );
        setAllAgreements(agreementsList);

        //Set Selected Templates from first packet
        setLoaded(true);
      } catch (error) {
        console.log(error);
        props.history.push("/login");
      }
    }

    setup();
  }, []);

  return (
    <main>
      <Row>
        <Dash>
          <Row justify="flex-end">
            <Link to="/dashboard">
              <Close />
            </Link>
          </Row>
          {loaded ? (
            <Wrap>
              <Text kind="p" weight={900} center uppercase>
                Add Resident
              </Text>
              <Steps>
                <Step active={step === 1}>
                  {" "}
                  1<Title>Resident Information</Title>{" "}
                </Step>
                <Line />
                <Step active={step === 2}>
                  2<Title>Department Details</Title>
                </Step>
                <Line />
                <Step active={step === 3}>
                  3<Title>Packet Selection</Title>
                </Step>
              </Steps>

              {step === 1 && (
                <Box>
                  <BoxRow>
                    <Row
                      padding={"24px"}
                      justify="space-between"
                      alignItems="center"
                    >
                      <Text kind="p" weight={900} center uppercase>
                        Resident Information
                      </Text>
                      {community.enableIntegrations && (
                        <Button
                          outline
                          onClick={() => setShowImportModal(true)}
                        >
                          Import
                        </Button>
                      )}
                    </Row>
                  </BoxRow>
                  <Row direction="column" padding={"24px"}>
                    <Row margin={"0 0 16px"}>
                      <WrapLeft>
                        <Input
                          name="first_name"
                          initialvalue={resident.first_name}
                          change={updateField}
                        />
                        <Label>
                          First Name
                          <Required />
                        </Label>
                      </WrapLeft>

                      <Row direction="column">
                        <Input
                          name="middle_name"
                          initialvalue={resident.middle_name}
                          change={updateField}
                        />
                        <Label>Middle Name</Label>
                      </Row>

                      <WrapRight>
                        <Input
                          name="last_name"
                          initialvalue={resident.last_name}
                          change={updateField}
                        />
                        <Label>
                          Last Name
                          <Required />
                        </Label>
                      </WrapRight>
                    </Row>

                    <Row margin={"0 0 16px"}>
                      <WrapLeft>
                        <Input
                          mask={"(999) 999-9999"}
                          name="phone_number"
                          initialvalue={resident.phone_number}
                          change={updateField}
                        />
                        <Label>Phone Number</Label>
                      </WrapLeft>

                      <Row direction="column">
                        <Input
                          name="email_address"
                          initialvalue={resident.email_address}
                          change={updateField}
                          onBlur={checkEmail}
                        />
                        <Label>
                          Email Address{" "}
                          {emailFormatError && (
                            <span style={{ color: "#D90479" }}>
                              Invalid Email Format
                            </span>
                          )}
                        </Label>
                      </Row>
                    </Row>

                    <Row>
                      <WrapLeft>
                        <Input
                          name="street_address"
                          initialvalue={resident.street_address}
                          change={updateField}
                        />
                        <Label>Street Address</Label>
                      </WrapLeft>

                      <WrapCenter>
                        <Input
                          name="city"
                          initialvalue={resident.city}
                          change={updateField}
                        />
                        <Label>City</Label>
                      </WrapCenter>

                      <Row direction="column" width="15%" margin={"0 0 0 16px"}>
                        <Input
                          name="state"
                          initialvalue={resident.state}
                          change={updateField}
                        />
                        <Label>State</Label>
                      </Row>

                      <Row direction="column" width="15%" margin={"0 0 0 16px"}>
                        <Input
                          name="zip"
                          initialvalue={resident.zip}
                          change={updateField}
                        />
                        <Label>Zip</Label>
                      </Row>
                    </Row>
                  </Row>
                  {error && <Text kind="p">{error}</Text>}
                  <Row padding={"0 24px 24px"} justify={"space-between"}>
                    <div />
                    {availableStep > 1 && (
                      <Button primary onClick={() => setStep(2)}>
                        Next
                      </Button>
                    )}
                    {availableStep === 1 && (
                      <Button primary disabled onMouseEnter={validate}>
                        Next
                      </Button>
                    )}
                  </Row>
                </Box>
              )}

              {step === 2 && (
                <Box>
                  <BoxRow>
                    <Row padding={"24px"}>
                      <Text kind="p" weight={900} center uppercase>
                        Department Details
                      </Text>
                    </Row>
                  </BoxRow>
                  <Row direction="column" padding={"24px"}>
                    <Row margin={"0 0 16px"}>
                      {getDepartments().length > 0 ? (
                        <WrapLeft>
                          <Select
                            initialvalue={
                              resident.department
                                ? { label: resident.department }
                                : undefined
                            }
                            listen={currentUser}
                            change={(e) => updateSelect(e, "department")}
                            options={getDepartments()}
                          />
                          <Label>
                            <Required />
                            Department / Care Type
                          </Label>
                        </WrapLeft>
                      ) : (
                        <WrapLeft>
                          <Label>No Departments Available</Label>
                        </WrapLeft>
                      )}

                      <WrapRight>
                        <Input
                          name="unit_number"
                          initialvalue={resident.unit_number}
                          change={updateField}
                        />
                        <Label>Unit Number</Label>
                      </WrapRight>
                    </Row>

                    <Row margin={"0 0 16px"} direction="column">
                      <Select
                        change={(e) => updateSelect(e, "assignee")}
                        options={communityAdminSelectList}
                      />
                      <Label>
                        <Required />
                        Community Assignee
                      </Label>
                    </Row>
                  </Row>
                  <Row padding={"0 24px 24px"} justify={"space-between"}>
                    <div />
                    <Actions>
                      <Button outline onClick={() => setStep(1)}>
                        Back
                      </Button>
                      {availableStep > 2 && (
                        <Button primary onClick={finalStep}>
                          Next
                        </Button>
                      )}
                      {availableStep <= 2 && (
                        <Button primary disabled>
                          Next
                        </Button>
                      )}
                    </Actions>
                  </Row>
                </Box>
              )}

              {step === 3 && (
                <Box>
                  <BoxRow>
                    <Row justify="space-between" padding={"24px"}>
                      <Row
                        direction="column"
                        width={"75%"}
                        padding={"0 16px 0 0"}
                      >
                        <Text kind="p" weight={900} uppercase>
                          Packet Selection
                        </Text>

                        <Row padding={"24px 0 0"}>
                          <Select
                            initialvalue={{ label: "Select Packet" }}
                            change={(e) => selectPacket(e)}
                            options={packetsSelectList}
                          />
                        </Row>
                      </Row>

                      <Row
                        direction="column"
                        width={"25%"}
                        padding={"0 0 0 16px"}
                      >
                        <Row justify="center">
                          <Text kind="p" weight={900} center uppercase>
                            Due Date
                          </Text>
                        </Row>
                        <Row
                          justify="space-between"
                          padding={"24px 0 0"}
                          alignItems="center"
                        >
                          <Input
                            mask={"99/99/9999"}
                            name="due_date"
                            initialvalue={resident.due_date}
                            change={updateField}
                          />
                        </Row>
                      </Row>
                    </Row>
                  </BoxRow>

                  <Row justify="space-between">
                    <Column padding={"0 24px"} direction="column">
                      <ListItem padding={"24px 16px"}>
                        <Text kind="p" weight={900} uppercase>
                          Resident Agreements
                        </Text>
                      </ListItem>
                      {templates &&
                        templates.map((template, index) => (
                          <ListItem
                            alignItems="center"
                            padding={"24px 16px"}
                            key={index}
                          >
                            <Checkbox
                              detect={changed}
                              selected={template.selected}
                              onClick={() => toggleTemplate(template)}
                            />
                            <Text
                              kind="p"
                              underline
                              pointer
                              onClick={() => setPreview(template)}
                            >
                              {template.details.name}{" "}
                              {template.details.required && <Required />}
                            </Text>
                          </ListItem>
                        ))}
                    </Column>

                    <Row
                      direction="column"
                      style={{
                        borderLeft: "1px solid #ddd",
                        marginLeft: "-1px",
                      }}
                    >
                      {physicianList.length > 0 && (
                        <Row
                          padding={"0 24px"}
                          margin={"0 0 64px"}
                          direction="column"
                        >
                          <ListItem padding={"24px 16px"}>
                            <Text kind="p" weight={900} uppercase>
                              Physician Documents
                            </Text>
                          </ListItem>

                          {physicianList.map((template, index) => (
                            <ListItem
                              alignItems="center"
                              padding={"24px 16px"}
                              key={index}
                            >
                              <Checkbox
                                detect={changed}
                                selected={template.selected}
                                onClick={() => toggleTemplate(template)}
                              />
                              <Text
                                kind="p"
                                underline
                                pointer
                                onClick={() => setPreview(template)}
                              >
                                {template.details.name}{" "}
                                {template.details.required && <Required />}
                              </Text>
                            </ListItem>
                          ))}
                        </Row>
                      )}

                      {uploadsList.length > 0 && (
                        <Row
                          padding={"0 24px"}
                          margin={"0 0 64px"}
                          direction="column"
                        >
                          <ListItem padding={"24px 16px"}>
                            <Text kind="p" weight={900} uppercase>
                              Resident Attachments
                            </Text>
                          </ListItem>

                          {uploadsList.map((upload, index) => (
                            <ListItem
                              alignItems="center"
                              padding={"24px 16px"}
                              key={index}
                            >
                              <Checkbox
                                detect={changed}
                                selected={upload.selected}
                                onClick={() => toggleUpload(upload)}
                              />
                              <Text kind="p">
                                {upload.title} {upload.required}{" "}
                                {upload.required ? <Required /> : null}
                              </Text>
                            </ListItem>
                          ))}
                        </Row>
                      )}

                      {readAndReview.length > 0 && (
                        <Row padding={"0 24px"} direction="column">
                          <ListItem padding={"24px 16px"}>
                            <Text kind="p" weight={900} uppercase>
                              Read and Review
                            </Text>
                          </ListItem>

                          {readAndReview.map((doc, index) => (
                            <ListItem
                              alignItems="center"
                              padding={"24px 16px"}
                              key={index}
                            >
                              <Checkbox
                                detect={changed}
                                selected={doc.selected}
                                onClick={() => toggleReadAndReview(doc)}
                              />
                              <Text kind="p">
                                {doc.details.name}{" "}
                                {doc.details.required && <Required />}
                              </Text>
                            </ListItem>
                          ))}
                        </Row>
                      )}
                    </Row>
                  </Row>

                  <Row padding={"24px"} justify={"space-between"}>
                    <div />
                    <Actions>
                      <Button outline onClick={() => setStep(2)}>
                        Back
                      </Button>
                      <Button
                        primary
                        onClick={finish}
                        disabled={submittedPacket ? "" : "disabled"}
                      >
                        Finish
                      </Button>
                    </Actions>
                  </Row>
                </Box>
              )}
              <HelpButton />
              <PreviewTemplate preview={preview} />

              <Modal
                width="800"
                show={showImportModal}
                close={() => setShowImportModal(false)}
                title="Import Resident"
              >
                <ImportResident
                  close={() => setShowImportModal(false)}
                  integrations={community.integrations}
                  selectResident={selectResident}
                  {...props}
                />
              </Modal>
            </Wrap>
          ) : (
            <Loading />
          )}
        </Dash>
      </Row>
    </main>
  );
};

export default AddResident;
