import React, { useState, useEffect } from "react";
import { Modal } from "./index";
import styled from "styled-components";
import templates from "../util/templates";

const Page = styled.img`
  width: 80%;
  margin: 0 auto 40px;
  display: block;
`;

const PreviewTemplate = (props) => {
  const [preview, setPreview] = useState();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    async function getTemplate() {
      try {
        const _template = await templates.get(props.preview.id);
        // console.log(_template);
        setPreview(_template.data);
        setShowModal(true);
      } catch (err) {
        //alert(err)
        console.log(err);
      }
    }

    if (props.preview && props.preview.id) getTemplate();
  }, [props.preview]);

  if (!preview) return null;
  return (
    <Modal
      width="900"
      title={preview.details.name}
      show={showModal}
      close={() => setShowModal(false)}
    >
      {preview.pages.pages.map((page, index) => {
        return <Page key={index} src={page.background} />;
      })}
    </Modal>
  );
};

export default PreviewTemplate;
