import React from "react";
import styled from "styled-components";
import colors from "../util/colors";

const Primary = styled.div`
  font-size: 12px;
  color: white;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 30px;
  padding: ${(props) => (props.full ? "7px" : "7px 32px")};
  text-align: center;
  border: none;
  border-radius: 50px;
  background: ${colors.blue};
  text-transform: uppercase;
  font-weight: 800;
  width: ${(props) => (props.full ? "100%" : "auto")};
  max-width: ${(props) => (props.width ? props.width : "auto")}
  white-space: nowrap;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`;

const Green = styled.div`
  font-size: 12px;
  color: white !important;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 30px;
  padding: ${(props) => (props.full ? "7px" : "7px 32px")};
  text-align: center;
  border: none;
  border-radius: 50px;
  background: #81c307;
  text-transform: uppercase;
  font-weight: 800;
  width: ${(props) => (props.full ? "100%" : "auto")};
  white-space: nowrap;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`;

const Red = styled.div`
  font-size: 12px;
  color: white !important;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 30px;
  padding: ${(props) => (props.full ? "7px" : "7px 32px")};
  text-align: center;
  border: none;
  border-radius: 50px;
  background: ${colors.red};
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  width: ${(props) => (props.full ? "100%" : "auto")};
  white-space: nowrap;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`;

const Gray = styled.div`
  font-size: 12px;
  color: #8a96a1 !important;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 30px;
  padding: ${(props) => (props.full ? "7px" : "7px 32px")};
  text-align: center;
  border: none;
  border-radius: 50px;
  background: #dcdee0;
  text-transform: uppercase;
  font-weight: 800;
  width: ${(props) => (props.full ? "100%" : "auto")};
  white-space: nowrap;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`;

export default (props) => {
  if (props.primary) return <Primary {...props} />;
  if (props.green) return <Green {...props} />;
  if (props.gray) return <Gray {...props} />;
  if (props.red) return <Red {...props} />;
  return <Primary {...props} />;
};
