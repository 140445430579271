import axios from "axios";
import urls from "./urls";
import { format } from "date-fns";
import queryString from "query-string";
import * as path from "path";

function authenticate(code, redirect_uri) {
  return axios.post(urls.api + "pcc/auth", {
    code: code,
    redirect_uri: redirect_uri,
  });
}

function getFacilities(token, orgUuid) {
  return axios.get(
    urls.api + `pcc/facilities?orgUuid=${orgUuid}&token=${token}`
  );
}

function getFacility(token, orgUuid, facId) {
  return axios.get(
    urls.api + `pcc/facility?orgUuid=${orgUuid}&token=${token}&facId=${facId}`
  );
}

function getPatient(token, orgUuid, patientId) {
  return axios.get(
    urls.api +
      `pcc/patient?orgUuid=${orgUuid}&patientId=${patientId}&&token=${token}`
  );
}

function getPatients(token, orgUuid, facId, page, patientStatus) {
  if (page && patientStatus)
    return axios.get(
      urls.api +
        `pcc/patients?orgUuid=${orgUuid}&token=${token}&facId=${facId}&page=${page}&patientStatus=${patientStatus}`
    );
  if (page && !patientStatus)
    return axios.get(
      urls.api +
        `pcc/patients?orgUuid=${orgUuid}&token=${token}&facId=${facId}&patientStatus=Current&page=${page}`
    );
  return axios.get(
    urls.api +
      `pcc/patients?orgUuid=${orgUuid}&token=${token}&facId=${facId}&patientStatus=Current`
  );
}

function getPatientMatch(token, orgUuid, facId, search) {
  return axios.post(urls.api + "pcc/patientMatch", {
    token,
    orgUuid,
    facId,
    search,
  });
}

function getAllPatients(token, orgUuid, facilities) {
  const promise = new Promise(async (resolve, reject) => {
    let all = [];
    for (var i in facilities) {
      const facId = facilities[i].facId;
      var page = 0;
      var res = await axios.get(
        urls.api +
          `pcc/patients?orgUuid=${orgUuid}&token=${token}&facId=${facId}`
      );
      all = [...all, ...res.data.data];
      while (res.data.paging.hasMore && page < 5) {
        page++;
        res = await axios.get(
          urls.api +
            `pcc/patients?orgUuid=${orgUuid}&token=${token}&facId=${facId}&page=` +
            page
        );
        if (res.data.data) all = [...all, ...res.data.data];
      }
    }
    resolve(all);
  });
  return promise;
}

function refreshToken(token) {
  return axios.post(urls.api + "pcc/refreshToken", { refresh_token: token });
}

function parseResident(patient) {
  console.log("patient", patient);
  const _obj = {
    move_in_date: format(new Date(patient.admissionDate), "MM/dd/yyyy") || null,
    unit_number: patient.unitId || null,
    first_name: patient.firstName || null,
    middle_name: patient.middleName || null,
    last_name: patient.lastName || null,
    phone_number: patient.homePhone || null,
    email_address: patient.email || null,
    date_of_birth: format(new Date(patient.birthDate), "MM/dd/yyyy") || null,
    ssn: patient.ssn || null,
    medicare_number: patient.medicareNumber || null,
    medicaid_number: patient.medicaidNumber || null,
    gender: patient.gender || null,
    religion: patient.religion || null,
    medical_record_number: patient.medicalRecordNumber || null,
    pcc: {
      orgUuid: patient.orgUuid,
      patientId: patient.patientId,
      facId: patient.facId,
    },
  };
  return _obj;
}

async function addDocument(token, orgUuid, patientId, metadata) {
  console.log("Here is a bunch of bugs!! Yay!!!!!!!!!!!metadata: ", metadata);
  console.log("Here is a bunch of bugs!! Yay!!!!!!!!!!!patientId: ", patientId);
  console.log("Here is a bunch of bugs!! Yay!!!!!!!!!!!orgUuid: ", orgUuid);
  console.log("Here is a bunch of bugs!! Yay!!!!!!!!!!!token: ", token);
  const _data = await fetch(metadata.file);
  const _blob = await _data.blob();
  console.log("Here is a bunch of bugs!! Yay!!!!!!!!!!!blob: ", _blob);
  const _file = new File(
    [_blob],
    metadata.metadata.name.replace(/\s/g, "") + ".pdf",
    {
      type: "application/pdf",
    }
  );
  console.log("Here is our file: ", _file);
  const _metadata = new File(
    [
      `{ "documentName": ${path.basename(
        _file.name,
        ".pdf"
      )}, "effectiveDate": "2020-04-05T06:13:41.451Z", "documentCategory": 1 }`,
    ],
    "metadata.json",
    {
      type: "application/json",
    }
  );
  const formData = new FormData();
  formData.append("file", _file);
  formData.append("metadata", _metadata);

  return axios.post(
    `${urls.api}pcc/addDocument?${queryString.stringify({
      orgUuid,
      token,
      patientId,
      filePath: metadata.file,
    })}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

const functions = {
  authenticate,
  getFacilities,
  getFacility,
  getPatients,
  getAllPatients,
  refreshToken,
  getPatientMatch,
  getPatient,
  parseResident,
  addDocument,
};

export default functions;
