import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "./logo";
import Wrapper from "./wrapper";
import Row from "./row";
import Text from "./text";
import User from "./user";
import Alerts from "./alerts";
import Select from "./select";
import Contentful from "../util/contentful";
import user from "../util/user";
import communities from "../util/community";

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  background: white;
  z-index: 10;
  @media (prefers-color-scheme: dark) {
    background: #2e2f2f;
    p {
      color: white;
    }
  }
`;

const Spacer = styled.div`
  width: 100%;
  height: 80px;
`;

const LogoWrap = styled.div`
  padding-right: 48px;
  margin-right: 23px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  & img {
    margin-left: 16px;
    position: relative;
    top: 4px;
    cursor: pointer;
    width: 40px;
  }
`;

const Heart = styled.img`
  margin: 0 8px;
`;

export default (props) => {
  const [alerts, setAlerts] = useState([]);
  const [newAlert, setNewAlert] = useState(false);
  const [admin, setAdmin] = useState();
  const [community, setCommunity] = useState();
  const [options, setOptions] = useState([
    { label: "Logout", action: () => logout() },
    {
      label: "My Settings",
      action: () => (window.location.href = "/settings"),
    },
  ]);

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("pass");
    localStorage.removeItem("selected_department");
    if (window.drift) window.drift.reset();
    window.location.href = "/login";
  }

  async function selectCommunity(e) {
    admin.facilityId = e;
    const updated = await user.update(admin);
    localStorage.selected_department = "All Residents";
    window.location.href = "/dashboard";
  }

  function generateOptions(communities) {
    let array = [];
    for (var i in communities) {
      let obj = {
        label: communities[i].name,
        value: communities[i].id,
      };
      array.push(obj);
    }
    return array;
  }

  useEffect(() => {
    async function init() {
      const entries = await Contentful.query({
        content_type: "alerts",
        order: "-sys.createdAt",
        limit: 5,
      });

      const me = await user.get();
      let facilityId;
      if (me.data.facilityId == "admin") {
        facilityId = me.data.details.communities[0].id;
        me.data.facilityId = facilityId;
        const update = await user.update(me.data);
      } else {
        facilityId = me.data.facilityId;
      }
      const _location = await communities.get(facilityId);
      setCommunity(_location.data);
      setAdmin(me.data);
      if (me.data.companyId) {
        options.push({
          label: "Community Info",
          action: () => (window.location.href = "/information"),
        });
        setOptions(options);
      }

      if (
        entries.items.length > 0 &&
        entries.items[0].sys.id !== localStorage.latestAlert
      )
        setNewAlert(true);
      setAlerts(entries.items);
    }

    init();
  }, []);

  return (
    <div>
      <Header {...props}>
        <Wrapper>
          <Row alignItems="center" height="80px">
            <LogoWrap>
              <Logo />
            </LogoWrap>
            <Row justify="space-between" alignItems="center">
              {admin ? (
                <Row>
                  {community &&
                  admin.details &&
                  admin.details.communities &&
                  admin.details.communities.length > 1 ? (
                    <Row alignItems="center">
                      <Text weight="900" kind="p">
                        WE
                      </Text>{" "}
                      <Heart src="/icon-heart.svg" />{" "}
                      <Select
                        initialvalue={{ label: community.details.name }}
                        width={"300px"}
                        textonly
                        update={selectCommunity}
                        options={generateOptions(admin.details.communities)}
                      />
                    </Row>
                  ) : (
                    <Text weight={800} uppercase size={"14px"} kind="p">
                      We <img src="/icon-heart.svg" />{" "}
                      {community && community.details.name}
                    </Text>
                  )}
                </Row>
              ) : (
                <Row />
              )}

              {props.children ? (
                props.children
              ) : (
                <Actions>
                  <Alerts active={newAlert} options={alerts} />
                  <User options={options} />
                </Actions>
              )}
            </Row>
          </Row>
        </Wrapper>
      </Header>
      <Spacer />
    </div>
  );
};
