import React from "react";
import styled from "styled-components";

const Cell = styled.div`
  margin: 0 0 0 24px;
  display: flex;
  justify-content: center;
  width: 200px;
  max-width: ${(props) => (props.width ? props.width + "px" : "auto")};
`;

export default (props) => <Cell {...props} />;
