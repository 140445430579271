import React from "react";
import styled from "styled-components";
import colors from "../util/colors";

const PrimaryButton = styled.button`
  font-size: 12px;
  color: white;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 40px;
  padding: ${(props) => (props.full ? "8px 32px" : "8px 32px")};
  text-align: center;
  border: none;
  border-radius: 6px;
  background: ${colors.blue};
  text-transform: uppercase;
  font-weight: 800;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans", sans-serif;
  width: ${(props) => (props.full ? "100%" : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  transition-duration: 0.3s;
  white-space: nowrap;
  &:hover {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
  }
  &:focus {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
    outline: none;
  }
`;

const SmallPrimaryButton = styled.button`
  font-size: 12px;
  color: white;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 30px;
  padding: 7px 0;
  text-align: center;
  border: none;
  border-radius: 6px;
  background: ${colors.blue};
  text-transform: uppercase;
  font-weight: 800;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans", sans-serif;
  width: ${(props) => (props.width ? props.width : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  transition-duration: 0.3s;
  white-space: nowrap;
  &:hover {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
  }
  &:focus {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
    outline: none;
  }
`;

const RedButton = styled.button`
  font-size: 12px;
  color: white;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 40px;
  padding: 8px 32px;
  text-align: center;
  border: none;
  border-radius: 6px;
  background: ${colors.red};
  text-transform: uppercase;
  font-weight: 800;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans", sans-serif;
  width: ${(props) => (props.full ? "100%" : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  transition-duration: 0.3s;
  white-space: nowrap;
  &:hover {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
  }
  &:focus {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
    outline: none;
  }
`;

const SecondaryButton = styled.button`
  font-size: 12px;
  color: white;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 40px;
  padding: ${(props) => (props.full ? "8px 0" : "8px 32px")};
  text-align: center;
  border: none;
  border-radius: 6px;
  background: ${colors.orange};
  text-transform: uppercase;
  font-weight: 800;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans", sans-serif;
  width: ${(props) => (props.full ? "100%" : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
  transition-duration: 0.3s;
  white-space: nowrap;
  &:hover {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
  }
  &:focus {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
    outline: none;
  }
`;

const Outline = styled.button`
  font-size: 12px;
  color: #4d5864;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 40px;
  padding: 8px 32px;
  text-align: center;
  border: none;
  border-radius: 6px;
  background: none;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  width: ${(props) => (props.full ? "100%" : "auto")};
  cursor: pointer;
  transition-duration: 0.3s;
  border: 1px solid #4d5864;
  white-space: nowrap;
  @media (prefers-color-scheme: dark) {
    border: ${(props) =>
      props.stay ? "1px solid #4D5864" : "1px solid white"};
    color: ${(props) => (props.stay ? "#4D5864" : "white")};
  }
  &:hover {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
  }
  &:focus {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    opacity: 0.95;
    outline: none;
  }
`;

const Text = styled.button`
  font-size: 12px;
  color: #4d5864;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  height: 40px;
  padding: 8px 32px;
  text-align: center;
  background: none;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  width: ${(props) => (props.full ? "100%" : "auto")};
  cursor: pointer;
  transition-duration: 0.3s;
  border: none;
  white-space: nowrap;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  &:hover {
    opacity: 0.95;
  }
  &:focus {
    opacity: 0.95;
    outline: none;
  }
`;

export default (props) => {
  if (props.primary) return <PrimaryButton {...props} />;
  if (props.small) return <SmallPrimaryButton {...props} />;
  if (props.secondary) return <SecondaryButton {...props} />;
  if (props.outline) return <Outline {...props} />;
  if (props.red) return <RedButton {...props} />;
  if (props.text) return <Text {...props} />;
  return <PrimaryButton {...props} />;
};
