import React, { useEffect, useState } from "react";
import { Input, Row, Text, AgreementItem, Button, Draggable } from "./index";
import user from "../util/user";
import agreementsHelper from "../util/agreements";
import templatesHelper from "../util/templates";
import randomString from "randomstring";
import packetHelper from "../util/packets";

const PacketOptions = (props) => {
  const [department, setDepartment] = useState();
  const [packet, setPacket] = useState();
  const [agreements, setAgreements] = useState();
  const [physicianDocs, setPhysicianDocs] = useState();
  const [readAndReview, setReadAndReview] = useState();
  const [uploads, setUploads] = useState();
  const [allAgreements, setAllAgreements] = useState();
  const [packetName, setPacketName] = useState("");
  const [error, setError] = useState();
  const [isCreating, setIsCreating] = useState(false);

  function toggleAgreement(e) {
    for (var i in agreements) {
      if (agreements[i].id == e.id) {
        agreements[i].selected = !agreements[i].selected;
      }
    }
    setAgreements(agreements);
    setPacket(
      agreementsHelper.createPacket(
        packetName,
        agreements,
        physicianDocs,
        readAndReview,
        uploads
      )
    );
  }

  function togglePhysicianDoc(e) {
    for (var i in physicianDocs) {
      if (physicianDocs[i].id == e.id) {
        physicianDocs[i].selected = !physicianDocs[i].selected;
      }
    }
    setPhysicianDocs(physicianDocs);
    setPacket(
      agreementsHelper.createPacket(
        packetName,
        agreements,
        physicianDocs,
        readAndReview,
        uploads
      )
    );
  }

  function toggleReadAndReview(e) {
    for (var i in readAndReview) {
      if (readAndReview[i].id == e.id) {
        readAndReview[i].selected = !readAndReview[i].selected;
      }
    }
    setReadAndReview(readAndReview);
    setPacket(
      agreementsHelper.createPacket(
        packetName,
        agreements,
        physicianDocs,
        readAndReview,
        uploads
      )
    );
  }

  function toggleUpload(e) {
    for (var i in uploads) {
      if (uploads[i].id == e.id) {
        uploads[i].selected = !uploads[i].selected;
      }
    }
    setUploads(uploads);
    setPacket(
      agreementsHelper.createPacket(
        packetName,
        agreements,
        physicianDocs,
        readAndReview,
        uploads
      )
    );
  }

  async function create() {
    if (!packetName) {
      setError("Please enter a packet name");
    } else {
      setIsCreating(true);
      const obj = await agreementsHelper.createPacket(
        packetName,
        agreements,
        physicianDocs,
        readAndReview,
        uploads
      );
      await props.create(obj);
      setIsCreating(false);
    }
  }

  function updateOrder(e, group) {
    if (group == "agreements") {
      setAgreements(e);
      setPacket(
        agreementsHelper.createPacket(
          packetName,
          e,
          physicianDocs,
          readAndReview,
          uploads
        )
      );
    }
    if (group == "physician") {
      setPhysicianDocs(e);
      setPacket(
        agreementsHelper.createPacket(
          packetName,
          agreements,
          e,
          readAndReview,
          uploads
        )
      );
    }
    if (group == "readAndReview") {
      setReadAndReview(e);
      setPacket(
        agreementsHelper.createPacket(
          packetName,
          agreements,
          physicianDocs,
          e,
          uploads
        )
      );
    }
    if (group == "uploads") {
      setUploads(e);
      setPacket(
        agreementsHelper.createPacket(
          packetName,
          agreements,
          physicianDocs,
          readAndReview,
          e
        )
      );
    }
  }

  function update() {
    packet.name = packetName;
    props.update(packet);
  }

  function deletePacket() {
    const check = window.confirm(
      "Are you sure you want to delete this packet?"
    );
    if (check) props.delete(packet);
  }

  useEffect(() => {
    async function init() {
      const me = await user.get();
      let agreementsList = await agreementsHelper.getAgreements(
        props.companyId,
        props.facilityId
      );

      if (props.department.available_uploads) {
        for (var i in props.department.available_uploads) {
          props.department.available_uploads[i].id = randomString.generate();
        }
      }
      setAllAgreements(agreementsList);

      if (props.packet) {
        const _departmentAgreements = agreementsHelper.filterDepartmentAgreements(
          props.department.available_agreements,
          agreementsList
        );

        const _departmentTemplates = null; //PULL TEMPLATES FOR READ ONLY
        const _packet = agreementsHelper.applyPacket(
          _departmentAgreements,
          props.packet,
          props.department.available_uploads
        );

        setPacketName(_packet.name);
        setPacket(_packet);
        setAgreements(_packet.agreements);
        setPhysicianDocs(_packet.physicianDocs);
        setReadAndReview(_packet.readAndReview);
        setUploads(_packet.uploads);
      } else {
        const _departmentAgreements = agreementsHelper.filterDepartmentAgreements(
          props.department.available_agreements,
          agreementsList
        );

        setAgreements(
          _departmentAgreements.filter(
            (agreement) =>
              !agreement.details.physician_doc && !agreement.details.read_only
          )
        );
        setPhysicianDocs(
          _departmentAgreements.filter(
            (agreement) => agreement.details.physician_doc
          )
        );
        setReadAndReview(
          _departmentAgreements.filter(
            (agreement) => agreement.details.read_only
          )
        );
        setUploads(props.department.available_uploads);
      }

      setDepartment(props.department);
    }

    init();
  }, [props.department, props.packet]);

  if (!department) return null;
  return (
    <Row direction="column">
      <Row padding="24px 24px 0">
        <Input
          initialvalue={packetName}
          change={(e) => setPacketName(e.target.value)}
          placeholder="Packet Name"
        />
      </Row>

      <Row justify="space-between" padding="24px">
        <Row direction="column" padding="0 24px 0 0">
          <Row margin="0 0 16px">
            <Text kind="p" color="#4D5864" weight="800" uppercase>
              Agreements
            </Text>
          </Row>

          <Draggable
            items={agreements}
            onChange={(e) => updateOrder(e, "agreements")}
            itemRenderer={(item) => {
              return <AgreementItem toggle={toggleAgreement} item={item} />;
            }}
          />
        </Row>

        <Row direction="column">
          {allAgreements &&
          agreementsHelper
            .filterDepartmentAgreements(
              props.department.available_agreements,
              allAgreements
            )
            .filter((agreement) => agreement.details.physician_doc).length >
            0 ? (
            <Row direction="column" margin="0 0 24px">
              <Row margin="0 0 16px">
                <Text kind="p" color="#4D5864" weight="800" uppercase>
                  Physician Docs
                </Text>
              </Row>

              <Draggable
                items={physicianDocs}
                onChange={(e) => updateOrder(e, "physician")}
                itemRenderer={(item) => {
                  return (
                    <AgreementItem toggle={togglePhysicianDoc} item={item} />
                  );
                }}
              />
            </Row>
          ) : null}

          {allAgreements &&
          agreementsHelper
            .filterDepartmentAgreements(
              props.department.available_agreements,
              allAgreements
            )
            .filter((agreement) => agreement.details.read_only).length > 0 ? (
            <Row direction="column" margin="0 0 24px">
              <Row margin="0 0 16px">
                <Text kind="p" color="#4D5864" weight="800" uppercase>
                  Read and Review
                </Text>
              </Row>

              <Draggable
                items={readAndReview}
                onChange={(e) => updateOrder(e, "readAndReview")}
                itemRenderer={(item) => {
                  return (
                    <AgreementItem toggle={toggleReadAndReview} item={item} />
                  );
                }}
              />
            </Row>
          ) : null}

          {uploads && uploads.length > 0 ? (
            <Row direction="column" margin="0 0 24px">
              <Row margin="0 0 16px">
                <Text kind="p" color="#4D5864" weight="800" uppercase>
                  Attachments
                </Text>
              </Row>

              <Draggable
                items={uploads}
                onChange={(e) => updateOrder(e, "uploads")}
                itemRenderer={(item) => {
                  return <AgreementItem toggle={toggleUpload} item={item} />;
                }}
              />
            </Row>
          ) : null}
        </Row>
      </Row>

      <Row padding="0 24px 0 0" justify="flex-end">
        {error && <Text color="#D60069">{error}</Text>}
      </Row>

      <Row padding="24px" justify="flex-end">
        {props.packet ? (
          <>
            <Button red margin={"0 16px 0 0"} onClick={deletePacket}>
              Delete
            </Button>
            <Button onClick={update}>Update</Button>
          </>
        ) : (
          <Button onClick={create} disabled={isCreating}>
            Create Packet
          </Button>
        )}
      </Row>
    </Row>
  );
};

export default PacketOptions;
