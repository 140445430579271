import axios from "axios";
import urls from "./urls";
import applications from "./application";
import pdf from "./pdf";
import { arrayBufferToBlob } from "blob-util";
import aws from "./aws";
import randomString from "randomstring";
import packetHelper from "./packets";
import upload from "../components/upload";

async function getAgreements(companyId, communityId) {
  const promise = new Promise((resolve, reject) => {
    let templates = [];

    let companyPage = 0;

    async function companyIterate() {
      const companyTemplates = await getCompanyTemplates(
        companyId,
        companyPage
      );

      if (companyTemplates.data.length) {
        templates.push.apply(templates, companyTemplates.data);
        companyPage++;
        companyIterate();
      } else {
        commmunityIterate();
      }
    }

    let communityPage = 0;

    async function commmunityIterate() {
      const communityTemplates = await getCommunityTemplates(
        communityId,
        communityPage
      );

      if (communityTemplates.data.length) {
        templates.push.apply(templates, communityTemplates.data);
        communityPage++;
        commmunityIterate();
      } else {
        resolve(templates);
      }
    }

    companyIterate();
  });

  return promise;
}

function create(agreement) {
  var object = {
    details: agreement.details,
    pages: agreement.pages,
    templateId: agreement.id,
    auditTrail: {},
  };
  return axios.post(urls.api + "agreement/", object, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function get(id) {
  return axios.get(urls.api + "agreement/" + id, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function update(agreement) {
  return axios.patch(urls.api + "agreement/", agreement, {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function getCompanyTemplates(id, offset) {
  return axios.get(
    urls.api +
      "companyTemplates/" +
      id +
      "?offset=" +
      (offset ? offset * 20 : 0),
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

function getCommunityTemplates(id, offset) {
  return axios.get(
    urls.api +
      "communityTemplates/" +
      id +
      "?offset=" +
      (offset ? offset * 20 : 0),
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

async function assignNewAgreementId(
  newAgreementId,
  agreementId,
  applicationId
) {
  //GET LATEST APPLICATION TO PREVENT OVERWRITTING
  const application = await applications.get(applicationId);
  for (var i in application.data.agreements.resident) {
    if (application.data.agreements.resident[i].template_id == agreementId)
      application.data.agreements.resident[i].agreement_id = newAgreementId;
  }
  for (var i in application.data.agreements.physician) {
    if (application.data.agreements.physician[i].template_id == agreementId)
      application.data.agreements.physician[i].agreement_id = newAgreementId;
  }
  //UPDATE ONLY AGREEMENT ID ON AGREEMENT
  return applications.update(application.data);
}

async function download(agreement, application) {
  const promise = new Promise((resolve, reject) => {
    async function runDownload() {
      try {
        let file_name;

        //if(!agreement.agreement_filename){
        if (true) {
          const response = await get(agreement.agreement_id);
          const html = await applications.generateHtml(response.data);
          const generated = await pdf.generate(
            html,
            response.data.details.size,
            "pdf"
          );
          file_name = generated.data.Key;
          agreement.agreement_filename = file_name;
          const latestApplication = await applications.get(application.id);
          for (var i in latestApplication.data.agreements.resident) {
            if (
              latestApplication.data.agreements.resident[i].template_id ==
              agreement.template_id
            )
              latestApplication.data.agreements.resident[i] = agreement;
          }
          const updatedApplication = await applications.update(
            latestApplication.data
          );
        } else {
          file_name = agreement.agreement_filename;
        }

        const buffer = await aws.getObject(file_name);
        const blob = arrayBufferToBlob(buffer.data, "application/pdf");

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, `${file_name}`);
        } else {
          const url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          const response = await get(agreement.agreement_id);
          const download_file_name = response.data.details.name;
          a.download = download_file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        }

        resolve(true);
      } catch (error) {
        console.log("download error", error);
        reject("Error");
        alert(
          "Please change browser permissions to download files, and/or disable any ad blockers"
        );
      }
    }

    runDownload();
  });

  return promise;
}

async function generatePhysicianFaxPdf(application) {
  const imagesList = { pages: { pages: [] }, details: {} };
  for (var i in application.agreements.physician) {
    if (application.agreements.physician[i].marked) {
      for (var j in application.agreements.physician[i].pages) {
        imagesList.pages.pages.push(
          application.agreements.physician[i].pages[j]
        );
      }
    }
  }
  const html = applications.generateHtml(imagesList);
  return pdf.generate(html, null, "pdf");
}

async function reset(agreement, applicationId) {
  var check = window.confirm(
    "This will remove all signatures and reset all fields from all signers"
  );
  if (check) {
    if (agreement.signatures.Resident)
      agreement.signatures.Resident.signed = false;
    if (agreement.signatures.Community)
      agreement.signatures.Community.signed = false;
    if (agreement.signatures.Manager)
      agreement.signatures.Manager.signed = false;
    delete agreement.agreement_id;
    delete agreement.agreement_filename;

    const application = await applications.get(applicationId);

    for (var i in application.data.agreements.resident) {
      if (
        application.data.agreements.resident[i].template_id ==
        agreement.template_id
      )
        application.data.agreements.resident[i] = agreement;
    }

    return applications.update(application.data);
  }
}

async function remove(agreement, applicationId) {
  var check = window.confirm("Are you sure you want to remove this agreement?");
  if (check) {
    return applications.removeAgreement(applicationId, agreement);
  }
}

function filterDepartmentAgreements(available_agreements, agreements) {
  let array = [];
  for (var i in available_agreements) {
    const exists = agreements.filter(
      (agreement) => agreement.id === available_agreements[i]
    );
    if (exists && exists.length > 0) array.push(exists[0]);
  }

  return packetHelper.orderAlphabetically(array);
}

function createPacket(
  packetName,
  agreements,
  physicianDocs,
  readAndReview,
  uploads
) {
  let obj = {
    name: packetName,
    agreements: agreements,
    physicianDocs: physicianDocs,
    readAndReview: readAndReview,
    uploads: uploads,
  };

  return obj;
}

function applyPacket(agreements, packet, uploads) {
  const _physicianDocs = agreements.filter(
    (agreement) => agreement.details.physician_doc
  );
  const _readOnly = agreements.filter(
    (agreement) => agreement.details.read_only
  );
  const _regularAgreements = agreements.filter(
    (agreement) =>
      !(agreement.details.physician_doc || agreement.details.read_only)
  );

  //Add any that are missing
  for (const i in _physicianDocs) {
    const id = i;
    const _contains = packet.physicianDocs.filter(
      (_agreement) => _agreement.id === _physicianDocs[id].id
    );
    if (_contains.length === 0) packet.physicianDocs.push(_physicianDocs[id]);
  }

  for (const j in _readOnly) {
    const id = j;
    const _contains = packet.readAndReview.filter(
      (_agreement) => _agreement.id === _readOnly[id].id
    );
    if (_contains.length === 0) packet.readAndReview.push(_readOnly[id]);
  }

  for (const l in _regularAgreements) {
    const id = l;
    const _contains = packet.agreements.filter(
      (_agreement) => _agreement.id === _regularAgreements[id].id
    );
    if (_contains.length === 0) packet.agreements.push(_regularAgreements[id]);
  }

  for (const m in uploads) {
    const id = m;
    const _contains = packet.uploads.filter(
      (_upload) => _upload.title === uploads[id].title
    );
    if (_contains.length === 0) packet.uploads.push(uploads[id]);
  }

  //Remove any that have been removed
  packet.agreements = packet.agreements.filter((_packetAgreement) => {
    const _match = agreements.filter(
      (_agreement) => _agreement.id === _packetAgreement.id
    );
    if (_match.length === 0 || _match[0].details.physician_doc) {
      return false;
    } else {
      return true;
    }
  });

  packet.readAndReview = packet.readAndReview.filter((_packetAgreement) => {
    const _match = agreements.filter(
      (_agreement) => _agreement.id === _packetAgreement.id
    );
    if (_match.length === 0) {
      return false;
    } else {
      return true;
    }
  });

  packet.physicianDocs = packet.physicianDocs.filter((_packetAgreement) => {
    const _match = agreements.filter(
      (_agreement) => _agreement.id === _packetAgreement.id
    );
    if (_match.length === 0) {
      return false;
    } else {
      return true;
    }
  });

  packet.uploads =
    packet.uploads &&
    packet.uploads.filter((_packetUpload) => {
      const _match =
        uploads &&
        uploads.filter((_upload) => _upload.title === _packetUpload.title);
      if (_match.length === 0) {
        return false;
      } else {
        return true;
      }
    });

  //update if they are required or not
  for (const i in packet) {
    if (i === "agreements" || i === "physicianDocs" || i === "readAndReview") {
      for (const j in packet[i]) {
        const _filtered = agreements.filter(
          (_agreement) => _agreement.id === packet[i][j].id
        );
        if (_filtered.length > 0) {
          packet[i][j].details.required = _filtered[0].details.required;
          packet[i][j].details.physician_doc =
            _filtered[0].details.physician_doc;
          packet[i][j].details.read_only = _filtered[0].details.read_only;
        }
      }
    }

    if (i === "uploads") {
      for (const j in packet[i]) {
        const _filtered = uploads.filter(
          (_upload) => _upload.title === packet[i][j].title
        );
        if (_filtered.length > 0) {
          packet[i][j].required = _filtered[0].required;
        }
      }
    }
  }

  return packet;
}

const functions = {
  getAgreements,
  create,
  get,
  download,
  reset,
  remove,
  assignNewAgreementId,
  update,
  generatePhysicianFaxPdf,
  filterDepartmentAgreements,
  createPacket,
  applyPacket,
};

export default functions;
