import React, { useState, useEffect } from "react";
import styled from "styled-components";
import application from "../util/application";
import user from "../util/user";
import communities from "../util/community";
import queryString from "query-string";
import Moment from "react-moment";
import {
  Pill,
  HelpButton,
  Header,
  Sidebar,
  Row,
  Text,
  Box,
  Wrapper,
  Link,
  Button,
  Input,
  BoxRow,
  Cells,
  Cell,
  Dashboard,
  Dots,
  Add,
  Loading,
  Select,
  Paginate,
} from "../components";
import includes from "array-includes";

const Summary = (props) => {
  const [applications, setApplications] = useState([]);
  const [loaded, setLoaded] = useState();
  const [refreshed, refresh] = useState(false);
  const [total, setTotal] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  async function unArchiveApplication(app) {
    const check = window.confirm(
      "Are you sure you want to archive this application?"
    );
    if (check) {
      async function unarchive() {
        const _latest = await application.get(app.id);
        _latest.data.archived = false;
        _latest.data.progress.current = "Unarchived";
        await application.update(_latest.data);

        const me = await user.get();
        console.log("before");
        const list = await application.listAll(me.data.facilityId);
        console.log("after");
        setApplications(list.data);
      }

      unarchive();
    }
  }

  async function deleteApplication(app) {
    const check = window.confirm(
      `Are you sure you want to delete ${app.myInfo.contact_info.name}? THIS INFORMATION CANNOT BE RECOVERED`
    );
    if (check) {
      await application.remove(app.id);

      const me = await user.get();
      const list = await application.listAll(me.data.facilityId);
      setApplications(list.data);
    }
  }

  function generateOptions(application) {
    const array = [
      {
        label: "View",
        action: () => props.history.push("/application/" + application.id),
      },
      { label: "Delete", action: () => deleteApplication(application) },
    ];
    if (application.archived) {
      array.push({
        label: "Unarchive",
        action: () => unArchiveApplication(application),
      });
    }
    return array;
  }

  function runSearch(e) {
    e.preventDefault();
  }

  useEffect(() => {
    setSearch("");
    setLoading(true);

    async function run() {
      const me = await user.get();
      const list = await application.listAll(me.data.facilityId);
      setApplications(list.data);
      setLoading(false);
    }

    run();
  }, [refreshed]);

  return (
    <main>
      <Header />
      <Row>
        <Sidebar />

        <Dashboard padding={"48px"}>
          <Row
            alignItems="center"
            justify="space-between"
            margin={"0 0 16px"}
            padding={"0 0 0 4px"}
          >
            <form onSubmit={runSearch}>
              <Input
                searchicon="true"
                initialvalue={search}
                change={(e) => setSearch(e.target.value)}
                placeholder="Search by Name"
                width={"240px"}
              />
            </form>
          </Row>

          {loading ? (
            <Loading />
          ) : (
            <Box>
              <BoxRow
                padding={"16px 24px"}
                justify="space-between"
                alignItems="center"
              >
                <Text kind="p" weight={800} size={"14px"} uppercase>
                  Resident Name
                </Text>

                <Cells>
                  <Cell width={145}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>
                      Department
                    </Text>
                  </Cell>

                  <Cell width={120}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>
                      Created
                    </Text>
                  </Cell>

                  <Cell width={155}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>
                      Status
                    </Text>
                  </Cell>

                  <Cell width={35} />
                </Cells>
              </BoxRow>

              {applications.length > 0 &&
              applications.filter((app) => {
                const check = app.myInfo.contact_info.name
                  .toLowerCase()
                  .indexOf(search.toLowerCase());
                if (check === -1) {
                  return false;
                } else {
                  return true;
                }
              }).length > 0 ? (
                applications.length > 0 &&
                applications
                  .filter((app) => {
                    const check = app.myInfo.contact_info.name
                      .toLowerCase()
                      .indexOf(search.toLowerCase());
                    if (check === -1) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .map((application, index) => {
                    return (
                      <BoxRow
                        padding={"16px 24px"}
                        justify="space-between"
                        alignItems="center"
                        key={index}
                      >
                        <Link to={"/application/" + application.id}>
                          <Text kind="p" underline>
                            {application.myInfo.contact_info.name}
                          </Text>
                        </Link>

                        <Cells>
                          <Cell width={145}>
                            <Text kind="p" center color={"#aaa"} size={"12px"}>
                              {application.department
                                ? application.department
                                : "None Assigned"}
                            </Text>
                          </Cell>

                          <Cell width={120}>
                            <Text
                              kind="p"
                              center
                              color={"#aaa"}
                              size={"12px"}
                              uppercase
                            >
                              <Moment format="MM/DD/YYYY">
                                {application.createdAt}
                              </Moment>
                            </Text>
                          </Cell>

                          <Cell width={155}>
                            {application.progress.current == "New!" && (
                              <Link
                                full="true"
                                to={"/application/" + application.id}
                              >
                                <Pill full primary>
                                  {application.progress.current}
                                </Pill>
                              </Link>
                            )}
                            {application.progress.current == "complete" && (
                              <Link
                                full="true"
                                to={"/application/" + application.id}
                              >
                                <Pill full green>
                                  {application.progress.current}
                                </Pill>
                              </Link>
                            )}
                            {application.progress.current !== "New!" &&
                              application.progress.current !== "complete" && (
                                <Link
                                  full="true"
                                  to={"/application/" + application.id}
                                >
                                  <Pill full gray>
                                    {application.progress.current}
                                  </Pill>
                                </Link>
                              )}
                          </Cell>

                          <Cell width={35}>
                            <Dots options={generateOptions(application)} />
                          </Cell>
                        </Cells>
                      </BoxRow>
                    );
                  })
              ) : (
                <Row padding="16px 24px">
                  {applications.length > 0 ? (
                    <Text kind="p">No Results for "{search}"</Text>
                  ) : (
                    <Text kind="p">
                      Search by Residents Name and press "Enter"
                    </Text>
                  )}
                </Row>
              )}
            </Box>
          )}

          <HelpButton />
        </Dashboard>
      </Row>
    </main>
  );
};

export default Summary;
