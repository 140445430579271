import React, { useState, useEffect } from "react";
import styled from "styled-components";
import selectList from "../util/selectList";
import communityHelper from "../util/community";
import {
  MessageBar,
  PacketOptions,
  Modal,
  Select,
  Box,
  BoxRow,
  Add,
  Row,
  Text,
} from "./index";

const Arrow = styled.img`
  width: 20px;
  cursor: pointer;
`;

const Open = styled(Row)`
  cursor: pointer;
`;

const Packets = (props) => {
  const [department, setDepartment] = useState();
  const [community, setCommunity] = useState();
  const [showAddPacketModal, setShowAddPacketModal] = useState(false);
  const [message, setMessage] = useState();
  const [changed, change] = useState();
  const [showPacket, setShowPacket] = useState(0);

  function selectDepartment(e) {
    const _department = community.details.departments.filter(
      (dep) => dep.name === e
    );
    setDepartment(_department[0]);
  }

  async function createPacket(packet) {
    if (!department.packets) department.packets = [];
    department.packets.push(packet);
    for (var i in community.details.departments) {
      if (community.details.departments[i].name == department.name) {
        community.details.departments[i] = department;
      }
    }

    const updated = await communityHelper.update(community);
    setShowAddPacketModal(false);
    setMessage("Packet created successfully");
    change(!changed);
  }

  async function updatePacket(packet) {
    for (var i in department.packets) {
      if (department.packets[i].name == packet.name)
        department.packets[i] = packet;
    }

    for (var i in community.details.departments) {
      if (community.details.departments[i].name == department.name) {
        community.details.departments[i] = department;
      }
    }

    const updated = await communityHelper.update(community);
    setShowAddPacketModal(false);
    setMessage("Packet updated successfully");
    change(!changed);
  }

  async function deletePacket(packet) {
    department.packets = department.packets.filter(
      (_packet) => _packet.name !== packet.name
    );

    for (var i in community.details.departments) {
      if (community.details.departments[i].name == department.name) {
        community.details.departments[i] = department;
      }
    }

    const updated = await communityHelper.update(community);
    setShowAddPacketModal(false);
    setMessage("Packet removed successfully");
    change(!changed);
  }

  useEffect(() => {
    // console.log("community", props.community);
    setCommunity(props.community);
    setDepartment(props.community.details.departments[0]);
  }, [props.community]);

  if (!community) return null;
  return (
    <>
      <Row justify="space-between" margin={"0 0 32px"}>
        <Select
          textonly
          change={(e) => selectDepartment(e.value)}
          options={selectList.generateDepartmentsList(
            community.details.departments
          )}
        />

        <Add onClick={() => setShowAddPacketModal(true)} />
      </Row>

      {department && (
        <Box>
          {department.packets ? (
            department.packets.map((packet, index) => (
              <BoxRow key={index} direction="column">
                <Open
                  justify="space-between"
                  alignItems="center"
                  padding="0 16px 0 0"
                  onClick={() =>
                    showPacket === index
                      ? setShowPacket(undefined)
                      : setShowPacket(index)
                  }
                >
                  <Text padding={"16px"} kind="p" weight="700" uppercase>
                    {packet.name}
                  </Text>

                  <Arrow src="/icon-downarrow.svg" />
                </Open>

                {showPacket == index && (
                  <Row>
                    <PacketOptions
                      packet={packet}
                      delete={deletePacket}
                      update={updatePacket}
                      department={department}
                      companyId={community.companyId}
                      facilityId={community.id}
                    />
                  </Row>
                )}
              </BoxRow>
            ))
          ) : (
            <BoxRow padding="16px">
              <Text kind="p">No Packets for selected department</Text>
            </BoxRow>
          )}
        </Box>
      )}

      <Modal
        width="900"
        title="Add Packet"
        show={showAddPacketModal}
        close={() => setShowAddPacketModal(false)}
      >
        <Row>
          <PacketOptions
            create={createPacket}
            department={department}
            companyId={community.companyId}
            facilityId={community.id}
          />
        </Row>
      </Modal>

      <MessageBar message={message} listen={changed} />
    </>
  );
};

export default Packets;
