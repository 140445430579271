import React from "react";
import styled from "styled-components";

const Add = styled.img`
  width: 30px;
  margin-bottom: -8px;
  cursor: pointer;
`;

export default (props) => <Add {...props} src="/icon-add.svg" />;
