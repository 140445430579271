import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";

const Wrap = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  &:after {
    content: "";
    width: 20px;
    height: 20px;
    background-image: ${(props) =>
      props.searchIcon ? 'url("/icon-search.svg")' : null};
    background-size: contain;
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 2;
  }
`;

const Input = styled.input`
  font-size: 13px;
  display: block;
  color: ${(props) => (props.color ? props.color : "#4D5864 !important")};
  font-family: "Open Sans", sans-serif;
  height: 40px;
  border: 1px solid #c4cad0;
  background: white;
  padding: 8px 12px;
  padding-right: ${(props) => (props.searchIcon ? "32px" : "auto")};
  border-radius: 6px;
  width: ${(props) => (props.width ? props.width : "100%")};
  transition-duration: 0.3s;
  box-shadow: none;

  &:focus {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    outline: none;
  }
`;

const Mask = styled(InputMask)`
  font-size: 13px;
  display: block;
  color: ${(props) => (props.color ? props.color : "#4D5864 !important")};
  height: 40px;
  border: 1px solid #c4cad0;
  background: white;
  padding: 8px 12px;
  padding-right: ${(props) => (props.searchIcon ? "32px" : "auto")};
  border-radius: 6px;
  width: ${(props) => (props.width ? props.width : "100%")};
  transition-duration: 0.3s;
  box-shadow: none;

  &:focus {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    outline: none;
  }
`;

export default (props) => {
  const [value, setValue] = useState(
    props.initialvalue ? props.initialvalue : ""
  );

  function updateField(e) {
    if (props.change) props.change(e);
    setValue(e.target.value);
  }

  useEffect(() => {
    setValue(props.initialvalue ? props.initialvalue : "");
  }, [props.initialvalue]);

  return (
    <Wrap searchIcon={props.searchicon}>
      {props.mask && <Mask {...props} onChange={updateField} value={value} />}
      {!props.mask && <Input {...props} onChange={updateField} value={value} />}
    </Wrap>
  );
};
