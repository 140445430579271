import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import user from "../util/user";
import communities from "../util/community";
import colors from "../util/colors";
import moment from "moment";
import {
  Label,
  Input,
  Row,
  Text,
  Link,
  Button,
  Logo,
  MessageBar,
} from "../components";
import { Redirect, BrowserRouter as Router } from "react-router-dom";
import ReactInputVerificationCode from "react-verification-code-input";
import "./styles.css";

const FormWrapper = styled.div`
  font-family: "Open Sans", sans-serif;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  @media (prefers-color-scheme: dark) {
    p,
    label,
    h1 {
      color: white;
    }
  }
`;

const Form = styled.form`
  max-width: 400px;
  width: 100%;
`;

export const StyledSeconds = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.002em;
  color: rgba(255, 255, 255, 0.4);
`;

const StyledReactInputVerificationCode = styled.div`
  display: flex;
  justify-content: center;

  --ReactInputVerificationCode-itemWidth: 40px;
  --ReactInputVerificationCode-itemHeight: 48px;
  --ReactInputVerificationCode-itemSpacing: 8px;

  .ReactInputVerificationCode__item {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #fff;

    background: rgba(53, 67, 98, 1);
    border: 1px solid
      ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
    border-radius: 4px;
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #36c6d9;
  }
`;

const TwoFactor = (props) => {
  const [value, setValue] = useState("");
  const [seconds, setSeconds] = useState(null);
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState();
  const [isInvalid, setIsInvalid] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [servCode, setServCode] = useState("");
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const codeVerifyInput = useRef(null);

  const verifyCompleteCode = (code) => {
    setValue(code);
    if (code !== "") {
      setError(null);
    }
    if (code.length === 6 && code === servCode) {
      setMessage("Sucess! Verification code is correct.");
      localStorage.setItem("twoFactorValid", true);
      setShowMessage(true);
      setIsValid(true);
      setTimeout(() => {
        props.history.push("/dashboard");
      }, "1000");
    } else if (code.length === 6) {
      setValue([]);
      setIsInvalid(true);
      setSeconds(60);

      let mySeconds = 60;
      const intervalId = setInterval(() => {
        mySeconds = mySeconds - 1;
        setSeconds(mySeconds);
        if (mySeconds === 0) {
          clearInterval(intervalId);
          setSeconds(null);
        }
      }, 1000);

      setTimeout(() => {
        setIsInvalid(false);
      }, 1000);

      setMessage("Wrong code! Try again.");
      setError("Sorry incorrect code. Please try again.");
      localStorage.setItem("twoFactorValid", false);
    }
  };

  useEffect(() => {
    async function checkTokenStatus() {
      try {
        const verifyCode = await user.generateCode();
        setServCode(verifyCode.data.code);
        localStorage.setItem("twoFactorDate", moment());
      } catch (error) {
        // localStorage.token = undefined;
        // localStorage.pass = undefined;
      }
    }

    if (localStorage.token) checkTokenStatus();
  }, []);

  return (
    <div className="App">
      <Logo margin={"24px"} absolute />
      <FormWrapper>
        <Form>
          <div>
            <Text kind="h1" size={"36px"} weight={"900"} center>
              Two-Factor Authentication
            </Text>
            <Text kind="p" size={"16px"} margin={"8px 0 48px"} center>
              Please check your email. You should have received a verification
              code from Senior Sign.
            </Text>
          </div>

          <StyledReactInputVerificationCode isInvalid={isInvalid}>
            <ReactInputVerificationCode
              length={6}
              value={value}
              ref={codeVerifyInput}
              onChange={(newValue) => {
                setValue(newValue);
                if (newValue !== "") {
                  setError(null);
                }
              }}
              onComplete={(val) => verifyCompleteCode(val)}
            />
          </StyledReactInputVerificationCode>
          <Label>{isValid && "Verification code success!"}</Label>

          <center>{error && <Label color={"red"}>{error}</Label>}</center>

          <br />
          <Button
            primary
            full
            onClick={() => {
              setValue("");
            }}
            isDisabled={isValid}
          >
            Login
          </Button>

          {seconds && (
            <StyledSeconds>{`Verification code has been re-sent (${seconds}s)`}</StyledSeconds>
          )}
        </Form>
      </FormWrapper>
      <MessageBar message={message} listen={showMessage} />
    </div>
  );
};

export default TwoFactor;
