import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Contentful from "../util/contentful";
import {
  Tabs,
  Tab,
  Link,
  Header,
  Row,
  Sidebar,
  Dashboard,
  Text,
  Markdown,
} from "../components";

const Page = styled.div`
  background: white;
  padding: 48px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
`;

const Article = (props) => {
  const [variable, setVariable] = useState();
  const [article, setArticle] = useState();

  useEffect(() => {
    async function getPage() {
      const _article = await Contentful.query({
        content_type: "helpArticle",
        "fields.url": "/" + window.location.pathname.split("/").pop(),
      });
      setArticle(_article.items[0].fields);
    }

    getPage();
  }, []);

  return (
    <main>
      <Header />
      <Row>
        <Sidebar />
        <Dashboard padding={"0 0 128px"}>
          <Tabs>
            <Link to="/help-center?tab=topics">
              <Tab active>
                <Text kind="p" weight={700} size={"14px"} uppercase>
                  Help Topics
                </Text>
              </Tab>
            </Link>
            <Link to="/help-center?tab=updates">
              <Tab>
                <Text kind="p" weight={700} size={"14px"} uppercase>
                  New Updates
                </Text>
              </Tab>
            </Link>
            <Link to="/help-center?tab=contact">
              <Tab>
                <Text kind="p" weight={700} size={"14px"} uppercase>
                  Contact Us
                </Text>
              </Tab>
            </Link>
          </Tabs>

          {article && (
            <Row padding={"48px"}>
              <Page>
                {article.category && (
                  <Link to={"/help-center" + article.category.fields.url}>
                    <Text
                      size={"12px"}
                      weight={700}
                      margin={"0 0 16px"}
                    >{`< Back`}</Text>
                  </Link>
                )}

                <Markdown source={article.article} />
              </Page>
            </Row>
          )}
        </Dashboard>
      </Row>
    </main>
  );
};

export default Article;
