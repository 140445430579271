import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import colors from "../util/colors";

const Item = styled(Link)`
  color: ${colors.blue};
  cursor: pointer;
  position: relative;
  z-index: 1;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  width: ${(props) => (props.full ? "100%" : "auto")};
`;

export default (props) => <Item {...props} />;
