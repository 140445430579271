import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../util/colors";

const Message = styled.div`
  width: 100%;
  padding: 16px 32px;
  position: fixed;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  bottom: ${(props) => (props.show ? "0px" : "-100px")};
  left: 0;
  background: ${colors.blue};
  color: white;
  font-weight: 700;
  z-index: 11;
  text-align: center;
`;

export default (props) => {
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setShowMessage(true);
    setMessage(props.message);

    let timeout;

    if (!props.stay) {
      timeout = window.setTimeout(() => setShowMessage(false), 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [props.listen, props.stay]);

  return <Message show={showMessage && props.message}>{props.message}</Message>;
};
