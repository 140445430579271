export default {
  generateDepartmentsList: (departments) => {
    let array = [];
    for (var i in departments) {
      const obj = {
        label: departments[i].name,
        value: departments[i].name,
      };
      array.push(obj);
    }
    return array;
  },
};
