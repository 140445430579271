import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import esign from "../util/esign";
import AutosizeInput from "react-input-autosize";
import { Required, Modal, Row, Button, Text, Checkbox } from "./index";
import InputMask from "react-input-mask";
import moment from "moment";

function isNotGeneratedEmail(email) {
  if (!email) return false;
  let isNot = true;
  if (email.indexOf("resident+") !== -1) isNot = false;
  return isNot;
}

const TextWrap = styled.div`
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
`;

const Textfield = styled(InputMask)`
  border: ${({ unassigned }) =>
    unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 11px;
  color: #4d5864;
  background: ${({ unassigned }) => (unassigned ? "#FFF4EB" : "#FFF4EB")};
  cursor: ${({ unassigned }) => (unassigned ? "not-allowed" : "pointer")};
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
  &::-webkit-input-placeholder {
    color: #ccc;
  }
`;

const TextArea = styled.textarea`
  border: ${({ unassigned }) =>
    unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 11px;
  color: #4d5864;
  background: ${({ unassigned }) => (unassigned ? "#FFF4EB" : "#FFF4EB")};
  cursor: ${({ unassigned }) => (unassigned ? "not-allowed" : "pointer")};
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
  &::-webkit-input-placeholder {
    color: #ccc;
  }
`;

const Signature = styled.div`
  border: ${({ unassigned }) =>
    unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  color: #4d5864;
  font-size: 27px;
  background: ${({ unassigned }) => (unassigned ? "#FFF4EB" : "#FFF4EB")};
  cursor: ${({ unassigned }) => (unassigned ? "not-allowed" : "pointer")};
  font-family: "La Belle Aurore", cursive;
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
`;

const InsertSignature = styled.input`
  padding: 16px 32px 4px;
  width: 100%;
  font-size: 45px;
  line-height: 1em;
  border: ${({ unassigned }) =>
    unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  color: #4d5864;
  background: ${({ unassigned }) => (unassigned ? "#FFF4EB" : "#FFF4EB")};
  cursor: ${({ unassigned }) => (unassigned ? "not-allowed" : "pointer")};
  font-family: "La Belle Aurore", cursive;
  text-align: center;
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
`;

const Initials = styled.div`
  border: ${({ unassigned }) =>
    unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  color: #4d5864;
  font-size: 22px;
  background: ${({ unassigned }) => (unassigned ? "#FFF4EB" : "#FFF4EB")};
  cursor: ${({ unassigned }) => (unassigned ? "not-allowed" : "pointer")};
  font-family: "La Belle Aurore", cursive;
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
`;

const CheckboxSign = styled.div`
  background-color: ${({ unassigned }) => (unassigned ? "#E9F3EA" : "#FFF4EB")};
  border: ${({ unassigned }) =>
    unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  cursor: ${({ unassigned }) => (unassigned ? "not-allowed" : "pointer")};
  background-image: ${({ checked }) =>
    checked ? "url('/icon-checkmark-blue.svg')" : "none"};
  background-size: 76% auto !important;
  background-repeat: no-repeat;
  background-position: center center;
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
`;

const Actions = styled(Row)`
  button {
    margin: 0 8px;
  }
`;

const PowerOfAttorney = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  font-weight: 700;
`;

const Dated = styled.div`
  border: ${({ unassigned }) =>
    unassigned ? "1px solid #ddd" : "1px solid #F7912F"};
  border-radius: 6px;
  color: #4d5864;
  font-size: 11px;
  padding: 0 4px;
  background: ${({ unassigned }) => (unassigned ? "#E9F3EA" : "#FFF4EB")};
  cursor: ${({ unassigned }) => (unassigned ? "not-allowed" : "pointer")};
  &.required {
    &:after {
      content: "";
      width: 8px;
      height: 8px;
      background: url("/icon-required.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -10px;
    }
  }
  &:focus {
    box-shadow: 0px 0px 8px rgba(247, 145, 47, 0.5);
  }
`;

const Field = ({
  field,
  updatePage,
  prefills,
  updateAuditTrail,
  ipaddress,
  match,
  fillPrefills,
  validateCheckboxs,
  communitySigner,
  listen,
  localInitials,
  setLocalInitials,
  save,
}) => {
  const [value, setValue] = useState(field.value);
  const [signature, setSignature] = useState(field.value);
  const [initials, setInitials] = useState(field.value);
  const [checked, setChecked] = useState(field.value);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [showInitialsModal, setShowInitialsModal] = useState(false);

  const node = useRef();
  const initialsNode = useRef();

  const filler = match.params.filler;

  function updateField(e) {
    setValue(e.target.value);
    updatePage(e.target.value, field.fieldid);
    if (
      field.prefill &&
      field.prefill !== "null" &&
      field.prefill !== "undefined"
    ) {
      fillPrefills(field.prefill, e.target.value);
    }
    save();
  }

  function insertSignature() {
    setValue(signature);
    setShowSignatureModal(false);

    updateAuditTrail({
      action: "Digitially Signed Signature",
      timestamp: new Date(),
      value: signature,
      filler: field.filler,
      fieldId: field.fieldid,
      poa: field.power_of_attorney,
      ipAddress: ipaddress,
    });
    // updatePage(signature, field.fieldid); //THIS REMOVES THE POA FROM POA SIGNATURES
    updatePage(
      field.power_of_attorney && signature.indexOf("POA") === -1
        ? signature + " POA"
        : signature,
      field.fieldid
    );

    save();
  }

  function insertDate() {
    setValue(moment().format("MM/DD/YYYY"));
    updateAuditTrail({
      action: "Digitially Inserted Date",
      timestamp: new Date(),
      value: moment().format("MM/DD/YYYY"),
      filler: field.filler,
      fieldId: field.fieldid,
      ipAddress: ipaddress,
    });
    updatePage(moment().format("MM/DD/YYYY"), field.fieldid);
    save();
  }

  function checkInitials() {
    if (value || !initials) {
      setShowInitialsModal(true);

      setTimeout(() => {
        if (initialsNode.current) {
          initialsNode.current.focus();
        }
      }, 500);
    } else {
      if (field.prefill === "rp_initials") {
        setValue(initials);
        updatePage(initials, field.fieldid);
        save();
      } else {
        setValue(
          field.type === "initials" && localInitials ? localInitials : initials
        );
        updatePage(
          field.type === "initials" && localInitials ? localInitials : initials,
          field.fieldid
        );
        save();
      }
    }
  }

  function insertInitials() {
    setValue(initials);
    setInitials(initials);
    updateAuditTrail({
      action: "Digitially Signed Initials",
      timestamp: new Date(),
      value: initials,
      filler: field.filler,
      fieldId: field.fieldid,
      ipAddress: ipaddress,
    });
    setShowInitialsModal(false);
    updatePage(initials, field.fieldid);
    save();
  }

  function clear() {
    setValue(null);
    setShowSignatureModal(false);
    setShowInitialsModal(false);
    updatePage(null, field.fieldid);
    save();
  }

  function check() {
    setChecked(!checked);

    if (field.grouprequirements === "Only_One") {
      validateCheckboxs(field.fieldid, field.groupname);
    } else {
      updatePage(!checked, field.fieldid);
      save();
    }
  }

  function keypressHandler(e) {
    if (e.key === "Enter") {
      document.getElementById(field.fieldid).blur();
    }
  }

  function setPowerOfAttorney() {
    field.power_of_attorney = !field.power_of_attorney;
    updatePage(field.power_of_attorney, field.fieldid, "power_of_attorney");
    save();
  }

  function focusSignatureModal() {
    setShowSignatureModal(true);

    setTimeout(() => {
      if (node.current) {
        node.current.focus();
      }
    }, 500);
  }

  function exists(f) {
    return f && f !== "undefined" && f !== "null" && f !== "false";
  }

  const styles = {
    position: "absolute",
    width: parseInt(field.position.w) + "px",
    height: parseInt(field.position.h) + "px",
    left: parseInt(field.position.x) + "px",
    top: parseInt(field.position.y) + "px",
  };

  const size = {
    width: parseInt(field.position.w) + "px",
    height: parseInt(field.position.h) + "px",
  };

  useEffect(() => {
    const prefill = prefills[field.prefill];
    prefill &&
      field.type !== "signature" &&
      field.type !== "initials" &&
      setValue(prefill);
    field.value && field.value !== "undefined" && setValue(field.value);
    setChecked(field.value);

    if (
      field.prefill === "email_address" &&
      !isNotGeneratedEmail(field.value)
    ) {
      setValue("");
    }
    if (field.filler === "Resident" && field.prefill !== "rp_initials")
      setInitials(
        field.value ||
          prefills.initials ||
          prefills.name_full.match(/\b(\w)/g).join("")
      );

    if (field.filler === "Community")
      setInitials(
        field.value ||
          (communitySigner.details && communitySigner.details.initials) ||
          communitySigner.name.match(/\b(\w)/g).join("")
      );

    if (field.filler === "Manager")
      setInitials(
        field.value ||
          (communitySigner.details && communitySigner.details.initials) ||
          communitySigner.name.match(/\b(\w)/g).join("")
      );

    if (field.filler === "Resident" && field.prefill === "rp_initials")
      setInitials(field.value || prefills.rp_initials);

    if (field.filler === "Resident" && field.type === "signature") {
      setSignature(prefills.name_full);
    }

    if (
      field.filler === "Resident" &&
      (field.prefill === "name_full" ||
        field.prefill === "signature_resident_or_rp" ||
        (field.type === "signature" && field.prefill === "null"))
    ) {
      setSignature(field.value ? field.value : prefills.name_full);
    }

    if (field.filler === "Resident" && field.prefill === "signature_rp")
      setSignature(field.value || prefills.signature_rp);

    if (field.filler === "Resident" && field.prefill === "signature_user_1")
      setSignature(field.value || prefills.signature_user_1);

    if (field.filler === "Community")
      setSignature(
        field.value ||
          (communitySigner.details && communitySigner.details.signature) ||
          communitySigner.name
      );

    if (field.filler === "Manager")
      setSignature(
        field.value ||
          (communitySigner.details && communitySigner.details.signature) ||
          communitySigner.name
      );
  }, [listen, prefills]);

  const fieldRequiredClass =
    field.isrequired &&
    field.isrequired !== "null" &&
    field.isrequired !== "false" &&
    field.isrequired !== "undefined"
      ? "required"
      : null;

  if (filler === field.filler || filler === "All") {
    if (field.type === "textbox" && !exists(field.multiline)) {
      return (
        <TextWrap style={styles} className={fieldRequiredClass}>
          <Textfield
            tabIndex={field.tabindex}
            onKeyPress={(e) => keypressHandler(e)}
            mask={
              field.format &&
              field.format !== "undefined" &&
              field.format !== "null"
                ? field.format
                : null
            }
            formatChars={
              field.format &&
              field.format !== "undefined" &&
              field.format !== "null"
                ? { "0": "[0-9]", S: "[A-Za-z]" }
                : null
            }
            placeholder={
              field.placeholder &&
              field.placeholder !== "undefined" &&
              field.placeholder !== "null"
                ? field.placeholder
                : null
            }
            id={field.fieldid}
            type="text"
            onBlur={updateField}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            style={size}
          />
        </TextWrap>
      );
    }

    if (field.type === "textbox" && exists(field.multiline)) {
      return (
        <TextWrap style={styles} className={fieldRequiredClass}>
          <TextArea
            tabIndex={field.tabindex}
            placeholder={
              field.placeholder &&
              field.placeholder !== "undefined" &&
              field.placeholder !== "null"
                ? field.placeholder
                : null
            }
            id={field.fieldid}
            onBlur={updateField}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            style={size}
          />
        </TextWrap>
      );
    }

    if (
      field.prefill === "signature_rp" ||
      field.prefill === "signature_user_1"
    ) {
      return (
        <div>
          <Signature
            tabIndex={field.tabindex}
            id={field.fieldid}
            onClick={focusSignatureModal}
            style={styles}
            className={fieldRequiredClass}
          >
            {value}{" "}
            {field.power_of_attorney && <PowerOfAttorney>POA</PowerOfAttorney>}
          </Signature>

          <Modal
            show={showSignatureModal}
            close={() => setShowSignatureModal(false)}
            title="Insert Signature"
          >
            <Row padding="24px 24px 0" direction="column">
              <InsertSignature
                ref={node}
                type="text"
                value={signature}
                onChange={(e) => setSignature(e.target.value)}
              />
            </Row>
            <Row
              padding="0 24px 24px"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Row padding="0 0 32px" justify="center" alignItems="center">
                {" "}
                <Text kind="p" size="12px">
                  I understand this is a legal representation of my signature
                </Text>
              </Row>
              {field.filler === "Resident" && (
                <Row padding="0 0 32px" justify="center" alignItems="center">
                  <Checkbox
                    selected={field.power_of_attorney}
                    onClick={() => setPowerOfAttorney()}
                  />{" "}
                  <Text kind="p" size="14px">
                    Signing through Power of Attorney
                  </Text>
                </Row>
              )}
              <Actions justify="center">
                <Button onClick={insertSignature}>Sign</Button>
                {value ? (
                  <Button secondary onClick={clear}>
                    Clear
                  </Button>
                ) : null}
              </Actions>
            </Row>
          </Modal>
        </div>
      );
    }

    if (field.type === "signature") {
      return (
        <div>
          <Signature
            tabIndex={field.tabindex}
            id={field.fieldid}
            onClick={focusSignatureModal}
            style={styles}
            className={fieldRequiredClass}
          >
            {value}{" "}
            {field.power_of_attorney && <PowerOfAttorney>POA</PowerOfAttorney>}
          </Signature>

          <Modal
            show={showSignatureModal}
            close={() => setShowSignatureModal(false)}
            title="Insert Signature"
          >
            <Row padding="24px 24px 0" direction="column">
              <InsertSignature
                ref={node}
                type="text"
                value={signature}
                onChange={(e) => setSignature(e.target.value)}
              />
            </Row>
            <Row
              padding="0 24px 24px"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Row padding="0 0 32px" justify="center" alignItems="center">
                {" "}
                <Text kind="p" size="12px">
                  I understand this is a legal representation of my signature
                </Text>
              </Row>
              {field.filler === "Resident" && (
                <Row padding="0 0 32px" justify="center" alignItems="center">
                  <Checkbox
                    selected={field.power_of_attorney}
                    onClick={() => setPowerOfAttorney()}
                  />{" "}
                  <Text kind="p" size="14px">
                    Signing through Power of Attorney
                  </Text>
                </Row>
              )}
              <Actions justify="center">
                <Button onClick={insertSignature}>Sign</Button>
                {value ? (
                  <Button secondary onClick={clear}>
                    Clear
                  </Button>
                ) : null}
              </Actions>
            </Row>
          </Modal>
        </div>
      );
    }

    if (field.prefill === "rp_initials") {
      return (
        <div>
          <Initials
            tabIndex={field.tabindex}
            id={field.fieldid}
            onClick={checkInitials}
            style={styles}
            className={fieldRequiredClass}
          >
            {value}
          </Initials>

          <Modal
            show={showInitialsModal}
            close={() => setShowInitialsModal(false)}
            title="Insert Initials"
          >
            <Row padding="24px" direction="column">
              <InsertSignature
                ref={initialsNode}
                type="text"
                value={initials}
                onChange={(e) => setInitials(e.target.value)}
              />
            </Row>
            <Row
              padding="0 24px 24px"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Text kind="p" margin="0 0 16px" size="14px">
                I understand this is a legal representation of my initials
              </Text>
              <Button onClick={insertInitials}>Insert Initials</Button>
            </Row>
          </Modal>
        </div>
      );
    }

    if (field.type === "initials") {
      return (
        <div>
          <Initials
            tabIndex={field.tabindex}
            id={field.fieldid}
            onClick={checkInitials}
            style={styles}
            className={fieldRequiredClass}
          >
            {value}
          </Initials>

          <Modal
            show={showInitialsModal}
            close={() => setShowInitialsModal(false)}
            title="Insert Initials"
          >
            <Row padding="24px" direction="column">
              <InsertSignature
                ref={initialsNode}
                type="text"
                value={initials}
                onChange={(e) => setInitials(e.target.value)}
              />
            </Row>
            <Row
              padding="0 24px 24px"
              alignItems="center"
              justify="center"
              direction="column"
            >
              <Text kind="p" margin="0 0 16px" size="14px">
                I understand this is a legal representation of my initials
              </Text>
              <Button onClick={insertInitials}>Insert Initials</Button>
            </Row>
          </Modal>
        </div>
      );
    }

    if (field.type === "checkbox") {
      return (
        <CheckboxSign
          tabIndex={field.tabindex}
          id={field.fieldid}
          className={fieldRequiredClass}
          checked={checked}
          onClick={check}
          style={styles}
        />
      );
    }

    if (field.type === "date") {
      return (
        <Dated
          tabIndex={field.tabindex}
          id={field.fieldid}
          className={fieldRequiredClass}
          onClick={insertDate}
          style={styles}
        >
          {value}
        </Dated>
      );
    }
  }
  //Unassigned fields
  else {
    if (field.type === "textbox") {
      return (
        <Textfield
          tabIndex={field.tabindex}
          unassigned
          id={field.fieldid}
          type="text"
          value={value}
          style={styles}
          disabled
        />
      );
    }

    if (field.type === "signature") {
      return (
        <Signature
          tabIndex={field.tabindex}
          unassigned
          id={field.fieldid}
          style={styles}
        >
          {" "}
          {value}{" "}
        </Signature>
      );
    }

    if (field.type === "initials") {
      return (
        <Initials
          tabIndex={field.tabindex}
          unassigned
          id={field.fieldid}
          style={styles}
        >
          {" "}
          {value}{" "}
        </Initials>
      );
    }

    if (field.type === "checkbox") {
      return (
        <CheckboxSign
          tabIndex={field.tabindex}
          unassigned
          id={field.fieldid}
          checked={checked}
          style={styles}
        />
      );
    }

    if (field.type === "date") {
      return (
        <Dated
          tabIndex={field.tabindex}
          unassigned
          id={field.fieldid}
          style={styles}
        >
          {value}
        </Dated>
      );
    }
  }
  return null;
};

export default Field;
