import React from "react";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : "0")};
  height: ${(props) => (props.height ? props.height : "auto")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : "flex-start"};
  width: 100%;
  min-width: ${(props) => (props.minwidth ? props.minwidth : "auto")};
  max-width: ${(props) => (props.width ? props.width : "auto")};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  cursor: ${({ pointer }) => (pointer ? "pointer" : null)};
`;

export default (props) => <Row {...props} />;
