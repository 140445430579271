import React from "react";
import styled from "styled-components";

const Text = styled.div`
  font-size: ${(props) => (props.size ? props.size : "16px")};
  display: block;
  color: ${(props) => (props.color ? props.color + " !important" : "#4D5864")};
  text-align: ${(props) => (props.center ? "center" : "left")};
  font-weight: ${(props) => (props.weight ? props.weight : 500)};
  padding: ${(props) => (props.padding ? props.padding : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
  font-family: "Open Sans", sans-serif;
  text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  line-height: ${(props) => (props.lineheight ? props.lineheight : "inherit")};
  min-width: ${(props) => (props.width ? props.width : "auto")};
  white-space: ${(props) => (props.nowrap ? "nowrap" : "inherit")};
  cursor: ${(props) => (props.pointer ? "pointer" : "inherit")};
`;

export default (props) => <Text {...props} as={props.kind} />;
