import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Row, Text, Select } from "./index";
import moment from "moment";
import applications from "../util/application";
import queryString from "query-string";

export default (props) => {
  const [packets, setPackets] = useState([]);
  const [application, setApplication] = useState();
  const [message, setMessage] = useState();

  function selectPacket(id) {
    if (id === "Add Packet") {
      const check = window.confirm(
        `This will add another packet for ${
          props.application.myInfo && props.application.myInfo.contact_info.name
        }. Do you want to add another packet for (him/her)?`
      );
      if (check) {
        props.history.push(
          `/add-resident?${queryString.stringify({
            email: application.myInfo.contact_info.email,
            first_name: application.myInfo.contact_info.first_name,
            last_name: application.myInfo.contact_info.last_name,
          })}`
        );
      }
    } else if (id == "Add Spouse") {
      props.history.push(
        `/add-resident?${queryString.stringify({
          email: application.myInfo.contact_info.email,
        })}`
      );
    } else {
      props.history.push("/application/" + id);
      props.refresh();
    }
  }

  function generatePacketSelectArray(packetsList) {
    // console.log("packets list", packetsList);
    let array = [];
    for (var i in packetsList) {
      let obj = {
        label: packetsList[i].myInfo.application_info.packet_name
          ? packetsList[i].myInfo.contact_info.name +
            ": " +
            packetsList[i].myInfo.application_info.packet_name
          : packetsList[i].myInfo.contact_info.name +
            ": " +
            moment(packetsList[i].createdAt).format("MMM DD, YYYY"),
        value: packetsList[i].id,
      };
      array.push(obj);
    }
    array.push({
      label: "Add Packet +",
      value: "Add Packet",
    });
    array.push({
      label: "Add Spouse +",
      value: "Add Spouse",
    });
    return array;
  }

  useEffect(() => {
    async function run() {
      // console.log("application", props.application);
      const applicationsData = await applications.listResidentApplications(
        props.application.userId,
        0
      );
      setApplication(props.application);
      // console.log(applicationsData);
      setPackets(generatePacketSelectArray(applicationsData.data));
    }

    if (props.application) run();
  }, [props.application]);

  if (!application) return null;
  return (
    <Row alignItems="center" width={"450px"}>
      {packets.length > 0 && (
        <Select
          initialvalue={{
            label: application.myInfo.application_info.packet_name
              ? application.myInfo.contact_info.name +
                ": " +
                application.myInfo.application_info.packet_name
              : application.myInfo.contact_info.name +
                ": " +
                moment(application.createdAt).format("MMM DD, YYYY"),
          }}
          textonly
          update={selectPacket}
          options={packets}
        />
      )}
    </Row>
  );
};
