import React, { useEffect, useState } from "react";
import styled from "styled-components";
import users from "../util/user";
import emails from "../util/emails";
import communities from "../util/community";
import {
  MessageBar,
  Select,
  Modal,
  Text,
  Row,
  Button,
  TextArea,
  Label,
} from "../components";

export default (props) => {
  const [show, setShow] = useState();
  const [agreement, setAgreement] = useState({});
  const [user, setUser] = useState({});
  const [communityAdminSelectList, setCommunityAdminSelectList] = useState();
  const [contact, setContact] = useState();
  const [message, setMessage] = useState("");
  const [sender, setSender] = useState();
  const [community, setCommunity] = useState();
  const [alert, setAlert] = useState();

  async function send() {
    const html = emails.generateForwardAgreementEmail(
      "Forwarded Document",
      contact,
      message,
      user,
      sender,
      agreement,
      community
    );
    const obj = {
      from: "hello@seniorsign.io",
      to: contact.email,
      subject: `Forwarded Document`,
      message: html,
    };
    const sent = await emails.sendNew(obj);
    setAlert("Email Sent Successfully");
    props.close();
  }

  function getAdmins(users) {
    const array = [];
    for (var i in users) {
      const obj = {
        label: `${users[i].name} (${users[i].email})`,
        value: users[i],
        details: users[i].details,
      };
      if (users[i].email.indexOf("@seniorsign.io") == -1) array.push(obj);
    }
    if (array.length > 0) setContact(array[0].value);
    return array;
  }

  useEffect(() => {
    setMessage(undefined);
    if (props.agreement) {
      setShow(true);
      setAgreement(props.agreement);
      setUser(props.user);
    } else {
      setShow(false);
      setAgreement({});
      setUser({});
    }
  }, [props.agreement]);

  useEffect(() => {
    async function getAdminList() {
      const me = await users.get();
      const communityAdmins = await users.listCommunityAdmins(
        me.data.facilityId,
        me.data.companyId,
        0
      );
      const _community = await communities.get(me.data.facilityId);
      setSender(me.data);
      setCommunity(_community.data.details.name);
      setCommunityAdminSelectList(getAdmins(communityAdmins.data));
    }

    getAdminList();
  }, []);

  return (
    <div>
      <Modal
        width={600}
        title={"Forward Agreement"}
        show={show}
        close={() => props.close()}
      >
        <Row padding="24px" direction="column">
          <Text weight={600}>
            {user.myInfo && user.myInfo.contact_info.name} - {agreement.name}
          </Text>
          <Text>
            Please enter a recepient and what message you want to send
          </Text>

          {communityAdminSelectList && (
            <Row direction="column" margin="32px 0 8px">
              <Select
                options={communityAdminSelectList}
                change={(e) => setContact(e.value)}
              />
              <Label color="#4D5864">Recepient</Label>
            </Row>
          )}

          <Row direction="column">
            <TextArea change={(e) => setMessage(e.target.value)} rows="5" />
            <Label color="#4D5864">Message</Label>
          </Row>
        </Row>

        <Row justify="flex-end" padding="0 24px 24px">
          <Button onClick={send}>Send</Button>
        </Row>
      </Modal>

      <MessageBar message={alert} listen={alert} />
    </div>
  );
};
