import axios from "axios";
import urls from "./urls";

function sendFax(faxNumber, filename) {
  return axios.post(
    urls.api + "mFax/sendfax",
    { to: faxNumber, from: "+18776240392", filename: filename },
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

function fetchFaxes() {
  return axios.get(urls.api + "mFax/faxes/", {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
  });
}

function validatePhoneNumber(number) {
  return axios.post(
    urls.api + "mFax/validate/",
    { number: number },
    {
      headers: {
        "X-Auth-Token": localStorage.token,
      },
    }
  );
}

const functions = {
  sendFax,
  fetchFaxes,
  validatePhoneNumber,
};

export default functions;
