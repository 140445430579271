import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, DownloadAll } from "./components";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  Test,
  Reset,
  Forgot,
  Invite,
  Category,
  Article,
  Login,
  Dashboard,
  Application,
  AddResident,
  Settings,
  Information,
  Sign,
  Resident,
  ResidentList,
  HelpCenter,
  InPerson,
  TwoFactor,
} from "./pages";
import ScrollToTop from "./components/scroll-top";
import Helmet from "react-helmet";
import "array-flat-polyfill";

const Inline = styled.div`
  text-align: center;
`;

const Icon = styled.img`
  width: 200px;
  margin: 40px;
`;

const Wrap = styled.div`
  padding: 100px;
  @media (prefers-color-scheme: dark) {
    p,
    label {
      color: white;
    }
  }
`;

const App = (props) => {
  const [downloads, setDownloads] = useState();
  const [ie, setIe] = useState(false);

  useEffect(() => {
    if (window.MSCompatibleInfo != null) {
      //alert("IE is not allowed please use Chrome!")
      //throw new Error('IE is not allowed please use Chrome!');
      console.log("USING IE");
      setIe(true);
    }
  }, []);

  function downloadAll(e) {
    setDownloads(e);
  }

  if (ie) {
    return (
      <Wrap>
        <Text kind="p" center size="55px" uppercase weight="800">
          Internet Explorer not supported
        </Text>
        <Text kind="p" center size="16px" margin="0 0 32px">
          Please download/use one of the following approved browsers
        </Text>
        <Inline>
          <a href="https://www.google.com/chrome/">
            <Icon src="/icon-chrome.png" />
          </a>
          <a href="https://support.apple.com/downloads/safari">
            <Icon src="/icon-safari.png" />
          </a>
          <a href="https://www.mozilla.org/en-US/firefox/new/">
            <Icon src="/icon-firefox.png" />
          </a>
          <a href="https://www.microsoft.com/en-us/edge">
            <Icon src="/icon-edge.png" />
          </a>
        </Inline>
      </Wrap>
    );
  }
  return (
    <div>
      <Helmet title="Senior Sign">
        <script>
          {/* Delighted NPS Script */}
          {`!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var u=t.getElementsByTagName("script")[0];u.parentNode.insertBefore(o,u)}}(window,document,"bcbRKkfTVues10jA","delighted");`}
        </script>

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-93904408-2"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-93904408-2');`}
        </script>

        <link rel="shortcut icon" type="image/png" href="/favicon.png" />

        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=La+Belle+Aurore&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Router>
        <ScrollToTop />
        <Route exact path="/" component={Login} />
        <Route path="/invite" component={Invite} />
        <Route path="/login" component={Login} />
        <Route path="/reset-password" component={Reset} />
        <Route path="/forgot-password" component={Forgot} />
        <Route path="/two-factor" component={TwoFactor} />
        <Route path="/dashboard" component={Dashboard} />
        <Route
          path="/application/:id"
          render={(props) => (
            <Application {...props} downloadAll={(e) => downloadAll(e)} />
          )}
        />
        <Route path="/resident/:id" component={Resident} />
        <Route path="/list" component={ResidentList} />
        <Route path="/add-resident" component={AddResident} />
        <Route path="/settings" component={Settings} />
        <Route path="/information" component={Information} />
        <Route exact path="/contact-us" component={HelpCenter} />
        <Route
          path="/sign/:id/:application/:filler/:source"
          {...props}
          component={Sign}
        />
        <Route path="/in-person/:id" component={InPerson} />
        <Route path="/test" component={Test} />
      </Router>

      <DownloadAll
        downloads={downloads}
        complete={() => setDownloads(undefined)}
      />
    </div>
  );
};

export default App;
