import React, { useState, useEffect } from "react";
import { Row, Text, PccImport, SherpaImport } from ".";
import styled from "styled-components";

const Integration = styled.div`
  width: 150px;
  height: 150px;
  background: ${({ url }) => (url ? "url(" + url + ")" : "black")};
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  border: 15px solid white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  margin: 0 16px 16px;
  &:hover {
  }
`;

const Main = (props) => {
  const [integration, setIntegration] = useState();

  useEffect(() => {}, []);

  return (
    <Row direction="column" padding="20px 40px 40px">
      {integration ? (
        <>
          {integration === "pcc" && (
            <PccImport close={() => setIntegration(null)} {...props} />
          )}
          {integration === "sherpa" && (
            <SherpaImport close={() => setIntegration(null)} {...props} />
          )}
        </>
      ) : (
        <>
          <Text margin="0 0 24px">
            Which system would you like to import from?
          </Text>
          <Row margin="0 -16px">
            {props.integrations && props.integrations.pcc && (
              <Integration
                onClick={() => setIntegration("pcc")}
                selectResident={props.selectResident}
                close={props.close}
                url="/pcc-logo.jpg"
              />
            )}
            {props.integrations && props.integrations.pcc && (
              <Integration
                onClick={() => setIntegration("pcc")}
                selectResident={props.selectResident}
                close={props.close}
                url="/matrixcare-logo.png"
              />
            )}
            {props.integrations && props.integrations.pcc && (
              <Integration
                onClick={() => setIntegration("sherpa")}
                selectResident={props.selectResident}
                close={props.close}
                url="/sherpa-logo.png"
              />
            )}
          </Row>
        </>
      )}
    </Row>
  );
};

export default Main;
