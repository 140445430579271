import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { DotsAnimated } from "./index";

const Wrapper = styled.div`
  position: relative;
  z-index: ${(props) => (props.show ? 9 : 8)};
`;

const Click = styled.div`
  padding: 0 16px;
  cursor: pointer;
`;

const List = styled.div`
  background: white;
  position: absolute;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  width: ${({ show }) => (show ? "190px" : "0px")};
  padding-right: 26px;
  top: -10px;
  right: 6px;
  z-index: 5;
  overflow: hidden;
  transition-duration: height 0.3s;
  height: ${(props) => (props.show ? "auto" : "0px")};
`;

const Item = styled.div`
  padding: 12px 16px;
  font-size: 13px;
  background: white;
  transition-duration: 0.3s;
  font-weight: 700;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    background: #eee;
  }
`;

export default (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const node = useRef();

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setShowMenu(false);
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [props.downloading]);

  return (
    <Wrapper ref={node} show={showMenu}>
      <Click onClick={() => setShowMenu(!showMenu)}>
        <DotsAnimated downloading={props.downloading} />
      </Click>
      {props.options && (
        <List show={showMenu}>
          {props.options.map((item, index) => {
            if (item.disabled)
              return (
                <Item key={index} disabled>
                  {item.label}
                </Item>
              );
            if (!item.disabled)
              return (
                <Item
                  key={index}
                  onClick={() => {
                    item.action();
                    setShowMenu(false);
                  }}
                >
                  {item.label}
                </Item>
              );
          })}
        </List>
      )}
    </Wrapper>
  );
};
