import React, { useState, useEffect } from "react";
import { Button } from "../components";
import styled from "styled-components";
import PCC from "../util/pcc";
import queryString from "query-string";

const Test = (props) => {
  const [code, setCode] = useState();
  const [facilities, setFacilities] = useState();

  useEffect(() => {
    const _parsed = queryString.parse(props.location.search);
    console.log(_parsed);
    if (_parsed.code) setCode(_parsed.code);
  }, []);

  async function authenticate() {
    try {
      const _auth = await PCC.authenticate(code);
      window.localStorage.setItem("pcc", JSON.stringify(_auth.data));
      console.log(_auth);
    } catch (err) {
      console.log("error", err);
    }
  }

  async function getFacilities() {
    try {
      const _auth = JSON.parse(window.localStorage.pcc);
      const _facilities = await PCC.getFacilities(
        _auth.access_token,
        _auth.metadata.orgUuid
      );
      setFacilities(_facilities.data.data);
      console.log(_facilities);
    } catch (err) {
      console.log("error", err);
    }
  }

  async function getResidents() {
    try {
      const _auth = JSON.parse(window.localStorage.pcc);
      const _patients = await PCC.getPatients(
        _auth.access_token,
        _auth.metadata.orgUuid,
        facilities
      );
      console.log("final", _patients);
    } catch (err) {
      console.log("error", err);
    }
  }

  async function refreshToken() {
    try {
      const _auth = JSON.parse(window.localStorage.pcc);
      const _refresh = await PCC.refreshToken(_auth.refresh_token);
    } catch (err) {
      console.log("error", err);
    }
  }

  function login() {
    window.location.href =
      "https://login.pointclickcare.com/auth/login.xhtml?client_id=dZXVQAUcpCzRFycUKA3h0xxWVImpGsX9&response_type=code&redirect_uri=https://comm.seniorsign.io/test&appName=ss-community-portal&state=9j76_b63";
  }

  return (
    <>
      <Button onClick={login}>Login</Button>
      <Button onClick={authenticate}>Get Token</Button>
      <Button onClick={refreshToken}>Refresh Token</Button>
      <Button onClick={getFacilities}>Get Facilities</Button>
      <Button onClick={getResidents}>Get Patients</Button>
    </>
  );
};

export default Test;
