import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import applications from "../util/application";
import {
  MessageBar,
  Modal,
  Row,
  Text,
  Required,
  Add,
  DropZone,
  Input,
  Label,
  Button,
} from "./index";
import randomString from "randomstring";
import aws from "../util/aws";

const Remove = styled.img`
  width: 18px !important;
  cursor: pointer;
  margin: 0 8px 13px 0;
`;

export default (props) => {
  const [application, setApplication] = useState(props.application);
  const [name, setName] = useState();
  const [files, setFiles] = useState([]);
  const [show, setShowModal] = useState(false);
  const [changed, change] = useState();
  const [message, setMessage] = useState();
  const [uploading, setUploading] = useState();

  function addFiles(_files) {
    const array = [...files];
    for (var i in _files) array.push(_files[i]);
    setFiles(array);
  }

  function removeAttachment(index) {
    const array = [...files];
    const filtered = array.filter((item, i) => i !== index);
    setFiles(filtered);
  }

  async function addAttachments() {
    if (!uploading) {
      if (!name) {
        setMessage("Enter a document name before adding the files");
        change(!changed);
      } else {
        setUploading(true);
        setMessage("Uploading Files...");
        change(!changed);
        const _application = await applications.get(application.id);

        const array = [];
        for (var i in files) {
          if (
            files[i].name &&
            files[i].name !== undefined &&
            files[i].name !== "item"
          ) {
            const filename =
              name.replace(/ /g, "_") +
              "_" +
              application.myInfo.contact_info.name.replace(/ /g, "_") +
              "_" +
              randomString.generate(15) +
              "." +
              files[i].name.split(".").pop();
            const put = await aws.putObject(files[i], filename);

            array.push(put.data.Key);
          }
        }

        const obj = {
          title: name,
          selected: true,
          files: array,
        };
        _application.data.myDocuments.required_documents.push(obj);
        await applications.update(_application.data);

        setMessage("Upload Successful");
        change(!changed);
        setShowModal(false);
        setUploading(false);
        props.refresh();
      }
    }
  }

  useEffect(() => {
    setApplication(props.application);
  }, [props.application]);

  return (
    <>
      <Add onClick={() => setShowModal(true)} />

      <Modal show={show} close={() => setShowModal(false)} title="Add Files">
        <Row padding="24px" direction="column">
          <Row direction="column" margin={"0 0 16px"}>
            <Input change={(e) => setName(e.target.value)} />
            <Label color="#333">
              <Required /> Document Name
            </Label>
          </Row>

          {files &&
            files.map((file, index) => (
              <Row alignItems="center">
                <Remove
                  src="/icon-close.svg"
                  onClick={() => removeAttachment(index)}
                />
                <Text margin="0 0 16px">{file.name}</Text>
              </Row>
            ))}

          <DropZone onDrop={(files) => addFiles(files)} />

          <Row margin={"16px 0 0"} justify="flex-end">
            {!uploading && <Button onClick={addAttachments}>Upload</Button>}
            {uploading && <Button disabled>Uploading...</Button>}
          </Row>
        </Row>
      </Modal>

      <MessageBar message={message} listen={changed} />
    </>
  );
};
