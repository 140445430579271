import React from "react";
import styled from "styled-components";

const Label = styled.label`
  font-size: 12px;
  display: block;
  color: ${(props) => (props.color ? props.color + " !important" : "#4D5864")};
  margin: 4px;
  opacity: 0.5;
  font-weight: ${(props) => (props.weight ? props.weight : "inherit")};
  transition-duration: 0.3s;
  :hover {
    opacity: 1;
  }
`;

export default (props) => <Label {...props} />;
