import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Drift from "react-driftjs";
import {
  Close,
  MessageBar,
  DropZone,
  TextArea,
  Label,
  Required,
  Tabs,
  Tab,
  Header,
  Sidebar,
  Row,
  Text,
  Box,
  Link,
  Button,
  Input,
  BoxRow,
  Cells,
  Cell,
  Dashboard,
  Loading,
} from "../components";
import Contentful from "../util/contentful";
import queryString from "query-string";
import ReactMarkdown from "react-markdown";
import colors from "../util/colors";
import emailer from "../util/emails";
import user from "../util/user";
import community from "../util/community";

const Form = styled.form`
  width: 100%;
`;

const Markdown = styled(ReactMarkdown)`
  p {
    margin-bottom: 0;
  }
`;

const Remove = styled.img`
  width: 18px !important;
  cursor: pointer;
  margin: 0 8px 13px 0;
`;

const Resident = (props) => {
  const [categories, setCategories] = useState();
  const [alerts, setAlerts] = useState();
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState();
  const [articles, setArticles] = useState();
  const [videos, setVideos] = useState();
  const [tab, setTab] = useState("contact");
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [messageBar, setMessageBar] = useState();
  const [changed, change] = useState(false);
  const [subject, setSubject] = useState();
  const [files, setFiles] = useState([]);
  const [me, setMe] = useState();
  const [location, setLocation] = useState();

  function updateSearch(e) {
    setSearch(e.target.value);
  }

  async function runSearch(e) {
    e.preventDefault();
    const articlesSearched = await Contentful.query({
      content_type: "helpArticle",
      query: search,
    });
  }

  async function sendMessage(e) {
    e.preventDefault();
    setError(undefined);

    if (!subject) {
      setError("Please enter a subject");
    } else if (!message) {
      setError("Please enter a message");
    } else {
      runSend();
    }

    async function runSend() {
      setMessageBar("Sending Email");
      change(!changed);

      const array = [];

      try {
        if (!files) send();
        for (var i in files) {
          function read(callback, iteration) {
            const reader = new FileReader();

            reader.onload = function (e) {
              callback(reader.result, iteration);
            };

            reader.readAsDataURL(files[i]);
          }

          read(complete, i);

          function complete(e, iteration) {
            array.push({ path: e });
            if (iteration == files.length - 1) {
              send();
            }
          }
        }

        async function send() {
          const obj = {
            from: "hello@seniorsign.io",
            to: "neil@seniorsign.io",
            subject: `${location.details.name} - ${subject}`,
            message: `${me.name}  (email:${me.email} department:${
              localStorage.selected_department
                ? localStorage.selected_department
                : me.details.departments
                ? me.details.departments[0]
                : "No Department Assigned"
            }) sent the following message: ${message}`,
            attachments: array,
          };

          const sent = await emailer.sendNew(obj);

          setSubject(undefined);
          setMessage(undefined);
          setFiles([]);

          setMessageBar("Email Sent Successfully!");
          change("Email");
        }
      } catch (err) {
        console.log(err);
        setMessageBar("Error Sending Email!");
        change("Error");
      }
    }
  }

  function addFiles(_files) {
    const array = [...files];
    for (var i in _files) array.push(_files[i]);
    setFiles(array);
  }

  function removeAttachment(index) {
    const array = [...files];
    const filtered = array.filter((item, i) => i !== index);
    setFiles(filtered);
  }

  function startChat() {
    window.drift.on("ready", function (api) {
      window.drift.api.startInteraction({ goToConversation: true });
    });
  }

  useEffect(() => {
    async function run() {
      try {
        const parsed = queryString.parse(props.location.search);
        if (parsed.tab) setTab(parsed.tab);

        const _me = await user.get();
        setMe(_me.data);

        const _location = await community.get(_me.data.facilityId);
        setLocation(_location.data);

        const _categories = await Contentful.query({
          content_type: "category",
        });
        setCategories(_categories.items);

        const _alerts = await Contentful.query({ content_type: "alerts" });
        setAlerts(_alerts.items);

        setLoaded(true);
      } catch (err) {
        console.log(err);
        props.history.push("/login?url=help-center");
      }
    }

    run();
  }, []);

  return (
    <main>
      <Header />
      <Drift appId="si55wsncxtax" />
      <Row>
        <Sidebar />

        {loaded ? (
          <Row direction="column">
            <Dashboard padding="0 0 82px">
              <Tabs>
                <Tab
                  active={tab == "contact"}
                  onClick={() => setTab("contact")}
                >
                  <Text kind="p" weight={700} size={"14px"} uppercase>
                    Contact Us
                  </Text>
                </Tab>
                <Tab
                  active={tab == "updates"}
                  onClick={() => setTab("updates")}
                >
                  <Text kind="p" weight={700} size={"14px"} uppercase>
                    New Updates
                  </Text>
                </Tab>
              </Tabs>

              {tab == "topics" && (
                <Row padding={"48px"} direction="column">
                  <Text
                    kind="p"
                    size={"16px"}
                    weight={800}
                    padding={"0 0 24px"}
                    uppercase
                  >
                    Help Center
                  </Text>

                  {/*<Row padding={"16px 0 24px"}>
                    <Form onSubmit={runSearch}>
                      <Input change={updateSearch} placeholder={"Search help articles"} searchicon/>
                    </Form>
                  </Row>*/}

                  <Box>
                    <BoxRow padding={"16px 24px"}>
                      <Text kind="p" weight={800} size={"14px"} uppercase>
                        Categories
                      </Text>
                    </BoxRow>

                    {categories &&
                      categories.map((category, index) => (
                        <BoxRow padding={"16px 24px"} key={index}>
                          <Link to={"/help-center" + category.fields.url}>
                            <Text kind="p" size={"14px"} underline>
                              {category.fields.name}
                            </Text>
                          </Link>
                        </BoxRow>
                      ))}
                  </Box>
                </Row>
              )}

              {tab == "updates" && (
                <Row padding={"48px"} direction="column">
                  <Text
                    kind="p"
                    size={"16px"}
                    weight={800}
                    padding={"0 0 24px"}
                    uppercase
                  >
                    New Updates
                  </Text>

                  {alerts &&
                    alerts.map((alert, index) => (
                      <Row padding="0 0 24px">
                        <Box>
                          <BoxRow padding={"16px 24px"} key={index}>
                            <Link to={alert.fields.url}>
                              <Text
                                kind="p"
                                size={"14px"}
                                weight={700}
                                padding="0 0 8px"
                              >
                                {alert.fields.blurb}
                              </Text>
                              <Text kind="p" size={"14px"}>
                                <Markdown
                                  source={alert.fields.longDescription}
                                />
                              </Text>
                            </Link>
                          </BoxRow>
                        </Box>
                      </Row>
                    ))}
                </Row>
              )}

              {tab == "contact" && (
                <Row padding={"48px"} direction="column">
                  <Text
                    kind="p"
                    size={"16px"}
                    weight={800}
                    padding={"0 0 24px"}
                    uppercase
                  >
                    Contact us
                  </Text>

                  <Row>
                    <Row direction="column">
                      <Row>
                        <Row margin={"0 32px 32px 0"}>
                          <Box>
                            <BoxRow padding="24px">
                              <Text
                                kind="p"
                                size={"14px"}
                                weight={800}
                                uppercase
                              >
                                Schedule a support call
                              </Text>
                            </BoxRow>
                            <Row
                              padding="32px"
                              alignItems="center"
                              justify="center"
                            >
                              <a
                                href="https://meetings.hubspot.com/angel-van-horn"
                                target="_blank"
                              >
                                <Button>Schedule</Button>
                              </a>
                            </Row>
                          </Box>
                        </Row>

                        <Box>
                          <BoxRow padding="24px">
                            <Text kind="p" size={"14px"} weight={800} uppercase>
                              Chat with an expert
                            </Text>
                          </BoxRow>
                          <Row
                            padding="32px"
                            alignItems="center"
                            justify="center"
                          >
                            <Button onClick={startChat}>Start Chat</Button>
                          </Row>
                        </Box>
                      </Row>
                    </Row>
                  </Row>

                  <Row margin={"0px 0 0"}>
                    <Box>
                      <BoxRow padding="24px">
                        <Text kind="p" size={"14px"} weight={800} uppercase>
                          Send us an email
                        </Text>
                      </BoxRow>

                      <form onSubmit={sendMessage}>
                        <BoxRow padding="24px" direction="column">
                          <Row direction="column">
                            <Input
                              initialvalue={subject}
                              change={(e) => setSubject(e.target.value)}
                            />
                            <Label>
                              <Required /> Subject
                            </Label>
                          </Row>

                          <Row margin={"16px 0 32px"} direction="column">
                            <TextArea
                              initialvalue={message}
                              change={(e) => setMessage(e.target.value)}
                              rows="8"
                            />
                            <Label>
                              <Required /> Message
                            </Label>
                          </Row>

                          {files &&
                            files.map((file, index) => (
                              <Row alignItems="center">
                                <Remove
                                  src="/icon-close.svg"
                                  onClick={() => removeAttachment(index)}
                                />
                                <Text kind="p" margin="0 0 16px">
                                  Attachment: {file.name}
                                </Text>
                              </Row>
                            ))}

                          <DropZone onDrop={(files) => addFiles(files)} />

                          {error && (
                            <Text
                              kind="p"
                              margin={"16px 0 0"}
                              color={colors.red}
                            >
                              {error}
                            </Text>
                          )}

                          <Row margin={"16px 0 0"} justify="flex-end">
                            <Button onClick={sendMessage}>SEND</Button>
                          </Row>
                        </BoxRow>
                      </form>
                    </Box>
                  </Row>
                </Row>
              )}

              <MessageBar message={messageBar} listen={changed} />
            </Dashboard>
          </Row>
        ) : (
          <Loading />
        )}
      </Row>
    </main>
  );
};

export default Resident;
