import React, { useState, useEffect } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";

const Textarea = styled.textarea`
  font-size: 13px;
  display: block;
  color: ${(props) => (props.color ? props.color : "#4D5864 !important")};
  font-family: "Open Sans", sans-serif;
  min-height: 40px;
  border: 1px solid #c4cad0;
  background: white;
  padding: 8px 12px;
  padding-right: ${(props) => (props.searchIcon ? "32px" : "auto")};
  border-radius: 6px;
  width: ${(props) => (props.width ? props.width : "100%")};
  transition-duration: 0.3s;
  box-shadow: none;

  &:focus {
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    outline: none;
  }
`;

export default (props) => {
  const [value, setValue] = useState(
    props.initialvalue ? props.initialvalue : undefined
  );

  function updateField(e) {
    if (props.change) props.change(e);
    setValue(e.target.value);
  }

  useEffect(() => {
    setValue(props.initialvalue ? props.initialvalue : "");
  }, [props.initialvalue]);

  return <Textarea {...props} onChange={updateField} value={value} />;
};
