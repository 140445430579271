import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../util/colors";
import applications from "../util/application";
import JSZip from "jszip";
import saveAs from "save-as";
import aws from "../util/aws";
import agreements from "../util/agreements";
import templates from "../util/templates";
import pdf from "../util/pdf";
import { Button } from "./";

const Message = styled.div`
  width: 100%;
  padding: 16px 32px;
  position: fixed;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  bottom: ${(props) => (props.show ? "0px" : "-100px")};
  left: 0;
  background: ${colors.blue};
  color: white;
  font-weight: 700;
  z-index: 11;
  text-align: center;
`;

const Cancel = styled(Button)`
  position: absolute;
  top: 8px;
  right: 16px;
`;

export default (props) => {
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [canceled, setCanceled] = useState(false);

  useEffect(() => {
    setCanceled(false);
    const application = props.downloads;

    async function downloadAll() {
      try {
        const zip = new JSZip();
        setShowMessage(true);

        // FILTER AGEREMENTS AND TEMPLATES THAT ARE COMPLETE OR READ ONLY
        const resAgreements = application.agreements.resident; //.filter(agreement => {
        //   return applications.prefillComplete(agreement.signatures) && !applications.residentTurn(agreement.signatures);
        // })

        for (var i in resAgreements) {
          setMessage(
            "Downloading Agreement " +
              (parseInt(i) + 1) +
              " of " +
              resAgreements.length
          );
          const agreement = resAgreements[i];

          if (!canceled) {
            //IF FILE EXISTS
            if (agreement.agreement_filename) {
              const buffer = await aws.getObject(agreement.agreement_filename);
              zip.file(agreement.name.replace(/ /g, "_") + ".pdf", buffer.data);
            }
            //ELSE GENERATE ADD TO ZIP AND UPDATE APPLICATION
            else {
              const response = agreement.agreement_id
                ? await agreements.get(agreement.agreement_id)
                : await templates.get(agreement.template_id);
              const html = await applications.generateHtml(response.data);
              const generated = await pdf.generate(html, null, "pdf");
              const file_name = generated.data.Key;

              agreement.agreement_filename = file_name;

              const latestApplication = await applications.get(application.id);
              for (var j in latestApplication.data.agreements.resident) {
                if (
                  latestApplication.data.agreements.resident[j].template_id ===
                  agreement.template_id
                ) {
                  latestApplication.data.agreements.resident[j] = agreement;
                }
              }
              const updatedApplication = await applications.update(
                latestApplication.data
              );
              const buffer = await aws.getObject(agreement.agreement_filename);
              zip.file(agreement.name.replace(/ /g, "_") + ".pdf", buffer.data);
            }
          }
        }

        if (!canceled) {
          const zipped = await zip.generateAsync({ type: "blob" });
          const name = application.myInfo.contact_info.name.replace(/ /g, "_");

          saveAs(zipped, name + ".zip");
          setMessage("Download Complete");

          setTimeout(() => setMessage(undefined), 3000);
        }
      } catch (error) {}
    }

    if (props.downloads) downloadAll();
  }, [props.downloads]);

  function cancelDownload() {
    setCanceled(true);
    setMessage("Download Canceled");

    setTimeout(() => setMessage(undefined), 3000);
  }

  return (
    <Message show={showMessage && message}>
      {message}
      {!canceled && (
        <Cancel outline onClick={cancelDownload}>
          Cancel
        </Cancel>
      )}
    </Message>
  );
};
