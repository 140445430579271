import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import applications from "../util/application";
import aws from "../util/aws";
import {
  AdditionalAttachments,
  MessageBar,
  Row,
  Text,
  BoxRow,
  Box,
  Cells,
  Cell,
  Check,
  Checkbox,
  Upload,
  Dots,
  Menu,
  Label,
  Button,
  AddDocuments,
  SelectPacket,
  Required,
} from "./index";
import $ from "jquery";
import randomString from "randomstring";
import JSZip from "jszip";
import saveAs from "save-as";

const Attached = styled(Label)`
  margin-right: 32px;
`;

const Mark = styled(Check)`
  cursor: pointer;
`;

export default (props) => {
  const [app, setApp] = useState(props.application);
  const [showAddDocuments, setShowAddDocuments] = useState(false);

  async function download(files, name) {
    const zip = new JSZip();

    for (var i in files) {
      const buffer = await aws.getObject(files[i]);
      zip.file(files[i], buffer.data);
    }

    const zipped = await zip.generateAsync({ type: "blob" });
    saveAs(zipped, name.replace(/ /g, "_") + "_attachments.zip");
  }

  async function downloadAll(documents) {
    const zip = new JSZip();

    for (var i in documents) {
      const files = documents[i].files;
      for (var j in files) {
        const buffer = await aws.getObject(files[j]);
        zip.file(files[j], buffer.data);
      }
    }

    const zipped = await zip.generateAsync({ type: "blob" });
    saveAs(
      zipped,
      props.application.myInfo.contact_info.name.replace(/ /g, "_") +
        "_attachments.zip"
    );
  }

  async function clearAttached(doc) {
    const check = window.confirm(
      "Are you sure you want to remove attached documents?"
    );
    if (check) {
      doc.files = undefined;
      const application = await applications.get(app.id);
      for (var i in application.data.myDocuments.required_documents) {
        if (
          application.data.myDocuments.required_documents[i].title == doc.title
        ) {
          application.data.myDocuments.required_documents[i] = doc;
        }
      }
      await applications.update(application.data);
      props.refresh();
    }
  }

  async function remove(doc) {
    const check = window.confirm(
      "Are you sure you want to remove required document from application?"
    );
    if (check) {
      const application = await applications.get(app.id);
      application.data.myDocuments.required_documents = application.data.myDocuments.required_documents.filter(
        (item) => item.title !== doc.title
      );
      await applications.update(application.data);
      props.refresh();
    }
  }

  async function checkReceived(doc) {
    const application = await applications.get(app.id);
    for (var i in application.data.myDocuments.required_documents) {
      if (
        application.data.myDocuments.required_documents[i].title == doc.title
      ) {
        if (doc.received) {
          application.data.myDocuments.required_documents[i].received = false;
        } else {
          application.data.myDocuments.required_documents[i].received = true;
        }
      }
    }
    await applications.update(application.data);
    props.refresh(randomString.generate());
  }

  useEffect(() => {
    setApp(props.application);
  }, [props.application]);

  return (
    <Row direction="column">
      <Box width={980}>
        <BoxRow
          padding={"16px 24px"}
          justify="space-between"
          alignItems="center"
        >
          <Row alignItems="center">
            <Text
              kind="p"
              weight={800}
              size={"14px"}
              margin="0 8px 0 0"
              uppercase
            >
              Documents
            </Text>
          </Row>

          <Cells>
            <Cell width={105}>
              <Text kind="p" weight={800} size={"14px"} uppercase>
                Received?
              </Text>
            </Cell>
            <Cell width={115}>
              <Row justify="flex-end">
                <AdditionalAttachments
                  refresh={props.refresh}
                  application={app}
                />
              </Row>
            </Cell>
          </Cells>
        </BoxRow>

        {app.myDocuments.required_documents &&
        app.myDocuments.required_documents.length > 0 ? (
          app.myDocuments.required_documents.map((doc, index) => (
            <BoxRow
              padding={"16px 24px"}
              justify="space-between"
              alignItems="center"
              key={index}
            >
              <Row alignItems="center">
                <Text kind="p" size={"15px"}>
                  {doc.title}
                </Text>
              </Row>

              <Cells>
                <Cell width={140}>
                  {doc.object_name && !doc.files ? (
                    <Attached>{doc.object_name}</Attached>
                  ) : null}
                  {doc.files && doc.files.length > 0 ? (
                    <Attached>{doc.files.length} File(s) Attached</Attached>
                  ) : null}
                  {doc.received && !doc.files && (
                    <Text kind="p" size="12px">
                      Received
                    </Text>
                  )}
                </Cell>

                <Cell width={105}>
                  <Checkbox
                    onClick={() => checkReceived(doc)}
                    selected={
                      (doc.files && doc.files.length > 0) ||
                      doc.object_name ||
                      doc.received
                    }
                  />
                </Cell>

                <Cell width={110}>
                  <Upload
                    application={app}
                    document={doc}
                    complete={(e) => props.refresh(e)}
                  >
                    {doc.object_name ? (
                      <Button
                        primary={!doc.object_name}
                        secondary={doc.object_name}
                      >
                        {doc.object_name ? "Change" : "Attach"}
                      </Button>
                    ) : (
                      <Button
                        primary={!(doc.files && doc.files.length)}
                        secondary={doc.files && doc.files.length}
                        disabled={doc.received}
                      >
                        {doc.files && doc.files.length > 0
                          ? "Change"
                          : "Attach"}
                      </Button>
                    )}
                  </Upload>
                </Cell>
              </Cells>
            </BoxRow>
          ))
        ) : (
          <Row padding={"16px 24px"}>
            <Text kind="p">No documents assigned</Text>
          </Row>
        )}
      </Box>

      <AddDocuments
        application={app}
        show={showAddDocuments}
        close={() => setShowAddDocuments(false)}
        complete={() => props.refresh()}
      />
    </Row>
  );
};
