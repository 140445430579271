import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PreviewTemplate, Row, Text, Checkbox, Required } from "./index";

const Item = styled(Row)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: white;
  width: 100%;
  height: 42px;
`;

const PreviewLink = styled(Text)`
  cursor: pointer;
`;

const AgreementItem = (props) => {
  const [agreement, setAgreement] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    setAgreement(props.item);
  }, [props.item]);

  if (!agreement) return null;
  if (props.readOnly && !agreement.details.read_only) return null;
  if (props.physicianDoc && !agreement.details.physician_doc) return null;
  if (
    props.itemsOnly &&
    (agreement.details.physician_doc || agreement.details.read_only)
  )
    return null;
  return (
    <>
      <Item alignItems="center" justify="space-between" margin="0 0 8px">
        <Row alignItems="center" padding="8px 16px 8px 8px">
          <Checkbox
            detect={agreement}
            selected={agreement.selected}
            onClick={() => props.toggle(agreement)}
          />
          <PreviewLink
            onClick={() => setPreview(agreement)}
            kind="p"
            color="#4D5864"
            size="12px"
            underline={!agreement.files}
          >
            {agreement.details ? agreement.details.name : agreement.title}{" "}
            {(agreement.required ||
              (agreement.details && agreement.details.required)) && (
              <Required />
            )}
          </PreviewLink>
        </Row>

        <PreviewTemplate preview={preview} />
      </Item>
    </>
  );
};

export default AgreementItem;
