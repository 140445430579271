import React, { useState, useEffect } from "react";
import styled from "styled-components";
import user from "../util/user";
import colors from "../util/colors";
import { Label, Input, Row, Text, Link, Button, Logo } from "../components";
import { Redirect, BrowserRouter as Router } from "react-router-dom";

const FormWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  @media (prefers-color-scheme: dark) {
    p,
    label,
    h1 {
      color: white;
    }
  }
`;

const Form = styled.form`
  max-width: 400px;
  width: 100%;
`;

const Name = (props) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [sent, setSent] = useState();

  async function reset(e) {
    e.preventDefault();
    if (email) {
      try {
        const _request = await user.requestReset(email);
        setSent(true);
      } catch (err) {
        alert("Error resetting password");
      }
    } else {
      alert("please enter an email address");
    }
  }

  useEffect(() => {}, []);

  return (
    <div>
      <a href="/login">
        <Logo margin={"24px"} absolute />
      </a>
      <FormWrapper>
        {sent ? (
          <div>
            <Text kind="h1" size={"36px"} weight={"900"} center>
              Email Sent
            </Text>
            <Text kind="p" size={"16px"} margin={"8px 0 48px"} center>
              Check your inbox on instructions to finish resetting your password
            </Text>
          </div>
        ) : (
          <Form onSubmit={reset}>
            <div>
              <Text kind="h1" size={"36px"} weight={"900"} center>
                Forgot Password
              </Text>
              <Text kind="p" size={"16px"} margin={"8px 0 48px"} center>
                Enter your email address to reset your password
              </Text>
            </div>

            <Row direction="column" padding={"0 0 24px"}>
              <Input
                name="email"
                type="email"
                change={(e) => setEmail(e.target.value)}
              />
              <Label>Email Address</Label>
            </Row>

            {error && <Label color={"red"}>{error}</Label>}

            <Button primary full onClick={reset}>
              Reset Password
            </Button>
          </Form>
        )}
      </FormWrapper>
    </div>
  );
};

export default Name;
