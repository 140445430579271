import axios from "axios";
import urls from "./urls";
import applications from "./application";
import pdf from "./pdf";

function getList(packets) {
  let array = [];
  for (var i in packets) {
    if (packets[i].name) {
      let obj = {
        label: packets[i].name,
        value: packets[i].name,
      };
      array.push(obj);
    }
  }
  return array;
}

async function sendPhysicianPacketViaFax(physicianForms) {
  const html = applications.generatePhsyicianFormsHtml(physicianForms);
  const upload = await pdf.generatePublic(html);
  const pdfUrl =
    "https://docuvault-uploads.s3-us-west-2.amazonaws.com/" + upload.data.Key;
}

function filterUploads(packetUploads, companyUploads) {
  let _array = [];

  if (companyUploads) {
    companyUploads.filter((_upload) => {
      if (packetUploads) {
        const _match = packetUploads.filter(
          (_packetUpload) => _packetUpload.title == _upload.title
        );
        if (_match.length > 0) {
          if (_upload.required) _match[0].required = true;
          _array.push(_match[0]);
        } else {
          if (_upload.title) {
            _array.push(_upload);
          } else {
            _array.push({
              title: _upload,
              files: [],
              object_name: null,
              required: false,
              department_selected: true,
              selected: false,
            });
          }
        }
      }
      return true;
    });
  }
  return _array;
}

function deselectUploads(uploads) {
  for (var i in uploads) uploads[i].selected = false;
  return uploads;
}

function orderAlphabetically(agreements) {
  const _ordered = [...agreements];

  _ordered.sort(function (a, b) {
    if (a.details.name < b.details.name) {
      return -1;
    }
    if (a.details.name > b.details.name) {
      return 1;
    }
    return 0;
  });

  return _ordered;
}

const functions = {
  getList,
  sendPhysicianPacketViaFax,
  filterUploads,
  deselectUploads,
  orderAlphabetically,
};

export default functions;
