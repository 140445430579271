import React, { useEffect, useState } from "react";
import styled from "styled-components";
import applications from "../util/application";
import agreements from "../util/agreements";
import communities from "../util/community";
import user from "../util/user";
import {
  Select,
  MessageBar,
  Row,
  Text,
  BoxRow,
  Box,
  Cells,
  Cell,
  Check,
  SelectPacket,
  Pill,
  Button,
  Input,
  Label,
} from "./index";
import moment from "moment";
import { Doughnut } from "react-chartjs-2";
import randomString from "randomstring";

const ProgressBox = styled.div`
  padding: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #e8eaec;
  &:first-child {
    border-right: 1px solid #e8eaec;
  }
`;

const Graph = styled.div`
  width: 180px;
  height: 180px;
  position: relative;
`;

const Percent = styled.div`
  position: absolute;
  width: 130px;
  height: 130px;
  background: white;
  border-radius: 80px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  top: 29px;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BarWrap = styled.div`
  position: relative;
  width: 100%;
  background: #f9f9fa;
  border-radius: 30px;
  height: 18px;
`;

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: -1px;
  background: #f9f9fa;
  border-radius: 30px;
  height: 18px;
  width: ${(props) => (props.width ? props.width + "%" : "0%")};
  background: ${(props) => props.background};
`;

const BarPercent = styled.div`
  position: absolute;
  top: -6px;
  left: ${(props) => "calc(" + props.percent + "% - 14px)"};
  border-radius: 30px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
`;

export default (props) => {
  const [app, setApplication] = useState();
  const [stats, setStats] = useState();
  const [message, setMessage] = useState();
  const [changed, change] = useState();
  const [departments, setDepartments] = useState();
  const [assignedManager, setAssignedManager] = useState();
  const [managers, setManagers] = useState();
  const [physicianFormsPrepared, setPhysicianFormsPrepared] = useState(false);

  function updateContactInfo(e) {
    if (e.target.name == "date_of_birth") {
      app.myInfo.personal_info[e.target.name] = e.target.value;
    } else if (
      e.target.name == "due_date" ||
      e.target.name == "manager_name" ||
      e.target.name == "unit_number"
    ) {
      app.myInfo.application_info[e.target.name] = e.target.value;
    } else {
      app.myInfo.contact_info[e.target.name] = e.target.value;
    }
    app.myInfo.contact_info.name = `${app.myInfo.contact_info.first_name}${
      app.myInfo.contact_info.middle_name
        ? " " + app.myInfo.contact_info.middle_name + " "
        : " "
    }${app.myInfo.contact_info.last_name}`;
  }

  async function updateApp() {
    const _application = await applications.get(app.id);
    const _resident = await user.getResident(_application.data.userId);

    if (app.myInfo.contact_info.email !== _resident.data.email) {
      try {
        _resident.data.email = app.myInfo.contact_info.email;
        const _updatedUser = await user.update(_resident.data);
        await updateApplication();
      } catch (error) {
        alert(
          "Email address is already taken, please enter a different email address"
        );
      }
    } else {
      await updateApplication();
    }

    async function updateApplication() {
      _application.data.myInfo = app.myInfo;
      _application.data.department = app.department;
      await applications.update(_application.data);
      setMessage("Resident Information updated successfully!");
      change(randomString.generate());
    }
  }

  function generateDepartmentsSelectList(list) {
    const array = [];
    for (var i in list) {
      const obj = {
        label: list[i].name,
        value: list[i].name,
      };
      array.push(obj);
    }
    return array;
  }

  function changeDepartment(e) {
    app.department = e.value;
  }

  function setAssigneeSelectList(assignees) {
    const array = [];
    for (var i in assignees) {
      const obj = {
        label: assignees[i].name,
        value: assignees[i].id,
      };
      if (assignees[i].email.indexOf("@seniorsign.io") == -1) array.push(obj);
    }
    return array;
  }

  async function updateProgress(status) {
    // if(status == 'complete'){
    //   let check = confirm('Are you sure you want to mark this complete? If there is an email assigned to this resident it will send them an email letting them know it has been approved.')
    //   if(check){
    //
    //   }
    // } else {
    //
    // }
    const _application = await applications.get(app.id);
    _application.data.progress.current = status;
    await applications.update(_application.data);
    setMessage("Status updated successfully!");
    change(randomString.generate());
    props.refresh();
  }

  useEffect(() => {
    async function getResident() {
      props.application.agreements.physician = await applications.getLatestPhysicianDocs(
        props.application.agreements.physician
      );

      const res = await user.getResident(props.application.userId);
      const community = await communities.get(res.data.facilityId);
      if (community.data.details.departments) {
        setDepartments(
          generateDepartmentsSelectList(community.data.details.departments)
        );
      }
      const me = await user.get();
      const communityAdmins = await user.listCommunityAdmins(
        me.data.facilityId,
        me.data.companyId,
        0
      );
      setManagers(setAssigneeSelectList(communityAdmins.data));

      if (props.application.myInfo.application_info.manager_name) {
        const _manager = await user.getResident(
          props.application.myInfo.application_info.manager_name
        );
        setAssignedManager({ label: _manager.data.name });
      }

      setApplication(props.application);
    }

    setApplication(props.application);
    setStats(applications.getProgress(props.application));
    applications.allAgreementsPrepared(props.application.agreements.resident);
    getResident();
  }, [props.application]);

  if (!app) return null;
  return (
    <>
      <Row direction="column" padding={"32px 40px"}>
        <Row
          height={"56px"}
          justify="space-between"
          alignItems="center"
          padding={"0 5px 16px 5px"}
        >
          <SelectPacket {...props} refresh={props.refresh} application={app} />

          {app.progress.current == "New!" && (
            <Pill primary>{app.progress.current}</Pill>
          )}
          {app.progress.current == "complete" && (
            <Pill green>{app.progress.current}</Pill>
          )}
          {app.progress.current !== "New!" &&
            app.progress.current !== "complete" && (
              <Pill gray>{app.progress.current}</Pill>
            )}
        </Row>

        <Row justify="space-between">
          <Row padding="0 32px 0 0">
            <Box>
              <Row>
                <ProgressBox>
                  <Text
                    kind="p"
                    margin="0 0 16px"
                    weight="700"
                    size="14px"
                    center
                    uppercase
                  >
                    Compliance
                  </Text>

                  <Graph>
                    <Percent>
                      <Text
                        kind="p"
                        size="42px"
                        color="#4A5967"
                        weight="800"
                        lineheight="1em"
                      >
                        {stats.compliance_percentage}
                      </Text>
                      <Text
                        kind="p"
                        size="15px"
                        color="#4A5967"
                        weight="800"
                        uppercase
                      >
                        Percent
                      </Text>
                    </Percent>
                    <Doughnut
                      options={{
                        tooltips: {
                          enabled: false,
                        },
                      }}
                      data={{
                        datasets: [
                          {
                            data: [
                              stats.agreements.compliance.agreements.complete,
                              stats.agreements.compliance.attachments.complete,
                              stats.agreements.compliance.physician
                                ? stats.agreements.compliance.physician.complete
                                : 0,
                              stats.total_compliance -
                                stats.total_compliance_complete,
                            ],
                            backgroundColor: [
                              "#42E8FF",
                              "#9F07D8",
                              "#FF008A",
                              "rgba(0,0,0,0)",
                            ],
                            borderWidth: 0,
                          },
                        ],
                      }}
                      width={1}
                      height={1}
                    />
                  </Graph>
                </ProgressBox>

                <ProgressBox>
                  <Text
                    kind="p"
                    margin="0 0 16px"
                    weight="700"
                    size="14px"
                    center
                    uppercase
                  >
                    Progress
                  </Text>

                  <Graph>
                    <Percent>
                      <Text
                        kind="p"
                        size="42px"
                        color="#4A5967"
                        weight="800"
                        lineheight="1em"
                      >
                        {stats.required_percentage}
                      </Text>
                      <Text
                        kind="p"
                        size="15px"
                        color="#4A5967"
                        weight="800"
                        uppercase
                      >
                        Percent
                      </Text>
                    </Percent>
                    <Doughnut
                      options={{
                        tooltips: {
                          enabled: false,
                        },
                      }}
                      data={{
                        datasets: [
                          {
                            data: [
                              stats.agreements.progress.agreements.complete,
                              stats.agreements.progress.attachments.complete,
                              stats.agreements.progress.physician
                                ? stats.agreements.progress.physician.complete
                                : 0,
                              stats.total_required - stats.total_complete,
                            ],
                            backgroundColor: [
                              "#3DC4DC",
                              "#75129F",
                              "#F40074",
                              "rgba(0,0,0,0)",
                            ],
                            borderWidth: 0,
                          },
                        ],
                      }}
                      width={1}
                      height={1}
                    />
                  </Graph>
                </ProgressBox>
              </Row>

              <Row padding={"32px"} direction="column">
                <Text uppercase weight="800" kind="p">
                  Agreements
                </Text>

                <Row padding="16px 0 8px 16px">
                  <Text weight="800" kind="p" size="14px" width="125px">
                    Compliance
                  </Text>
                  <BarWrap>
                    <Bar
                      width={stats.agreements.compliance.agreements.percent}
                      background={"#42E8FF"}
                    />
                    <BarPercent
                      percent={stats.agreements.compliance.agreements.percent}
                    >
                      <Text size="10px" weight="800" color={"#42E8FF"}>
                        {stats.agreements.compliance.agreements.percent + "%"}
                      </Text>
                    </BarPercent>
                  </BarWrap>
                </Row>
                <Row padding="16px 0 8px 16px">
                  <Text weight="800" kind="p" size="14px" width="125px">
                    Progress
                  </Text>
                  <BarWrap>
                    <Bar
                      width={stats.agreements.progress.agreements.percent}
                      background={"#3DC4DC"}
                    />
                    <BarPercent
                      percent={stats.agreements.progress.agreements.percent}
                    >
                      <Text size="10px" weight="800" color={"#3DC4DC"}>
                        {stats.agreements.progress.agreements.percent + "%"}
                      </Text>
                    </BarPercent>
                  </BarWrap>
                </Row>

                <Text uppercase weight="800" kind="p" margin={"32px 0 0"}>
                  Attachments
                </Text>

                <Row padding="16px 0 8px 16px">
                  <Text weight="800" kind="p" size="14px" width="125px">
                    Compliance
                  </Text>
                  <BarWrap>
                    <Bar
                      width={stats.agreements.compliance.attachments.percent}
                      background={"#9F07D8"}
                    />
                    <BarPercent
                      percent={stats.agreements.compliance.attachments.percent}
                    >
                      <Text size="10px" weight="800" color={"#9F07D8"}>
                        {stats.agreements.compliance.attachments.percent + "%"}
                      </Text>
                    </BarPercent>
                  </BarWrap>
                </Row>
                <Row padding="16px 0 8px 16px">
                  <Text weight="800" kind="p" size="14px" width="125px">
                    Progress
                  </Text>
                  <BarWrap>
                    <Bar
                      width={stats.agreements.progress.attachments.percent}
                      background={"#75129F"}
                    />
                    <BarPercent
                      percent={stats.agreements.progress.attachments.percent}
                    >
                      <Text size="10px" weight="800" color={"#75129F"}>
                        {stats.agreements.progress.attachments.percent + "%"}
                      </Text>
                    </BarPercent>
                  </BarWrap>
                </Row>

                {app.agreements.physician && app.agreements.physician.length ? (
                  <Row direction="column">
                    <Text uppercase weight="800" kind="p" margin={"32px 0 0"}>
                      Physician Forms
                    </Text>

                    <Row padding="16px 0 8px 16px">
                      <Text weight="800" kind="p" size="14px" width="125px">
                        Compliance
                      </Text>
                      <BarWrap>
                        <Bar
                          width={stats.agreements.compliance.physician.percent}
                          background={"#FF008A"}
                        />
                        <BarPercent
                          percent={
                            stats.agreements.compliance.physician.percent
                          }
                        >
                          <Text size="10px" weight="800" color={"#FF008A"}>
                            {stats.agreements.compliance.physician.percent +
                              "%"}
                          </Text>
                        </BarPercent>
                      </BarWrap>
                    </Row>
                    <Row padding="16px 0 8px 16px">
                      <Text weight="800" kind="p" size="14px" width="125px">
                        Progress
                      </Text>
                      <BarWrap>
                        <Bar
                          width={stats.agreements.progress.physician.percent}
                          background={"#F40074"}
                        />
                        <BarPercent
                          percent={stats.agreements.progress.physician.percent}
                        >
                          <Text size="10px" weight="800" color={"#F40074"}>
                            {stats.agreements.progress.physician.percent + "%"}
                          </Text>
                        </BarPercent>
                      </BarWrap>
                    </Row>
                  </Row>
                ) : null}
              </Row>
            </Box>
          </Row>

          <Row direction="column">
            <Box>
              <BoxRow
                padding={"16px 24px"}
                justify="space-between"
                alignItems="center"
              >
                <Text kind="p" weight="800" size={"14px"} uppercase>
                  Tasks
                </Text>

                <Cells>
                  {/*<Cell width={95}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>Date</Text>
                  </Cell>*/}

                  <Cell width={125}>
                    <Text kind="p" center weight={800} size={"14px"} uppercase>
                      Status
                    </Text>
                  </Cell>
                </Cells>
              </BoxRow>

              <BoxRow
                padding={"16px 24px"}
                justify="space-between"
                alignItems="center"
              >
                <Row>
                  <Check
                    checked={applications.allAgreementsPrepared(
                      app.agreements.resident
                    )}
                  />
                  <Text kind="p" center weight={800} size={"14px"}>
                    Prepare Agreements
                  </Text>
                </Row>

                <Cells>
                  {/*<Cell width={95}>
                    <Text kind="p" center size={"14px"} uppercase>01/01/2019</Text>
                  </Cell>*/}

                  <Cell width={125}>
                    {applications.allAgreementsPrepared(
                      app.agreements.resident
                    ) && <Pill green>Completed</Pill>}
                    {!applications.allAgreementsPrepared(
                      app.agreements.resident
                    ) && (
                      <Button
                        onClick={() => props.setTab("agreements")}
                        small
                        width={"100%"}
                      >
                        Prepare
                      </Button>
                    )}
                  </Cell>
                </Cells>
              </BoxRow>

              {app.agreements.physician && app.agreements.physician.length ? (
                <>
                  {applications.physicianDocsNeedPreparing(
                    app.agreements.physician
                  ) && (
                    <BoxRow
                      padding={"16px 24px"}
                      justify="space-between"
                      alignItems="center"
                    >
                      <Row>
                        <Check
                          checked={applications.physicianDocsPrepared(
                            app.agreements.physician
                          )}
                        />
                        <Text kind="p" center weight={800} size={"14px"}>
                          Prepare Physician Forms
                        </Text>
                      </Row>

                      <Cells>
                        {/*<Cell width={95}>
                          <Text kind="p" center size={"14px"} uppercase>01/01/2019</Text>
                        </Cell>*/}

                        <Cell width={125}>
                          {applications.physicianDocsPrepared(
                            app.agreements.physician
                          ) && <Pill green>Completed</Pill>}
                          {!applications.physicianDocsPrepared(
                            app.agreements.physician
                          ) && (
                            <Button
                              onClick={() => props.setTab("physician")}
                              small
                              width={"100%"}
                            >
                              Prepare
                            </Button>
                          )}
                        </Cell>
                      </Cells>
                    </BoxRow>
                  )}

                  <BoxRow
                    padding={"16px 24px"}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Row>
                      <Check
                        checked={applications.physicianDocsSent(
                          app.progress.physician
                        )}
                      />
                      <Text kind="p" center weight={800} size={"14px"}>
                        Send Physician Forms
                      </Text>
                    </Row>

                    <Cells>
                      {/*<Cell width={95}>
                        <Text kind="p" center size={"14px"} uppercase>01/01/2019</Text>
                      </Cell>*/}

                      <Cell width={125}>
                        {applications.physicianDocsSent(
                          app.progress.physician
                        ) && <Pill green>Completed</Pill>}
                        {!applications.physicianDocsSent(
                          app.progress.physician
                        ) && (
                          <Button
                            onClick={() => props.setTab("physician")}
                            small
                            width={"100%"}
                          >
                            Send
                          </Button>
                        )}
                      </Cell>
                    </Cells>
                  </BoxRow>
                </>
              ) : null}

              <BoxRow
                padding={"16px 24px"}
                justify="space-between"
                alignItems="center"
              >
                <Row>
                  <Check checked={applications.allAgreementsComplete(app)} />
                  <Text kind="p" center weight={800} size={"14px"}>
                    Sign Agreements
                  </Text>
                </Row>

                <Cells>
                  {/*<Cell width={95}>
                    <Text kind="p" center size={"14px"} uppercase>01/01/2019</Text>
                  </Cell>*/}

                  <Cell width={125}>
                    {applications.allAgreementsComplete(app) && (
                      <Pill green>Completed</Pill>
                    )}
                    {!applications.allAgreementsComplete(app) && (
                      <Button
                        onClick={() => props.setTab("agreements")}
                        small
                        width={"100%"}
                      >
                        Sign
                      </Button>
                    )}
                  </Cell>
                </Cells>
              </BoxRow>

              {app.myDocuments.required_documents &&
              app.myDocuments.required_documents.length ? (
                <BoxRow
                  padding={"16px 24px"}
                  justify="space-between"
                  alignItems="center"
                >
                  <Row>
                    <Check checked={applications.allUploadsReceived(app)} />
                    <Text kind="p" center weight={800} size={"14px"}>
                      Attach Documents
                    </Text>
                  </Row>

                  <Cells>
                    {/*<Cell width={95}>
                      <Text kind="p" center size={"14px"} uppercase>01/01/2019</Text>
                    </Cell>*/}

                    <Cell width={125}>
                      {applications.allUploadsReceived(app) && (
                        <Pill green>Completed</Pill>
                      )}
                      {!applications.allUploadsReceived(app) && (
                        <Button
                          onClick={() => props.setTab("attachments")}
                          small
                          width={"100%"}
                        >
                          Upload
                        </Button>
                      )}
                    </Cell>
                  </Cells>
                </BoxRow>
              ) : null}

              {/* {app.agreements.physician && app.agreements.physician.length ? (
                <BoxRow
                  padding={"16px 24px"}
                  justify="space-between"
                  alignItems="center"
                >
                  <Row>
                    <Check
                      checked={applications.allPhysicianDocsComplete(app)}
                    />
                    <Text kind="p" center weight={800} size={"14px"}>
                      Receive Physician Forms
                    </Text>
                  </Row>

                  <Cells>
                    <Cell width={125}>
                      {applications.allPhysicianDocsComplete(app) && (
                        <Pill green>Completed</Pill>
                      )}
                      {!applications.allPhysicianDocsComplete(app) && (
                        <Button
                          onClick={() => props.setTab("physician")}
                          small
                          width={"100%"}
                        >
                          Attach
                        </Button>
                      )}
                    </Cell>
                  </Cells>
                </BoxRow>
              ) : null} */}

              {app.progress.current == "In Review" ? (
                <BoxRow
                  padding={"16px 24px"}
                  justify="space-between"
                  alignItems="center"
                >
                  <Row>
                    <Check />
                    <Text kind="p" center weight={800} size={"14px"}>
                      Mark Complete
                    </Text>
                  </Row>

                  <Cells>
                    {/*<Cell width={95}>
                      <Text kind="p" center size={"14px"} uppercase>01/01/2019</Text>
                    </Cell>*/}

                    <Cell width={125}>
                      <Button
                        onClick={() => updateProgress("complete")}
                        small
                        width={"100%"}
                      >
                        Approve
                      </Button>
                    </Cell>
                  </Cells>
                </BoxRow>
              ) : null}

              {app.progress.current == "complete" ? (
                <BoxRow
                  padding={"16px 24px"}
                  justify="space-between"
                  alignItems="center"
                >
                  <Row>
                    <Check checked />
                    <Text kind="p" center weight={800} size={"14px"}>
                      Mark Complete
                    </Text>
                  </Row>

                  <Cells>
                    <Cell width={125}>
                      <Pill green>Completed</Pill>
                    </Cell>
                  </Cells>
                </BoxRow>
              ) : null}
            </Box>

            <Row padding={"32px 0 0 0"}>
              <Box>
                <BoxRow
                  padding={"16px 24px"}
                  justify="space-between"
                  alignItems="center"
                >
                  <Text kind="p" weight="800" size={"14px"} uppercase>
                    Resident Information
                  </Text>
                </BoxRow>

                <Row direction="column" padding={"32px 16px"}>
                  <Row>
                    <Row margin="0 16px 0 0" direction="column">
                      <Input
                        name="first_name"
                        change={updateContactInfo}
                        initialvalue={app.myInfo.contact_info.first_name}
                      />
                      <Label>First Name</Label>
                    </Row>

                    <Row margin="0 16px 0 0" direction="column">
                      <Input
                        name="middle_name"
                        change={updateContactInfo}
                        initialvalue={app.myInfo.contact_info.middle_name}
                      />
                      <Label>Middle Name</Label>
                    </Row>

                    <Row direction="column">
                      <Input
                        name="last_name"
                        change={updateContactInfo}
                        initialvalue={app.myInfo.contact_info.last_name}
                      />
                      <Label>Last Name</Label>
                    </Row>
                  </Row>

                  <Row padding={"16px 0"}>
                    <Row margin="0 16px 0 0" direction="column">
                      <Input
                        mask={"99/99/9999"}
                        name="date_of_birth"
                        change={updateContactInfo}
                        initialvalue={app.myInfo.personal_info.date_of_birth}
                      />
                      <Label>Date of Birth</Label>
                    </Row>

                    <Row margin="0 16px 0 0" direction="column">
                      <Input
                        name="unit_number"
                        change={updateContactInfo}
                        initialvalue={app.myInfo.application_info.unit_number}
                      />
                      <Label>Unit Number</Label>
                    </Row>

                    <Row direction="column">
                      <Input
                        mask={"99/99/9999"}
                        name="due_date"
                        change={updateContactInfo}
                        initialvalue={app.myInfo.application_info.due_date}
                      />
                      <Label>Due Date</Label>
                    </Row>
                  </Row>

                  <Row>
                    <Row direction="column">
                      <Input
                        name="email"
                        change={updateContactInfo}
                        initialvalue={
                          applications.isNotGeneratedEmail(
                            app.myInfo.contact_info.email
                          )
                            ? app.myInfo.contact_info.email
                            : ""
                        }
                      />
                      <Label>Email Address</Label>
                    </Row>
                  </Row>

                  <Row padding={"16px 0 0"}>
                    <Row direction="column">
                      <Select
                        change={(e) => changeDepartment(e)}
                        initialvalue={{ label: app.department }}
                        options={departments}
                      />
                      <Label>Assigned Department</Label>
                    </Row>
                  </Row>

                  <Row padding={"16px 0 0"}>
                    <Row direction="column">
                      <Select
                        change={(e) =>
                          updateContactInfo({
                            target: { value: e.value, name: "manager_name" },
                          })
                        }
                        listen={assignedManager}
                        initialvalue={assignedManager ? assignedManager : null}
                        options={managers}
                      />
                      <Label>Community Assignee</Label>
                    </Row>
                  </Row>

                  <Row padding={"16px 0 0"}>
                    <Row direction="column">
                      <Select
                        change={(e) => updateProgress(e.value)}
                        initialvalue={{ label: app.progress.current }}
                        options={[
                          { label: "New", value: "New!" },
                          { label: "In Progress", value: "In Progress" },
                          { label: "In Review", value: "In Review" },
                          { label: "Complete", value: "complete" },
                        ]}
                      />
                      <Label>Application Status</Label>
                    </Row>
                  </Row>

                  <Row padding={"32px 0 0"} justify="flex-end">
                    <div onClick={updateApp}>
                      <Button>Update</Button>
                    </div>
                  </Row>
                </Row>
              </Box>
            </Row>
          </Row>
        </Row>
      </Row>
      <MessageBar message={message} listen={changed} />
    </>
  );
};
