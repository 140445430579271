import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../util/colors";
import $ from "jquery";

const Wrap = styled.label`
  display: inline-block;
  user-select: none;
  .checkmark {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid #c4cad0;
    background: white;
    position: relative;
    overflow: hidden;
    margin-right: 8px;
    img {
      display: none;
    }
  }
  input:checked ~ .checkmark {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid ${colors.blue};
    background: ${colors.blue};
    position: relative;
    overflow: hidden;
    margin-right: 8px;
    display: flex;
    align-items: middle;
    img {
      display: block;
      margin-left: 3px;
      width: 12px;
    }
  }
`;

export default (props) => {
  useEffect(() => {
    const radio = $("." + props.name + props.value);
    if (props.initialchecked) radio.prop("checked", true);
  }, []);

  return (
    <Wrap>
      <input
        className={props.name + props.value}
        hidden
        type="radio"
        {...props}
      />
      <div className={"checkmark"}>
        <img src="/icon-checkmark.svg" />
      </div>
    </Wrap>
  );
};
