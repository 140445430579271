import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../util/colors";
import applications from "../util/application";
import user from "../util/user";
import communities from "../util/community";
import agreementsHelper from "../util/agreements";
import { Modal, Row, Button, Text, Checkbox, Label } from "./index";

export default (props) => {
  const [show, setShow] = useState(props.show);
  const [application, setApplication] = useState(props.application);
  const [agreements, setAgreements] = useState();

  function toggleAgreement(agreement) {
    agreement.selected = !agreement.selected;
    for (var i in agreements) {
      if (agreement.id == agreements[i].id) agreements[i] = agreement;
    }
    setAgreements(agreements);
  }

  async function addAgreements() {
    try {
      const applicationLatest = await applications.get(application.id);
      const selectedAgreements = agreements.filter(
        (agreement) => agreement.selected
      );
      const preppedAgreements = applications.prepAgreements(selectedAgreements);
      for (var i in preppedAgreements) {
        if (preppedAgreements[i].details.physician_doc) {
          applicationLatest.data.agreements.physician.push(
            preppedAgreements[i]
          );
        } else {
          applicationLatest.data.agreements.resident.push(preppedAgreements[i]);
        }
      }
      await applications.update(applicationLatest.data);
      props.complete();
      props.close();
    } catch (err) {}
  }

  useEffect(() => {
    if (props.application) {
      async function init() {
        const me = await user.get();
        const location = await communities.get(me.data.facilityId);
        let agreementsList = await agreementsHelper.getAgreements(
          location.data.companyId,
          me.data.facilityId
        );

        if (props.community.details.departments) {
          const _department = props.community.details.departments.filter(
            (dep) => dep.name === props.application.department
          );

          const department = _department[0];

          const _departmentAgreements = agreementsHelper.filterDepartmentAgreements(
            department.available_agreements,
            agreementsList
          );

          agreementsList = _departmentAgreements;
        }

        const cleanedUpList = [];

        for (var i in agreementsList) {
          const agreement = agreementsList[i];
          let clear = true;

          if (props.physician && !agreement.details.physician_doc)
            clear = false;

          for (var j in props.application.agreements.resident) {
            if (
              props.application.agreements.resident[j].template_id ==
              agreement.id
            )
              clear = false;
          }
          for (var j in props.application.agreements.physician) {
            if (
              props.application.agreements.physician[j].template_id ==
              agreement.id
            )
              clear = false;
          }
          if (clear) cleanedUpList.push(agreement);
        }
        setAgreements(cleanedUpList);
      }

      setShow(props.show);
      setApplication(props.application);
      init();
    }
  }, [props.show, props.application, props.community]);

  return (
    <Modal
      title={props.physician ? "Add Documents" : "Add Agreements"}
      show={show}
      close={props.close}
    >
      {agreements && agreements.length > 0 ? (
        <Row direction="column">
          <Row padding={"24px 24px 0"} direction="column">
            <Text kind="p" weight={700} color="#4D5864">
              {props.physician ? "Documents" : "Agreements"}
            </Text>
          </Row>

          <Row direction="column" padding={"12px"}>
            {agreements.map((agreement, index) => (
              <Row padding={"12px"} alignItems="center">
                <Checkbox onClick={() => toggleAgreement(agreement)} />
                <Label color="#4D5864">{agreement.details.name}</Label>
              </Row>
            ))}
          </Row>

          <Row padding={"24px"} justify="flex-end">
            <Button onClick={addAgreements}>Add</Button>
          </Row>
        </Row>
      ) : (
        <Row direction="column">
          <Row justify="center" padding="48px">
            <Text kind="p" center color="#4D5864">
              All {props.physician ? "Documents" : "Agreements"} have already
              been assigned
            </Text>
          </Row>

          <Row padding={"0 24px 24px"} justify="flex-end">
            <Button onClick={() => props.close()}>Close</Button>
          </Row>
        </Row>
      )}
    </Modal>
  );
};
