import React, { useState, useEffect } from "react";
import styled from "styled-components";
import user from "../util/user";
import colors from "../util/colors";
import { Label, Input, Row, Text, Link, Button, Logo } from "../components";
import { Redirect, BrowserRouter as Router } from "react-router-dom";
import QueryString from "query-string";

const FormWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  @media (prefers-color-scheme: dark) {
    p,
    label,
    h1 {
      color: white;
    }
  }
`;

const Form = styled.form`
  max-width: 400px;
  width: 100%;
`;

const Name = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secondaryPassword, setSecondaryPassword] = useState("");
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState();
  const [inviteCode, setInviteCode] = useState();

  // const BACKENDURL =
  //   process.env.REACT_APP_BACKEND_URL ||
  //   window.location.href.indexOf("localhost:8000")
  //     ? "http://localhost:8000"
  //     : "https://api.docuvaultapp.com";
  const BACKENDURL =
    process.env.REACT_APP_BACKEND_URL || "https://api.docuvaultapp.com";

  const login = (e) => {
    e.preventDefault();

    if (password !== secondaryPassword) {
      setError(`Passwords don't match`);
    } else {
      const _accept = user.acceptInvitation({
        email: email,
        password: password,
        invite_code: inviteCode,
      });

      fetch(BACKENDURL + "/session", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, password: password }),
        mode: "cors",
      })
        .then((response) => {
          if (!response.ok) {
            console.log("Heres our error", response);
          }
          return response.json();
        })
        .then((returnedData) => {
          if (returnedData.token) {
            if (returnedData.access == "facility") {
              localStorage.setItem("redirectKey", email);
              localStorage.setItem("token", returnedData.token);
              localStorage.setItem("pass", password);
            }
            props.history.push("/dashboard");
          }
          if (returnedData.statusCode === 403) {
            setError("Please type your email or password again!");
          }
        });
    }
  };

  const checkIfPasswordAlreadySet = () => {
    let redirectKey = localStorage.getItem("redirectKey");
    if (email === redirectKey) {
      props.history.push("/");
    }
  };

  useEffect(() => {
    checkIfPasswordAlreadySet();
  }, [email]);

  useEffect(() => {
    const parsed = QueryString.parse(window.location.search);
    setEmail(parsed.email.toLowerCase());
    setInviteCode(parsed.invite_code);
  }, []);

  return (
    <div>
      <Logo margin={"24px"} absolute />
      <FormWrapper>
        <Form onSubmit={login}>
          <div>
            <Text kind="h1" size={"36px"} weight={"900"} center>
              WELCOME
            </Text>
            <Text kind="p" size={"16px"} margin={"8px 0 48px"} center>
              Please create an account
            </Text>
          </div>

          <Row direction="column" padding={"0 0 24px"}>
            <Input
              initialvalue={email}
              name="email"
              type="email"
              change={(e) => setEmail(e.target.value)}
            />
            <Label>Email Address</Label>
          </Row>

          <Row direction="column" padding={"0 0 24px"}>
            <Input
              name="password"
              type="password"
              change={(e) => setPassword(e.target.value)}
            />
            <Row justify="space-between">
              <Label>Password</Label>
            </Row>
          </Row>

          <Row direction="column" padding={"0 0 24px"}>
            <Input
              name="second_password"
              type="password"
              change={(e) => setSecondaryPassword(e.target.value)}
            />
            <Row justify="space-between">
              <Label>Repeat Password</Label>
            </Row>
          </Row>

          {error && <Label color={"red"}>{error}</Label>}

          <Button primary full onClick={login}>
            Login
          </Button>
        </Form>
      </FormWrapper>
    </div>
  );
};

export default Name;
