import React from "react";
import styled from "styled-components";

const Logo = styled.img`
  width: 190px;
  margin: ${(props) => (props.margin ? props.margin : 0)};
  position: ${(props) => (props.absolute ? "absolute" : "relative")};
`;

export default (props) => <Logo {...props} src="/logo2.png" />;
