import React from "react";
import styled from "styled-components";
import Row from "./row";

const BoxRow = styled(Row)`
  border-bottom: 1px solid #ddd;
  border-top: ${(props) => (props.bordertop ? "1px solid #ddd" : "none")};
  &:last-child {
    border-bottom: none;
  }
  background: ${(props) => (props.background ? props.background : "none")};
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid #66686b;
    border-top: ${(props) => (props.bordertop ? "1px solid #66686b" : "none")};
    background: inherit;
  }
`;

export default (props) => <BoxRow {...props} />;
