import React, { useState, useEffect } from "react";
import {
  Row,
  Text,
  Button,
  Input,
  Label,
  Select,
  Required,
  MessageBar,
} from ".";
import styled from "styled-components";
import PCC from "../util/pcc";
import queryString from "query-string";
import randomstring from "randomstring";

const Item = styled.div`
  width: 100%;
  padding: 12px;
  background: white;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition-duration: 0.3s;
  background: white;
  :hover {
    background: #ededed;
    div {
      font-weight: 600 !important;
    }
  }
`;

const Items = styled.div`
  width: 100%;
  height: 325px;
  overflow: scroll;
  margin-top: 16px;
`;

const Icon = styled.img`
  width: 20px;
`;

const Main = ({ location, close, selectResident }) => {
  const [code, setCode] = useState();
  const [auth, setAuth] = useState();
  const [patient, setPatient] = useState();
  const [patients, setPatients] = useState();
  const [facility, setFacility] = useState();
  const [facilities, setFacilities] = useState();
  const [search, setSearch] = useState({ gender: "MALE" });
  const [page, setPage] = useState(1);
  const [patientStatus, setPatientStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    console.log("load");
    const _parsed = queryString.parse(location.search);
    console.log(_parsed);

    async function checkTokens() {
      console.log("check token");
      try {
        if (window.localStorage.pcc) {
          const _auth = JSON.parse(window.localStorage.pcc);
          console.log("auth refresh", _auth);
          if (_auth.refresh_token) {
            const _refreshedToken = await PCC.refreshToken(_auth.refresh_token);
            if (_refreshedToken.data.access_token) {
              setAuth(_refreshedToken.data);
              getFacilities(_refreshedToken.data);
              window.localStorage.setItem(
                "pcc",
                JSON.stringify(_refreshedToken.data)
              );
            } else {
              getLogin();
            }
          }
        } else {
          getLogin();
        }

        async function getLogin() {
          if (_parsed.code) {
            const _auth = await authenticate(_parsed.code);
            console.log("code but not logged in", _auth);
            if (_auth.error) {
              //login()
            } else {
              const _refreshedToken = await PCC.refreshToken(
                _auth.refresh_token
              );
              if (_refreshedToken.data.access_token) {
                setAuth(_refreshedToken.data);
                getFacilities(_refreshedToken.data);
                window.localStorage.setItem(
                  "pcc",
                  JSON.stringify(_refreshedToken.data)
                );
                setMessage("PCC authentication successful");
                setShowMessage(randomstring.generate());
              }
            }
          } else if (window.localStorage.pcc) {
            login();
          } else {
            login();
          }
        }
      } catch (err) {
        console.log("error check", err);
      }
    }

    checkTokens();
  }, []);

  useEffect(() => {
    async function getUpdated() {
      setLoading(true);
      const _patients = await PCC.getPatients(
        auth.access_token,
        auth.metadata.orgUuid,
        facility.facId,
        page,
        patientStatus
      );

      console.log("patients", _patients);
      setPatients(_patients.data);
      setLoading(false);
    }

    if (facility && facility.facId) getUpdated();
  }, [facility, page, patientStatus]);

  async function getFacilities(_auth) {
    try {
      const _facilities = await PCC.getFacilities(
        _auth.access_token,
        _auth.metadata.orgUuid
      );
      console.log("facilities", _facilities);
      setFacilities(_facilities.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function getPatients(page) {
    if (facility.facId) {
      setLoading(true);
      const _patients = await PCC.getPatients(
        auth.access_token,
        auth.metadata.orgUuid,
        facility.facId,
        page
      );
      console.log("patients", _patients);
      setPatients(_patients.data);
      setLoading(false);
    }
  }

  async function getPatient(patientId) {
    console.log(patientId);
    const _patient = await PCC.getPatient(
      auth.access_token,
      auth.metadata.orgUuid,
      patientId
    );
    const _parsedResident = PCC.parseResident(_patient.data);
    console.log(_patient.data);
    if (_parsedResident) setPatient(_parsedResident);
  }

  async function authenticate(_code) {
    try {
      const _auth = await PCC.authenticate(_code);
      window.localStorage.setItem("pcc", JSON.stringify(_auth.data));
      return _auth.data;
    } catch (err) {
      console.log("error", err);
      return false;
    }
  }

  function updateSearch(e) {
    search[e.target.name] = e.target.value;
    setSearch(search);
  }

  async function runSearch() {
    console.log(search);
    if (
      !search.firstName ||
      !search.lastName ||
      !search.birthDate ||
      !search.gender
    ) {
      setMessage("Please fill out all required fields");
      setShowMessage(randomstring.generate());
    } else {
      setLoading(true);
      const _patients = await PCC.getPatientMatch(
        auth.access_token,
        auth.metadata.orgUuid,
        facility.facId,
        search
      );
      console.log("patients", _patients);
      if (
        _patients.data &&
        !_patients.data.error &&
        _patients.data.data &&
        Array.isArray(_patients.data.data)
      ) {
        console.log("data", _patients.data);
        setPatients(_patients.data);
      } else {
        setPatients(null);
      }
      setLoading(false);
    }
  }

  // Production URL!
  // function login() {
  //   window.location.href =
  //     "https://connect.pointclickcare.com/auth/login?client_id=FIbvMl792HdxAIUE88ubqmb0ygH7lKFX&response_type=code&redirect_uri=https://comm.seniorsign.io/add-resident&appName=ss-community-portal&state=9j76_b63";
  // }

  // Sandbox URL
  function login() {
    window.location.href =
      "https://login.pointclickcare.com/auth/login.xhtml?client_id=dZXVQAUcpCzRFycUKA3h0xxWVImpGsX9&response_type=code&redirect_uri=https://comm.seniorsign.io/add-resident&appName=ss-community-portal&state=9j76_b63";
  }

  function nextPage() {
    const _page = page + 1;
    setPage(_page);
  }

  function prevPage() {
    const _page = page - 1;
    if (_page > 0) setPage(_page);
  }

  function importResident() {
    selectResident(patient);
    close();
  }

  return (
    <>
      <Row direction="column">
        {facilities && !facility && (
          <>
            <Text uppercase weight="600" size="14px" margin="0 0 16px">
              Community / Facility
            </Text>
            {facilities.map((_facility) => (
              <Item
                onClick={() => setFacility(_facility)}
                key={_facility.facId}
              >
                <Text size="12px">{_facility.facilityName}</Text>
              </Item>
            ))}
          </>
        )}

        {facility && (
          <>
            {patient ? (
              <>
                <Text weight="bold">{`${patient.firstName || ""} ${
                  patient.middleName || ""
                } ${patient.lastName || ""}`}</Text>

                <Row wrap>
                  {patient &&
                    Object.entries(patient).map((key) => {
                      if (key[0] === "pcc") return null;
                      return (
                        <Row direction="column" margin="0 0 8px" width="285px">
                          <Label color="black">{key[0]}</Label>
                          <Text size="14px">{key[1]}</Text>
                        </Row>
                      );
                    })}
                </Row>
              </>
            ) : (
              <>
                <Text uppercase weight="600" size="14px">
                  {facility.facilityName}
                </Text>

                <Row direction="column" margin="24px 0 0">
                  <Row margin="0 0 8px">
                    <Row direction="column" margin="0 8px 0 0">
                      <Input
                        name="firstName"
                        placeholder="First Name"
                        change={updateSearch}
                      />
                      <Label color="black">
                        <Required />
                        First Name
                      </Label>
                    </Row>

                    <Row direction="column">
                      <Input
                        name="lastName"
                        placeholder="Last Name"
                        change={updateSearch}
                      />
                      <Label color="black">
                        <Required />
                        Last Name
                      </Label>
                    </Row>
                  </Row>

                  <Row alignItems="flex-start" justify="space-between">
                    <Row direction="column" margin="0 8px 0 0">
                      <Input
                        mask="9999-99-99"
                        placeholder="yyyy-dd-mm"
                        name="birthDate"
                        change={updateSearch}
                      />
                      <Label color="black">
                        <Required />
                        Date of Birth
                      </Label>
                    </Row>

                    <Row direction="column" margin="0 8px 0 0">
                      <Select
                        options={[
                          { label: "Male", value: "MALE" },
                          { label: "Female", value: "FEMALE" },
                          { label: "Unknown", value: "UNKNOWN" },
                        ]}
                        name="gender"
                        change={(e) =>
                          updateSearch({
                            target: { name: "gender", value: e.value },
                          })
                        }
                      />
                      <Label color="black">
                        <Required />
                        Gender
                      </Label>
                    </Row>

                    <Button onClick={runSearch}>Search</Button>
                  </Row>
                </Row>

                <Row
                  margin="16px 0 0"
                  justify="space-between"
                  alignItems="center"
                >
                  <Text uppercase weight="600" size="14px" margin="0 0 16px">
                    Select Resident:
                  </Text>

                  <Select
                    width="200px"
                    options={[
                      { label: "Current", value: "Current" },
                      { label: "New", value: "New" },
                      { label: "Discharged", value: "Discharged" },
                    ]}
                    name="patientStatus"
                    change={(e) => {
                      setPage(1);
                      setPatientStatus(e.value);
                    }}
                  />
                </Row>

                {loading ? (
                  <Row
                    justify="center"
                    alignItems="center"
                    margin="16px 0 0"
                    height="325px"
                  >
                    <Text>Loading...</Text>
                  </Row>
                ) : (
                  <Items>
                    {patients &&
                      patients.data.map((_patient) => (
                        <Item
                          onClick={() => getPatient(_patient.patientId)}
                        >{`${_patient.firstName || ""} ${
                          _patient.middleName || ""
                        } ${_patient.lastName || ""}`}</Item>
                      ))}
                  </Items>
                )}

                {patients && (
                  <Row justify="space-between" margin="16px 0">
                    {page === 1 ? (
                      <div />
                    ) : (
                      <Row
                        onClick={prevPage}
                        pointer
                        width="200px"
                        alignItems="center"
                      >
                        <Icon src="/paginate-arrow-left.svg" />
                        <Text
                          margin="0 0 0 8px"
                          size="15px"
                          weight="600"
                          color="#8A96A1"
                        >
                          Previous Page
                        </Text>
                      </Row>
                    )}

                    {patients.paging && patients.paging.hasMore && (
                      <Row
                        onClick={nextPage}
                        pointer
                        width="200px"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <Text
                          margin="0 8px 0 0"
                          size="15px"
                          weight="600"
                          color="#8A96A1"
                        >
                          Next Page
                        </Text>
                        <Icon src="/paginate-arrow-right.svg" />
                      </Row>
                    )}
                  </Row>
                )}
              </>
            )}
          </>
        )}
      </Row>

      <Row padding="32px 0 0" justify="flex-start">
        <Button outline stay onClick={close}>
          Back
        </Button>

        {patient && (
          <Button margin="0 0 0 16px" onClick={importResident}>
            Import
          </Button>
        )}
      </Row>

      <MessageBar message={message} listen={showMessage} />
    </>
  );
};

export default Main;
